import { CSVLink } from 'react-csv';

import { getPerformanceLabel } from '../components/HelperFunctions/Others';
import {
  calcDifferenceTimeDecimal,
  convertIsoToLocalDate,
  extractTime,
  timestampToDecimal,
} from './DateTime';
import { formatSearchingDate } from './formatDates';
import { getWorkerNameByID, getWarehouseNameByID } from './getName';

export const generateCSVData = (newReports, SearchParams, warehouses, workers) => {
  const csvData = [];
  if (SearchParams) {
    const warehouseName = getWarehouseNameByID(SearchParams?.warehouse, warehouses);
    csvData.push([
      formatSearchingDate(SearchParams?.startDate, SearchParams?.endDate),
      warehouseName,
    ]);
    csvData.push([]);
  }
  if (newReports) {
    Object.keys(newReports).forEach((e) => {
      csvData.push([
        'Employee',
        'Date',
        'Warehouse',
        'Check In',
        'Check Out',
        'Working Hours',
        'Performance',
      ]);

      newReports[e].shifts.forEach((shift) => {
        csvData.push([
          getWorkerNameByID(e, workers),
          convertIsoToLocalDate(shift.check_in),
          getWarehouseNameByID(shift.warehouse, warehouses),
          extractTime(shift.check_in),
          extractTime(shift.check_out),
          calcDifferenceTimeDecimal(shift.check_in, shift.check_out),
          getPerformanceLabel(shift.performance, shift.performanceUnit),
        ]);
      });

      csvData.push([
        '',
        '',
        '',
        '',
        'Total',
        timestampToDecimal(newReports[e].total),
        newReports[e].performanceTotal,
      ]);

      csvData.push([]);
    });
  }
  return csvData;
};

export const CSVDownloadLink = ({ newReports, SearchParams, warehouses, workers }) => {
  const warehouseName = getWarehouseNameByID(SearchParams?.warehouse, warehouses);

  return (
    <CSVLink
      data={generateCSVData(newReports, SearchParams, warehouses, workers)}
      filename={`${formatSearchingDate(
        SearchParams?.startDate,
        SearchParams?.endDate,
      )}(${warehouseName}).csv`}
    >
      Download CSV
    </CSVLink>
  );
};
