import React, {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import { Container } from '@mui/material';

import {
    getUsers,
    getWarehouse,
} from '../../../../actions';
import { getVasShifts } from '../../../../actions/vasShiftActions';
import VasShiftsTable
    from '../../../../components/Dashboard/Tools/vas-shifts/VasShiftsTable';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';

const VasShifts = () => {
    const dispatch = useDispatch();

    // Local state for pagination
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    // Extract the paginated response from Redux state.
    const { vasShiftsResponse, loading, error } = useSelector(
        (state) => state.vasShifts
    );
    const warehouses = useSelector((state) => state.warehouses);
    const employees = useSelector((state) => state.users);

    // Whenever page or limit changes, dispatch the action to fetch data.
    useEffect(() => {
        dispatch(getVasShifts(page, limit));
    }, [dispatch, page, limit]);

    useEffect(() => {
        dispatch(getWarehouse());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    // Destructure pagination data from the response.
    const { data: shifts = [], total = 0 } = vasShiftsResponse || {};

    // Handlers to update local pagination state.
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newLimit) => {
        setLimit(newLimit);
        setPage(1); // Reset to first page when rows per page change.
    };

    return (
        <Page title="VAS Shift">
            <Container>
                <HeaderBreadcrumbs
                    heading="VAS Shifts"
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Tools', href: '/tools' },
                        { name: 'VAS Shifts' },
                    ]}
                />

                {loading ? (
                    <div>Loading...</div>
                ) : shifts.length > 0 ? (
                    <VasShiftsTable
                        shifts={shifts}
                        warehouses={warehouses}
                        employees={employees}
                        total={total}
                        currentPage={page}
                        limit={limit}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                ) : (
                    <div>No VAS Shifts available</div>
                )}

                {error && <div>Error: {error}</div>}
            </Container>
        </Page>
    );
};

export default VasShifts;
