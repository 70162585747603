import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Create axios instances for both backend URLs
export const axiosMain = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export const axiosNew = axios.create({
  baseURL: process.env.REACT_APP_NEW_BACKEND_URL,
});

// Request interceptor to add auth token
const addAuthToken = (config) => {
  const token = cookies.get('rsid');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
};

// Add interceptors to both instances
[axiosMain, axiosNew].forEach((instance) => {
  instance.interceptors.request.use(addAuthToken);
});

const axiosConfig = {
  axiosMain,
  axiosNew,
};

export default axiosConfig;
