import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Divider, Typography, Paper } from '@mui/material';

import { fNumber } from '@/utils/formatNumber';
import LoadingCard from '@/components/common/LoadingCard';
import NoDataCard from '@/components/common/NoDataCard';

/**
 * ProductTypeSummaryItem - Displays summary information for a single product type
 */
const ProductTypeSummaryItem = memo(({ item }) => {
  const { t } = useTranslation();

  return (
    <Box mb={2}>
      <Typography variant="h6">
        {t('report.productType')}: {item.productType}
      </Typography>
      <Typography>
        {t('report.totalPieces')}: {fNumber(item.totalPcs)}
      </Typography>
      <Typography>
        {t('report.workingHours')}: {fNumber(item.totalDuration, '0,0.00')} h
      </Typography>
      <Typography>
        {t('report.averagePerformance')}: {fNumber(item.performance, '0,0.00')}{' '}
        {t('common.performanceUnit')}
      </Typography>
    </Box>
  );
});

ProductTypeSummaryItem.propTypes = {
  item: PropTypes.shape({
    productType: PropTypes.string.isRequired,
    totalPcs: PropTypes.number.isRequired,
    totalDuration: PropTypes.number.isRequired,
    performance: PropTypes.number.isRequired,
  }).isRequired,
};

ProductTypeSummaryItem.displayName = 'ProductTypeSummaryItem';

/**
 * RenderProductTypeReport - Component to display VAS product-type-level report data
 */
const RenderProductTypeReport = () => {
  const { t } = useTranslation();
  const { data, loading, searchPerformed } = useSelector((state) => state.vasReport);
  const productTypeSummary = data?.productTypeSummary || [];

  if (loading) {
    return <LoadingCard message={t('common.loading')} />;
  }

  // Only show "No Data Available" if a search has been performed
  if (searchPerformed && !productTypeSummary.length) {
    return <NoDataCard message={t('report.noDataAvailable')} />;
  }

  // If no search has been performed yet, return null
  if (!searchPerformed) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ mt: 2 }}>
      <CardContent>
        <Paper>
          {productTypeSummary.map((item, index) => (
            <React.Fragment key={item.productType}>
              <ProductTypeSummaryItem item={item} />
              {index < productTypeSummary.length - 1 && <Divider sx={{ mb: 2 }} />}
            </React.Fragment>
          ))}
        </Paper>
      </CardContent>
    </Card>
  );
};

export default memo(RenderProductTypeReport);
