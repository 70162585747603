import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { deleteVasShifts } from '../../../../actions/vasShiftActions';

// A simple toolbar that shows the number of selected rows and a delete button.
const EnhancedTableToolbar = ({ numSelected, onDelete, t }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.secondary.light
              : theme.palette.secondary.dark,
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} {t('vasShifts.selected')}
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {t('vasShifts.title')}
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title={t('vasShifts.delete')}>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const VasShiftsTable = ({
  shifts,
  warehouses,
  employees,
  total, // Total number of items
  currentPage, // 1-indexed current page from parent state
  limit, // Rows per page
  onPageChange, // Callback to update page in parent state
  onRowsPerPageChange, // Callback to update rows per page in parent state
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const getEmployeeFullName = (employeeId) => {
    if (!employees || !Array.isArray(employees)) return t('vasShifts.unknown');
    const employee = employees.find((emp) => emp.id === employeeId);
    return employee ? employee.fullName : t('vasShifts.unknown');
  };

  const getWarehouseName = (warehouseId) => {
    if (!warehouses || !Array.isArray(warehouses)) return t('vasShifts.unknown');
    const warehouse = warehouses.find((wh) => wh.id === warehouseId);
    return warehouse ? warehouse.name : t('vasShifts.unknown');
  };

  // Handler for "select all" checkbox in the header.
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = shifts.map((shift) => shift.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Handler for a single row's checkbox.
  const handleCheckboxClick = (event, id) => {
    // Prevent the row click (that navigates) from firing.
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // When delete is clicked, open the MUI confirmation dialog.
  const handleDelete = () => {
    setOpenConfirm(true);
  };

  // Confirm deletion: dispatch the delete action and reset selection.
  const handleConfirmDelete = () => {
    dispatch(deleteVasShifts(selected));
    setSelected([]);
    setOpenConfirm(false);
  };

  // Close the confirmation dialog without deleting.
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  // MUI TablePagination expects a zero-indexed page.
  const handleChangePage = (event, newPage) => {
    onPageChange(newPage + 1); // Convert to 1-indexed page for parent state.
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <>
      <TableContainer component={Paper}>
        {/* Toolbar with the delete button */}
        <EnhancedTableToolbar numSelected={selected.length} onDelete={handleDelete} t={t} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < shifts.length}
                  checked={shifts.length > 0 && selected.length === shifts.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all shifts',
                  }}
                />
              </TableCell>
              <TableCell>
                <strong>{t('vasShifts.employee')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('vasShifts.warehouse')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('vasShifts.startTime')}</strong>
              </TableCell>
              <TableCell>
                <strong>{t('vasShifts.endTime')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shifts.map((shift) => {
              const isItemSelected = isSelected(shift.id);
              const linkUrl = `/tools/vas-shifts/${shift.id}`;

              return (
                <TableRow
                  key={shift.id}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  selected={isItemSelected}
                  component={RouterLink}
                  to={linkUrl}
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: 'table-row',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleCheckboxClick(event, shift.id);
                      }}
                      inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${shift.id}`,
                      }}
                    />
                  </TableCell>

                  <TableCell>{getEmployeeFullName(shift.employeeId)}</TableCell>
                  <TableCell>{getWarehouseName(shift.warehouseId)}</TableCell>
                  <TableCell>{new Date(shift.startTime).toLocaleString()}</TableCell>
                  <TableCell>
                    {shift.endTime ? new Date(shift.endTime).toLocaleString() : ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={total}
                page={currentPage - 1}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('vasShifts.rowsPerPage')}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* MUI Confirmation Dialog */}
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          {t('vasShifts.confirmDeleteTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            {t('vasShifts.confirmDeleteMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>{t('vasShifts.cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            {t('vasShifts.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VasShiftsTable;
