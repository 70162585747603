const instruction = {
  en: {
    instructions: 'Instructions',
    startingWork: 'Starting Work',
    switchWorkType: 'Switch Work Type',
  },
  fi: {
    instructions: 'Ohjeet',
    startingWork: 'Aloita työt',
    switchWorkType: 'Vaihda työlaji',
  },
  vi: {
    instructions: 'Hướng dẫn',
    startingWork: 'Bắt đầu làm việc',
    switchWorkType: 'Chuyển loại công việc',
  }
};

export default instruction;