import { axiosNew } from './axiosConfig';

export const startActivityApi = async (shiftId, data) => {
  const response = await axiosNew.post(`/vas/activity/${shiftId}/start`, data);
  return response.data;
};

export const selectProductApi = async (shiftId, data) => {
  const response = await axiosNew.post(`/vas/activity/${shiftId}/select-product`, data);
  return response.data;
};

export const addBoxesApi = async (shiftId, data) => {
  const response = await axiosNew.post(`/vas/activity/${shiftId}/add-boxes`, data);
  return response.data;
};

export const endActivityApi = async (shiftId) => {
  const response = await axiosNew.post(`/vas/activity/${shiftId}/end`, {});
  return response.data;
};

export const endShiftApi = async (shiftId) => {
  const response = await axiosNew.post(`/vas/activity/${shiftId}/end-shift`, {});
  return response.data;
};

export const getVasReport = async (data) => {
  const query = {
    startDate: data.startDate,
    endDate: data.endDate,
    ...(data.warehouse && data.warehouse !== 'All' && { warehouse: data.warehouse }),
    ...(data.principal && data.principal !== 'All' && { principal: data.principal }),
    ...(data.task && data.task !== 'All' && { task: data.task }),
    ...(Array.isArray(data.worker) && data.worker.length > 0 && { workers: data.worker }),
    ...(Array.isArray(data.employees) &&
      data.employees.length > 0 && { employees: data.employees }),
  };

  return axiosNew.get('/vas-activity/report', { params: query });
};

export const getWorkOrders = () => {
  return axiosNew.get('/vas/work-order/');
};
