import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BiNotepad, BiStats } from 'react-icons/bi';
import { BsFillGearFill } from 'react-icons/bs';
import {
    FaBriefcaseMedical,
    FaClipboardList,
    FaUserClock,
} from 'react-icons/fa';
import { IoMdAnalytics } from 'react-icons/io';
import { useSelector } from 'react-redux';

import { IconLinkCard } from '../IconLinkCard';
import Rules from './Rules';

const Home = () => {
    const [shown, setShown] = useState(false);
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);

    return (
        <div className="my-2 row">
            <IconLinkCard
                link="/timer"
                icon={<FaUserClock />}
                title={t('navigation.stamp')}
            />
            <IconLinkCard
                link="/report"
                icon={<IoMdAnalytics />}
                title={t('navigation.reports')}
            />
            <IconLinkCard
                link="/sickleave"
                icon={<FaBriefcaseMedical />}
                title={t('navigation.sickleave')}
            />
            {(user.id === 17 || user.id === 23) && (
                <IconLinkCard
                    link="/off-day"
                    icon={<BiNotepad />}
                    title="Holiday"
                />
            )}
            <IconLinkCard
                link="/live-stats-monitor"
                icon={<BiStats />}
                title="Live Stats"
            />
            <IconLinkCard
                link="/setting"
                icon={<BsFillGearFill />}
                title={t('navigation.setting')}
            />
            <IconLinkCard
                link=""
                icon={<FaClipboardList />}
                title={t('navigation.rules')}
                onClick={() => setShown(true)}
            />
            {shown && <Rules setShown={setShown} show={shown} />}
        </div>
    );
};

export default Home;
