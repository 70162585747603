const vasShifts = {
  en: {
    title: 'VAS Shifts',
    employee: 'Employee',
    warehouse: 'Warehouse',
    startTime: 'Start Time',
    endTime: 'End Time',
    selected: 'selected',
    confirmDeleteTitle: 'Confirm Deletion',
    confirmDeleteMessage: 'Are you sure you want to delete the selected shifts?',
    cancel: 'Cancel',
    delete: 'Delete',
    unknown: 'Unknown',
    rowsPerPage: 'Rows per page:',
  },
  fi: {
    title: 'VAS Vuorot',
    employee: 'Työntekijä',
    warehouse: 'Kustannuspaikka',
    startTime: 'Aloitusaika',
    endTime: 'Lopetusaika',
    selected: 'valittu',
    confirmDeleteTitle: 'Vahvista poisto',
    confirmDeleteMessage: 'Haluatko varmasti poistaa valitut vuorot?',
    cancel: 'Peruuta',
    delete: 'Poista',
    unknown: 'Tuntematon',
    rowsPerPage: 'Rivejä per sivu:',
  },
  vi: {
    title: 'Ca làm việc VAS',
    employee: 'Nhân viên',
    warehouse: 'Kho',
    startTime: 'Thời gian bắt đầu',
    endTime: 'Thời gian kết thúc',
    selected: 'đã chọn',
    confirmDeleteTitle: 'Xác nhận xóa',
    confirmDeleteMessage: 'Bạn có chắc chắn muốn xóa các ca làm việc đã chọn?',
    cancel: 'Hủy',
    delete: 'Xóa',
    unknown: 'Không rõ',
    rowsPerPage: 'Số hàng mỗi trang:',
  },
};

export default vasShifts;
