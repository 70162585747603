import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

/**
 * ActionButtons component contains buttons for adding boxes and finalizing
 */
const ActionButtons = ({ 
  handleAddBox, 
  openFinalizeDialog, 
  isLoading, 
  isDelay, 
  delayCountdown,
  user 
}) => {
  const { t } = useTranslation();
  
  // Check if user type is exactly "MN"
  const isMNUserType = user && user.user_type === 'MN';
  
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
      <Button
        onClick={handleAddBox}
        fullWidth
        variant="contained"
        color="error"
        size="large"
        sx={{
          py: 1.5,
          fontSize: '1.25rem',
        }}
        disabled={isLoading || isDelay}
      >
        {isLoading ? (
          <>
            <CircularProgress size={24} color="inherit" />
            &nbsp;{t('selectedProduct.loading')}
          </>
        ) : isDelay ? (
          <>
            <CircularProgress size={24} color="inherit" />
            &nbsp;
            {t('selectedProduct.delayCountdown', {
              count: delayCountdown,
            })}
          </>
        ) : (
          t('selectedProduct.addBox')
        )}
      </Button>
      {isMNUserType && (
        <Button
          onClick={openFinalizeDialog}
          variant="contained"
          color="primary"
          size="large"
          sx={{
            py: 1.5,
            fontSize: '1.25rem',
          }}
          disabled={isLoading || isDelay}
          startIcon={<DoneIcon />}
        >
          {t('selectedProduct.finalize', 'Finalize')}
        </Button>
      )}
    </Stack>
  );
};

ActionButtons.propTypes = {
  handleAddBox: PropTypes.func.isRequired,
  openFinalizeDialog: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDelay: PropTypes.bool.isRequired,
  delayCountdown: PropTypes.number.isRequired,
  user: PropTypes.object
};

export default ActionButtons;