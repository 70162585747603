import { useEffect, useMemo, useState } from 'react';

import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { postShiftTask, stopWork } from '../../../actions/shiftActions';
import {
  endShift,
  fetchActiveActivity,
  startTaskActivity,
} from '../../../actions/vasActivityActions';
// Import the action for VAS Products by work type
import { getVasProductsByWorkTypeId } from '../../../actions/vasProductActions';
import { getPrincipalsTasks, getWarehousePrincipals } from '../../../actions/warehouseActions';
import { sortObject } from '../../HelperFunctions/Others';

const TaskForm = () => {
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedPrincipal, setSelectedPrincipal] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Selectors for principals, warehouses, tasks, and vasProducts
  const principals = sortObject(
    useSelector((state) => state.principal || []),
    'name',
  );
  const warehouses = sortObject(
    useSelector((state) => state.warehouses || []),
    'name',
  );
  const tasks = sortObject(
    useSelector((state) => state.principalTasks || []),
    'title',
  );
  const { vasProducts } = useSelector((state) => state.vasProducts);
  const { shiftActivity } = useSelector((state) => state.vasWorkLog);

  const shift = useSelector((state) => state.shift || []);

  const activeShift = useMemo(() => shift.find((e) => e.is_working === true) || {}, [shift]);
  const latestShift = useMemo(
    () => shift.find((shift) => shift.check_out === null && shift.is_working === true) || {},
    [shift],
  );

  const currentWarehouseName =
    warehouses.find((warehouse) => warehouse.id === latestShift?.warehouse)?.name || '';

  useEffect(() => {
    if (activeShift?.warehouse) {
      dispatch(getWarehousePrincipals(activeShift.warehouse));
    }
  }, [dispatch, activeShift]);

  useEffect(() => {
    if (latestShift?.id && Object.keys(shiftActivity ?? {}).length === 0) {
      dispatch(fetchActiveActivity(latestShift.id));
    }
  }, [dispatch, latestShift, shiftActivity]);

  // When the selected task (work type) changes, dispatch getVasProductsByWorkTypeId
  useEffect(() => {
    if (selectedTask) {
      dispatch(getVasProductsByWorkTypeId(selectedTask));
    }
  }, [dispatch, selectedTask]);

  const handleChangePrincipal = (e) => {
    const id = e.target.value;
    setSelectedPrincipal(id);
    if (activeShift?.warehouse) {
      dispatch(getPrincipalsTasks(activeShift.warehouse, id));
    }
  };

  const startTimer = (event) => {
    event.preventDefault();
    if (activeShift?.id && selectedTask) {
      // Determine viewVAS based on the length of vasProducts.
      const viewVAS = vasProducts && vasProducts.length > 0;

      if (viewVAS) {
        dispatch(
          startTaskActivity(
            {
              employeeId: parseInt(activeShift.worker),
              warehouseId: parseInt(activeShift.warehouse),
              shiftId: activeShift.id,
              principalId: parseInt(selectedPrincipal),
              taskId: parseInt(selectedTask),
            },
            true,
          ),
        );
      }
      dispatch(postShiftTask(activeShift.id, selectedTask));
    }
  };

  const stopWorking = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="custom-alert">
          <h1>{t('timer.warning')}</h1>
          <p>{t('timer.warningText')}</p>
          <div className="warning-buttons">
            <button className="cancel btn" onClick={onClose}>
              {t('timer.cancel')}
            </button>
            <button
              className="stop btn btn-danger"
              onClick={async () => {
                if (latestShift?.id) {
                  setLoading(true);
                  await dispatch(stopWork(latestShift.id));
                  await dispatch(endShift(latestShift.id));
                  setLoading(false);
                  navigate('/');
                  onClose();
                }
              }}
            >
              {t('timer.stopWorking')}
            </button>
          </div>
        </div>
      ),
    });
  };

  const getTime = (timestamp) => {
    if (!timestamp) return '';
    const dateObject = new Date(timestamp);
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="mt-5">
      <h2 className="page-heading">
        <b>{t('timer.startWork')}</b>
      </h2>
      <form>
        <h5 className="mt-4">
          <b>{t('timer.note')}</b> {t('timer.noteText')} {currentWarehouseName}, {t('timer.klo')}:{' '}
          {getTime(latestShift?.check_in)}
        </h5>
        <div className="form-group mt-4">
          <label htmlFor="principal">
            <b>{t('timer.selectPrincipal')}</b>
          </label>
          <select onChange={handleChangePrincipal} className="form-control" id="principal" required>
            <option value="">{t('timer.selectPrincipal')}</option>
            {principals.map((principal) => (
              <option key={principal.id} value={principal.id}>
                {principal.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="task">
            <b>{t('timer.selectWorktype')}</b>
          </label>
          <select
            onChange={({ target }) => setSelectedTask(target.value)}
            className="form-control"
            id="task"
            required
          >
            <option value="">{t('timer.selectWorktype')}</option>
            {tasks.map((task) => (
              <option key={task.id} value={task.id}>
                {t(task.title)}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-danger" onClick={stopWorking} disabled={loading}>
            <b>{loading ? t('common.loading') : t('timer.stopWorking')}</b>
          </button>
          <button className="btn btn-startJob" onClick={startTimer} disabled={loading}>
            <b>{t('timer.startWorking')}</b>
          </button>
        </div>
      </form>
    </div>
  );
};

export default TaskForm;
