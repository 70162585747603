// Permissions configuration for the application
import { lazy } from 'react';

// Component imports
import Guides from '@/components/Dashboard/Guides';
import Applicant from '@/components/Dashboard/Home/Applicant';
import Home from '@/components/Dashboard/Home/Internal';
import Staff from '@/components/Dashboard/Home/Staff';
import ExternalManagerReport from '@/components/Dashboard/Report/ExternalManagerReport';
import InternalReport from '@/components/Dashboard/Report/InternalReport';
import EmployeeList from '@/components/Dashboard/Report/Manager/EmployeeList';
import Print from '@/components/Dashboard/Report/Manager/Print';
import Search from '@/components/Dashboard/Report/Manager/Search';
import ManagerReport from '@/components/Dashboard/Report/ManagerReport';
import ChangePassword from '@/components/Dashboard/Setting/ChangePassword';
import Timer from '@/components/Dashboard/Timer/Timer';
import CreateShifts from '@/components/Dashboard/Tools/CreateShifts';
import EmWorksStatus from '@/components/Dashboard/Tools/EmWorksStatus';
import ExternalManagerTools from '@/components/Dashboard/Tools/ExternalManagerTools';
import Tools from '@/components/Dashboard/Tools/Tools';
import WorksStatus from '@/components/Dashboard/Tools/WorksStatus';
import OffDay from '@/pages/dashboard/offDay/index';
import SafetyPage from '@/pages/dashboard/safety/index';
import AboutApp from '@/pages/dashboard/Setting/AboutApp';
import LiveStatsMonitor from '@/pages/dashboard/tools/LiveStatsMonitor';
import VasReport from '@/features/vas-report';
import Sickleave from '@/pages/dashboard/tools/sickleave/Sickleave';
import SickLeaveListPage from '@/pages/dashboard/tools/sickleave/SickLeaveList';
import VasShift from '@/pages/dashboard/tools/vas-shift/VasShift';
import VasShiftDetails from '@/pages/dashboard/tools/vas-shift/VasShiftDetails';
import VasProductAdd from '@/pages/dashboard/vas/VasProductAdd';
import VasProductList from '@/pages/dashboard/vas/VasProductList';
import Warehouse from '@/components/Dashboard/Home/Warehouse';
import BillingReport from '@/features/billing-report';
import PalletList from '@/pages/dashboard/tools/reports/PalletList';

// Lazy-loaded components
const Statistics = lazy(() => import('@/components/Dashboard/Report/Manager/Statistics'));
const EmStatistics = lazy(() =>
  import('@/components/Dashboard/Report/ExternalManager/EmStatistics'),
);
const Setting = lazy(() => import('@/components/Dashboard/Setting/Setting'));

// Permissions definitions
const generatePermissions = (permissions) =>
  permissions.map(({ path, displayText, component, display = false }) => ({
    path,
    displayText,
    component,
    display,
  }));

export const ExternalPermission = generatePermissions([
  { path: '/', displayText: 'Etusivu', component: <Home />, display: true },
  {
    path: '/timer',
    displayText: 'Työn aloitus',
    component: <Timer />,
    display: true,
  },
  {
    path: '/report',
    displayText: 'Raportit',
    component: <InternalReport />,
    display: true,
  },
  {
    path: '/setting',
    displayText: 'Asetukset',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'vaihda salasana',
    component: <ChangePassword />,
  },
]);

export const InternalPermission = generatePermissions([
  { path: '/', displayText: 'homepage', component: <Home />, display: true },
  {
    path: '/timer',
    displayText: 'stamp',
    component: <Timer />,
    display: true,
  },
  {
    path: '/report',
    displayText: 'reports',
    component: <InternalReport />,
    display: true,
  },
  {
    path: '/setting',
    displayText: 'setting',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'change-password',
    component: <ChangePassword />,
  },
  {
    path: '/live-stats-monitor',
    displayText: 'live-stats-monitor',
    component: <LiveStatsMonitor />,
  },
  {
    path: '/user-guide',
    displayText: 'instructions',
    component: <Guides />,
    display: true,
  },
  { path: '/sickleave', displayText: 'sickleave', component: <Sickleave /> },
  { path: '/off-day', displayText: 'off-day', component: <OffDay /> },
]);

export const ManagerPermission = generatePermissions([
  { path: '/', displayText: 'homepage', component: <Staff />, display: true },
  {
    path: '/timer',
    displayText: 'stamp',
    component: <Timer />,
    display: true,
  },
  {
    path: '/tools',
    displayText: 'tools',
    component: <Tools />,
    display: true,
  },
  {
    path: '/tools/live-stats-monitor',
    displayText: 'live-stats-monitor',
    component: <LiveStatsMonitor />,
  },
  {
    path: '/tools/workingstatus',
    displayText: 'Työ status',
    component: <WorksStatus />,
  },
  {
    path: '/tools/vas-products-list',
    displayText: 'vas-products-list',
    component: <VasProductList />,
  },
  {
    path: '/tools/vas-products-list/add',
    displayText: 'vas-products-add',
    component: <VasProductAdd />,
  },
  {
    path: '/tools/vas-products-list/edit/:id',
    displayText: 'vas-products-edit',
    component: <VasProductAdd />,
  },
  {
    path: '/tools/reports',
    displayText: 'Raportit',
    component: <ManagerReport />,
  },
  {
    path: '/tools/reports/search',
    displayText: 'Haku',
    component: <Search />,
  },
  {
    path: '/tools/reports/bill',
    displayText: 'Billing Report',
    component: <BillingReport />,
  },
  {
    path: '/tools/reports/print',
    displayText: 'printReport',
    component: <Print />,
  },
  {
    path: '/tools/reports/employees',
    displayText: 'listOfEmployees',
    component: <EmployeeList />,
  },
  {
    path: '/tools/reports/statistics',
    displayText: 'marketShare',
    component: <Statistics />,
  },
  {
    path: '/tools/reports/vas-report',
    displayText: 'vasReport',
    component: <VasReport />,
  },
  {
    path: '/tools/reports/pallets',
    displayText: 'Pallet List',
    component: <PalletList />,
  },
  {
    path: '/tools/create-shifts',
    displayText: 'luoTyövuorot',
    component: <CreateShifts />,
  },
  {
    path: '/tools/vas-shifts',
    displayText: 'vas shifts',
    component: <VasShift />,
  },
  {
    path: '/tools/vas-shifts/:id',
    displayText: 'vas shift detail',
    component: <VasShiftDetails />,
  },

  { path: '/reports', displayText: 'Raportit', component: <ManagerReport /> },
  {
    path: '/setting',
    displayText: 'setting',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'vaihda salasana',
    component: <ChangePassword />,
  },
  { path: '/setting/about', displayText: 'Tietoa', component: <AboutApp /> },
  {
    path: '/user-guide',
    displayText: 'instructions',
    component: <Guides />,
    display: true,
  },
  { path: '/sickleave', displayText: 'sickleave', component: <Sickleave /> },
  {
    path: '/sickleave/list',
    displayText: 'sickleave',
    component: <SickLeaveListPage />,
  },
  { path: '/safety', displayText: 'safety', component: <SafetyPage /> },
]);

export const ExternalManagerPermission = generatePermissions([
  { path: '/', displayText: 'Etusivu', component: <Staff />, display: true },
  {
    path: '/timer',
    displayText: 'Työn aloitus',
    component: <Timer />,
    display: true,
  },
  {
    path: '/tools',
    displayText: 'Työkalut',
    component: <ExternalManagerTools />,
    display: true,
  },
  {
    path: '/tools/reports/statistics',
    displayText: 'marketShare',
    component: <EmStatistics />,
  },
  {
    path: '/tools/workingstatus',
    displayText: 'Työ status',
    component: <EmWorksStatus />,
  },
  {
    path: '/tools/reports',
    displayText: 'Raportit',
    component: <ExternalManagerReport />,
  },
  {
    path: '/tools/reports/search',
    displayText: 'Haku',
    component: <Search />,
  },
  { path: '/reports', displayText: 'Raportit', component: <ManagerReport /> },
  {
    path: '/setting',
    displayText: 'Asetukset',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'vaihda salasana',
    component: <ChangePassword />,
  },
]);

export const ApplicantPermission = generatePermissions([
  { path: '/', displayText: 'Etusivu', component: <Applicant /> },
]);

export const WarehousePermission = generatePermissions([
  {
    path: '/',
    displayText: 'homepage',
    component: <Warehouse />,
    display: true,
  },
  {
    path: '/setting',
    displayText: 'setting',
    component: <Setting />,
    display: true,
  },
  {
    path: '/setting/change-password',
    displayText: 'change-password',
    component: <ChangePassword />,
  },
  {
    path: '/live-stats-monitor',
    displayText: 'live-stats-monitor',
    component: <LiveStatsMonitor />,
    display: true,
  },
]);
