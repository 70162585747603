import React from 'react';

import { useSelector } from 'react-redux';

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

const ShiftActivitiesTable = () => {
    // Extract shift activities and products from the Redux store.
    const { shiftActivities } = useSelector((state) => state.vasWorkLog);
    const { vasProducts } = useSelector((state) => state.vasProducts);

    // Show a loading message if the data isn't available yet.
    if (!shiftActivities || !vasProducts) {
        return <Typography>Loading shift activities...</Typography>;
    }

    // Create a sorted copy of shiftActivities by startTime in descending order.
    const sortedActivities = [...shiftActivities].sort((a, b) => {
        // If both activities have a startTime, sort descending.
        if (a.startTime && b.startTime) {
            return new Date(b.startTime) - new Date(a.startTime);
        }
        // If only one has a startTime, place the one missing startTime at the end.
        if (!a.startTime) return 1;
        if (!b.startTime) return -1;
        return 0;
    });

    return (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>Product Code</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Boxes Completed</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Start Time</strong>
                        </TableCell>
                        <TableCell>
                            <strong>End Time</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedActivities.map((activity) => {
                        // Find the matching product for the current activity.
                        const product = vasProducts.find(
                            (p) => p.id === activity.productId
                        );

                        return (
                            <TableRow key={activity.id}>
                                <TableCell>
                                    {product ? product.productCode : 'N/A'}
                                </TableCell>
                                <TableCell>{activity.boxesCompleted}</TableCell>
                                <TableCell>
                                    {activity.startTime
                                        ? new Date(
                                              activity.startTime
                                          ).toLocaleString()
                                        : '-'}
                                </TableCell>
                                <TableCell>
                                    {activity.endTime
                                        ? new Date(
                                              activity.endTime
                                          ).toLocaleString()
                                        : '-'}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ShiftActivitiesTable;
