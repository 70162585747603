import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import EmptyContent from '../empty-content';

TableNoData.propTypes = {
    isNotFound: PropTypes.bool,
    colSpan: PropTypes.number,
};

export default function TableNoData({ isNotFound, colSpan }) {
    return (
        <TableRow>
            {isNotFound ? (
                <TableCell colSpan={colSpan}>
                    <EmptyContent
                        title="No Data"
                        sx={{
                            '& span.MuiBox-root': { height: 160 },
                        }}
                    />
                </TableCell>
            ) : (
                <TableCell colSpan={colSpan} sx={{ p: 0 }} />
            )}
        </TableRow>
    );
}
