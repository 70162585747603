import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { userLogout } from '../../actions/userActions';
import {
  ApplicantPermission,
  ExternalManagerPermission,
  ExternalPermission,
  InternalPermission,
  ManagerPermission,
  WarehousePermission,
} from '../../configs/RolePermission';
import NotificationsPopover from '../../layouts/dashboard/header/notification/NotificationsPopover';
import ChangeLanguageBar from '../Others/Language';
import Notification from '../Others/Notification';
import Routing from '../Others/Routing';

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const user = useSelector((state) => state.user);

  const Permission = useMemo(() => {
    switch (user.user_type) {
      case 'ET':
        return ExternalPermission;
      case 'IT':
        return InternalPermission;
      case 'EM':
        return ExternalManagerPermission;
      case 'MN':
        return ManagerPermission;
      case 'AP':
        return ApplicantPermission;
      case 'WH':
        return WarehousePermission;
      default:
        return [];
    }
  }, [user.user_type]);

  const handleLogout = () => {
    // First dispatch logout to clear individual states
    dispatch(userLogout());
    // Then navigate
    navigate('/');
  };

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);
  const closeDrawer = () => setDrawerOpen(false);

  const navItems = Permission.filter((p) => p.display).map((p) => (
    <Button
      component={Link}
      to={p.path}
      key={p.path}
      onClick={closeDrawer}
      sx={{
        color: 'white',
        mx: 1,
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
      }}
    >
      {t(`navigation.${p.displayText}`)}
    </Button>
  ));

  const drawer = (
    <Box onClick={closeDrawer} sx={{ textAlign: 'center' }}>
      <Link to="/" className="text-decoration-none">
        <span className="andromeda-logo">ANDROMEDA</span>
      </Link>
      <Divider />
      <List>
        {Permission.filter((p) => p.display).map((p) => (
          <ListItem key={p.path} disablePadding>
            <ListItemButton
              component={Link}
              to={p.path}
              sx={{
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: 'rgba(0, 144, 255, 0.08)',
                  color: 'primary.main',
                  fontWeight: 'bold',
                },
              }}
            >
              <ListItemText primary={t(`navigation.${p.displayText}`)} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleLogout}
            sx={{
              textAlign: 'center',
              '&:hover': {
                backgroundColor: 'rgba(255, 59, 59, 0.08)',
                color: 'error.main',
                fontWeight: 'bold',
              },
            }}
          >
            <ListItemText primary={t('login.logout')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Notification />
      <AppBar position="static" sx={{ bgcolor: 'primary.main' }}>
        <Container>
          <Toolbar>
            <Box
              component={Link}
              to="/"
              onClick={closeDrawer}
              sx={{
                flexGrow: { xs: 1, md: 0 },
                mr: 2,
                textDecoration: 'none',
              }}
            >
              <span className="andromeda-logo">ANDROMEDA</span>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>{navItems}</Box>

            <Stack direction="row" spacing={2} alignItems="center">
              <NotificationsPopover />

              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleLogout}
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                      borderColor: 'white',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                  }}
                >
                  {t('login.logout')}
                </Button>
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  edge="end"
                  color="inherit"
                  onClick={toggleDrawer}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={closeDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <ChangeLanguageBar userEmail={user.email} />
      <Container>
        <Routing permission={Permission} />
      </Container>
    </>
  );
};

export default Navigation;
