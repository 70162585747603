import { axiosMain } from './axiosConfig';

const getShift = async () => {
  const response = await axiosMain.get('/shift/');
  return response.data;
};

const getWorkingShift = async () => {
  const response = await axiosMain.get('/shift/?is_working=true');
  return response.data;
};

const getShiftByWorkerID = async (id) => {
  const response = await axiosMain.get(`/shift/?worker=${id}`);
  return response.data;
};

const postShift = async (worker, warehouse, time, is_working) => {
  const data = {
    worker,
    check_in: time,
    is_working,
    warehouse,
  };

  const response = await axiosMain.post('/shift/', data);
  return response.data;
};

const createShift = async (worker, warehouse, assignedStart, assignedEnd) => {
  const data = {
    worker,
    assigned_start: assignedStart,
    assigned_end: assignedEnd,
    warehouse,
  };

  const response = await axiosMain.post('/shift/', data);
  return response.data;
};

const getShiftTask = async (id) => {
  const response = await axiosMain.get(`/shift/${id}/task/`);
  return response.data;
};

const patchShift = async (id, data) => {
  const response = await axiosMain.patch(`/shift/${id}/`, data);
  return response.data;
};

const postShiftTask = async (warehouseID, workType, time) => {
  const data = {
    work_type: workType,
    start: time,
  };

  const response = await axiosMain.post(`/shift/${warehouseID}/task/`, data);
  return response.data;
};

const putShiftTask = async (inputdata) => {
  const data = {
    work_type: inputdata.workType,
    end: inputdata.time,
  };

  const response = await axiosMain.put(
    `/shift/${inputdata.shiftID}/task/${inputdata.taskID}/`,
    data,
  );
  return response.data;
};

const shiftApi = {
  getShift,
  postShift,
  createShift,
  getShiftTask,
  postShiftTask,
  putShiftTask,
  patchShift,
  getShiftByWorkerID,
  getWorkingShift,
};

export default shiftApi;
