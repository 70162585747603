import { eachDayOfInterval, isWithinInterval, sub } from 'date-fns';

import reportApi from '../api/report';
import shiftApi from '../api/shift';
import { getReportAbsences } from '../api/sickleave';
import { dateToUtcEnd, dateToUtcStart } from '../utils/DateTime';
import { getCheckIn, getCheckOut } from '../utils/shiftTimeCompare';

export const internalReportSearch = (data) => {
  return async (dispatch) => {
    let startDate;
    let endDate;

    if (data.startDate) {
      startDate = dateToUtcStart(data.startDate);
    } else {
      startDate = '';
    }

    if (data.endDate) {
      endDate = dateToUtcEnd(data.endDate);
    } else {
      endDate = '';
    }

    let warehouse;
    if (data.warehouse === 'All') {
      warehouse = '';
    } else {
      warehouse = data.warehouse;
    }

    let worker;
    if (Array.isArray(data.worker) && data.worker.length > 0) {
      // Worker array will be handled in the shifts filtering below
      worker = '';
    } else if (Number.isInteger(parseInt(data.worker)) || typeof data.worker === 'number') {
      worker = data.worker;
    } else {
      worker = '';
    }

    const newData = {
      startDate,
      endDate,
      warehouse,
      worker,
    };

    const shifts = await reportApi.searchShifts(newData);
    const newShifts = [];

    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];

      // Filter by worker array if provided
      if (Array.isArray(data.worker) && data.worker.length > 0) {
        const workerIds = data.worker.map((id) => (typeof id === 'string' ? parseInt(id, 10) : id));
        if (!workerIds.includes(parseInt(shift.worker, 10))) {
          continue;
        }
      }

      const check_in = shift.check_in;
      const check_out = shift.check_out;
      const warehouse = shift.warehouse;
      const tasks = await shiftApi.getShiftTask(shift.id);
      let workTypeTotal = [];
      for (let j = 0; j < tasks.length; j++) {
        const element = tasks[j];
        const work_type = {
          work_type: element.work_type,
          work_type_name: element.work_type_name,
          total: [
            {
              start: element.start,
              end: element.end,
            },
          ],
        };
        const foundWorkType = workTypeTotal.find((e) => e.work_type === element.work_type);
        if (foundWorkType) {
          const indexFoundWorkType = workTypeTotal.indexOf(foundWorkType);
          const startEnd = { start: element.start, end: element.end };
          workTypeTotal[indexFoundWorkType].total.push(startEnd);
        } else {
          workTypeTotal.push(work_type);
        }
      }
      const newShift = {
        check_in,
        check_out,
        warehouse,
        tasks,
        workTypeTotal,
      };
      newShifts.push(newShift);
    }

    dispatch({
      type: 'SEARCHREPORT',
      payload: newShifts,
    });
  };
};

export const managerReportSearch = (data) => {
  return async (dispatch) => {
    let startDate;
    let endDate;

    if (data.startDate) {
      startDate = dateToUtcStart(data.startDate);
    } else {
      startDate = '';
    }

    if (data.endDate) {
      endDate = dateToUtcEnd(data.endDate);
    } else {
      endDate = '';
    }

    // Handle warehouse filter
    let warehouse = '';
    // Single warehouse case (when it's a number or numeric string)
    if (
      (Number.isInteger(parseInt(data.warehouse)) || typeof data.warehouse === 'number') &&
      !Array.isArray(data.warehouse)
    ) {
      warehouse = data.warehouse;
    }
    // Array case will be handled during shift filtering

    // Fix handling of worker filter
    let worker;
    if (
      (Number.isInteger(parseInt(data.worker)) || typeof data.worker === 'number') &&
      !Array.isArray(data.worker)
    ) {
      worker = data.worker;
    } else {
      worker = '';
    }

    const newData = {
      startDate,
      endDate,
      warehouse,
      worker,
    };

    const shifts = await reportApi.searchShifts(newData);
    const newShifts = [];

    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      const warehouse = shift.warehouse;
      const worker = shift.worker;
      const worker_name = shift.worker_name;
      const tasks = shift.tasks;

      // Correctly filter by warehouse array
      if (Array.isArray(data.warehouse) && data.warehouse.length > 0) {
        // Convert to numbers for proper comparison
        const warehouseIds = data.warehouse.map((id) =>
          typeof id === 'string' ? parseInt(id, 10) : id,
        );
        if (!warehouseIds.includes(parseInt(warehouse, 10))) {
          continue;
        }
      }

      // Correctly filter by worker array
      if (Array.isArray(data.worker) && data.worker.length > 0) {
        // Convert to numbers for proper comparison
        const workerIds = data.worker.map((id) => (typeof id === 'string' ? parseInt(id, 10) : id));
        if (!workerIds.includes(parseInt(worker, 10))) {
          continue;
        }
      }

      const newTask = [];
      let workTypeTotal = [];

      let check_in = getCheckIn(shift.check_in, shift.assigned_start);
      let check_out = getCheckOut(shift.check_out, shift.assigned_end, shift.is_extra);

      for (let j = 0; j < tasks.length; j++) {
        const element = tasks[j];

        // if not selected All principal or principal dint match then skip this task
        if ((element.principal_id === Number(data.principal)) === (data.principal === 'All')) {
          continue;
        }

        // if not selected All work_type or work_type dint match then skip this task
        if ((element.work_type === Number(data.task)) === (data.task === 'All')) {
          continue;
        }

        const work_type = {
          work_type: element.work_type,
          work_type_name: element.work_type_name,
          total: [
            {
              start: element.start,
              end: element.end,
            },
          ],
        };

        const foundWorkType = workTypeTotal.find((e) => e.work_type === element.work_type);

        if (foundWorkType) {
          const indexFoundWorkType = workTypeTotal.indexOf(foundWorkType);
          const startEnd = { start: element.start, end: element.end };
          workTypeTotal[indexFoundWorkType].total.push(startEnd);
        } else {
          workTypeTotal.push(work_type);
        }

        newTask.push(element);
      }

      if (newTask.length > 0) {
        const newShift = {
          id: shift.id,
          check_in,
          check_out,
          assigned_start: shift.assigned_start,
          worker,
          worker_name,
          tasks: newTask,
          warehouse,
          workTypeTotal,
        };

        newShifts.push(newShift);
      }
    }

    if (data?.filter?.sickleave) {
      const sicleaves = await getReportAbsences();

      let filteridsickleaves = [];

      // Fix sick leave filtering for array of workers
      if (Array.isArray(data.worker) && data.worker.length > 0) {
        // Convert worker IDs to numbers for comparison
        const workerIds = data.worker.map((id) => (typeof id === 'string' ? parseInt(id, 10) : id));
        filteridsickleaves = sicleaves.filter((leave) => workerIds.includes(leave.user_id));
      } else if (newData.worker) {
        // Single worker case
        filteridsickleaves = sicleaves.filter((leave) => leave.user_id === Number(newData.worker));
      } else {
        // No worker filter
        filteridsickleaves = sicleaves;
      }

      filteridsickleaves?.forEach((leave) => {
        if (new Date(leave.end_date).toISOString() === new Date(leave.start_date).toISOString()) {
          const inSearch = isWithinInterval(new Date(leave.start_date), {
            start: new Date(newData.startDate),
            end: newData.endDate ? new Date(newData.endDate) : new Date(),
          });

          if (inSearch) {
            const newShift = {
              id: leave.id,
              check_in: leave.start_date,
              check_out: leave.end_date,
              worker: leave.user_id,
              worker_name: leave.worker_name,
              tasks: [],
              warehouse: 'SICK',
              workTypeTotal: [],
            };
            newShifts.push(newShift);
          }
        } else {
          const eachDay = eachDayOfInterval({
            start: leave.start_date,
            end: leave.end_date,
          });
          eachDay.forEach((day) => {
            const inSearch = isWithinInterval(new Date(day), {
              start: new Date(newData.startDate),
              end: newData.endDate ? new Date(newData.endDate) : new Date(),
            });

            if (inSearch) {
              const newShift = {
                id: leave.id,
                check_in: day,
                check_out: day,
                worker: leave.user_id,
                worker_name: leave.worker_name,
                tasks: [],
                warehouse: 'SICK',
                workTypeTotal: [],
              };
              newShifts.push(newShift);
            }
          });
        }
      });
    }

    dispatch({
      type: 'SEARCHREPORT',
      payload: newShifts,
    });

    if (!Number.isInteger(data.warehouse) && !Array.isArray(data.warehouse)) {
      data.warehouse = 'All';
    }

    dispatch({
      type: 'SETSEACHPARAMS',
      payload: data,
    });
  };
};

export const warehouseShares = () => {
  return async (dispatch) => {
    const shifts = await reportApi.warehouseShares();
    dispatch({
      type: 'WAREHOUSESHARES',
      payload: shifts,
    });
  };
};

export const latestSevenDays = () => {
  const startDate = dateToUtcStart(
    sub(new Date(), {
      days: 7,
    }),
  );

  const newData = {
    startDate,
    endDate: '',
    warehouse: '',
    worker: '',
    is_working: 'false',
  };

  return async (dispatch) => {
    const shifts = await reportApi.searchShifts(newData);

    shifts.map((shift) => {
      shift.check_in = getCheckIn(shift.check_in, shift.assigned_start);
      shift.check_out = getCheckOut(shift.check_out, shift.assigned_end, shift.is_extra);

      return shift;
    });

    dispatch({
      type: 'LATEST7DAYS',
      payload: shifts,
    });
  };
};

export const shiftsOfToday = () => {
  const startDate = dateToUtcStart(
    sub(new Date(), {
      days: 0,
    }),
  );

  const newData = {
    startDate,
    endDate: '',
    warehouse: '',
    worker: '',
  };
  return async (dispatch) => {
    const shifts = await reportApi.searchShifts(newData);
    shifts.map((shift) => {
      shift.check_in = getCheckIn(shift.check_in, shift.assigned_start);
      shift.check_out = getCheckOut(shift.check_out, shift.assigned_end, shift.is_extra);

      return shift;
    });

    dispatch({
      type: 'TODAYSHIFTS',
      payload: shifts,
    });
  };
};
