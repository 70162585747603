import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { getUsers, getWarehouse } from '../../../../actions';
import { getVasProducts } from '../../../../actions/vasProductActions';
import {
  getVasShift,
  updateVasShift,
} from '../../../../actions/vasShiftActions';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/iconify';

/**
 * Converts a local datetime-local string (YYYY-MM-DDTHH:mm)
 * to a UTC ISO timestamp string.
 */
const convertLocalDateTimeToUTCISOString = localDateTime => {
  if (!localDateTime) return '';
  const date = new Date(localDateTime);
  if (isNaN(date.getTime())) return '';
  return date.toISOString();
};

/**
 * Converts a UTC ISO timestamp into a string formatted for a
 * datetime-local input (YYYY-MM-DDTHH:mm) in the local timezone.
 */
const formatLocalDateTime = dateString => {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '';
  // Create local date string using the local parts
  const pad = num => String(num).padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

/**
 * Calculates the duration in decimal hours between two dates
 * @param {string|Date} startDate - The start date/time
 * @param {string|Date} endDate - The end date/time
 * @returns {string} Duration in decimal hours, always with 2 decimal places
 */
const calculateDurationInHours = (startDate, endDate) => {
  if (!startDate || !endDate) return '0.00';

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) return '0.00';

  // Calculate milliseconds difference and convert to hours
  const durationMs = end.getTime() - start.getTime();
  const durationHours = durationMs / (1000 * 60 * 60);

  // Format to always show 2 decimal places
  return durationHours.toFixed(2);
};

const VasShiftDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Retrieve VAS Shift details from Redux state
  const { vasShift, loading, error } = useSelector(
    state => state.vasShifts || {}
  );

  // Retrieve VAS Products (for the activities select dropdown)
  const { vasProducts } = useSelector(state => state.vasProducts || {});

  // Retrieve warehouse and employee data from Redux state
  const warehouses = useSelector(state => state.warehouses);
  const employees = useSelector(state => state.users);

  const [formData, setFormData] = useState({
    id: '',
    createdAt: '',
    startTime: '',
    endTime: '',
    shiftId: '',
    warehouseId: '',
    employeeId: '',
    activities: [],
  });

  // Fetch the VAS Shift details if an id is provided
  useEffect(() => {
    if (id) {
      dispatch(getVasShift(id));
    }
  }, [dispatch, id]);

  // Fetch VAS products for the activities dropdown
  useEffect(() => {
    dispatch(getVasProducts());
  }, [dispatch]);

  // Fetch Warehouse and Employee data so their names can be used
  useEffect(() => {
    dispatch(getWarehouse());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  // Populate form data once vasShift is loaded
  useEffect(() => {
    if (vasShift) {
      setFormData({
        id: vasShift.id || '',
        createdAt: vasShift.createdAt || '',
        startTime: vasShift.startTime || '',
        endTime: vasShift.endTime || '',
        shiftId: vasShift.shiftId || '',
        warehouseId: vasShift.warehouseId || '',
        employeeId: vasShift.employeeId || '',
        activities: vasShift.activities || [],
      });
    }
    // Don't attempt to populate the form if there's an error
  }, [vasShift, error]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Autocomplete employee change
  const handleEmployeeChange = (event, newValue) => {
    setFormData(prevData => ({
      ...prevData,
      employeeId: newValue ? newValue.id : '',
    }));
  };

  const handleActivityChange = (index, e) => {
    const { name, value } = e.target;
    setFormData(prevData => {
      const updatedActivities = prevData.activities.map((activity, i) => {
        if (i === index) {
          let updatedActivity = { ...activity, [name]: value };

          if (name === 'productId') {
            const selectedProduct = vasProducts.find(
              product => product.id === value
            );
            updatedActivity.productPcs = selectedProduct
              ? selectedProduct.pcs
              : 0;
          }
          return updatedActivity;
        }
        return activity;
      });
      return { ...prevData, activities: updatedActivities };
    });
  };

  // Prevent scroll wheel from changing number input values
  const handleWheel = e => {
    // Prevent the default scroll behavior when input is focused
    e.target.blur();
  };

  const handleSave = async e => {
    e.preventDefault();

    // Convert the top-level shift times to UTC ISO strings
    const updatedStartTime = convertLocalDateTimeToUTCISOString(
      formData.startTime
    );
    const updatedEndTime = formData.endTime
      ? convertLocalDateTimeToUTCISOString(formData.endTime)
      : '';

    // Also convert the activity times to UTC ISO strings
    const updatedActivities = formData.activities.map(activity => {
      const updatedActivity = {
        ...activity,
        startTime: convertLocalDateTimeToUTCISOString(activity.startTime),
      };

      const updatedEndTime = convertLocalDateTimeToUTCISOString(
        activity.endTime
      );
      if (updatedEndTime) {
        updatedActivity.endTime = updatedEndTime;
      } else {
        delete updatedActivity.endTime;
      }

      return updatedActivity;
    });

    const updatedFormData = {
      ...formData,
      startTime: updatedStartTime,
      activities: updatedActivities,
    };

    // Remove endTime if it is empty
    if (!updatedEndTime) {
      delete updatedFormData.endTime;
    } else {
      updatedFormData.endTime = updatedEndTime;
    }

    try {
      await dispatch(updateVasShift(formData.id, updatedFormData));
      navigate('/tools/vas-shifts/'); // Navigate after successful update
    } catch (error) {
      console.error('Update failed', error);
    }
  };

  // Look up the corresponding warehouse and employee objects using their IDs.
  const warehouseObj = warehouses?.find(w => w.id === formData.warehouseId);

  return (
    <Page title="VAS Shift Details">
      <Container>
        <HeaderBreadcrumbs
          heading="VAS Shift Details"
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'Tools', href: '/tools' },
            { name: 'VAS Shifts', href: '/tools/vas-shifts' },
            { name: 'VAS Shift Details' },
          ]}
        />

        {loading ? (
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Stack sx={{ p: 2 }} spacing={2}>
                <LinearProgress />
                <Typography variant="body1" align="center">
                  Loading shift details...
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 3 }}>
            Error: {error}
          </Alert>
        ) : (
          <Box component="form" onSubmit={handleSave} noValidate>
            {/* Main Information Card */}
            <Card sx={{ mb: 3 }}>
              <CardHeader
                title="Shift Information"
                avatar={
                  <Iconify icon="mdi:clock-time-four-outline" width={24} />
                }
              />
              <Divider />
              <CardContent>
                {/* Warehouse Info */}
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ mb: 3 }}
                >
                  <Iconify icon="mdi:warehouse" width={20} />
                  <Typography variant="subtitle1">
                    <strong>Warehouse:</strong>
                  </Typography>
                  <Chip
                    label={
                      warehouseObj
                        ? warehouseObj.name
                        : formData.warehouseId || 'N/A'
                    }
                    variant="soft"
                    color="info"
                  />
                </Stack>

                <Grid container spacing={3}>
                  {/* Employee Selection - replaced with Autocomplete */}
                  <Grid item md={12}>
                    <Autocomplete
                      id="employee-autocomplete"
                      options={employees || []}
                      getOptionLabel={option =>
                        option.fullName ||
                        `${option.first_name || ''} ${option.last_name || ''}`
                      }
                      value={
                        employees?.find(
                          emp => emp.id === formData.employeeId
                        ) || null
                      }
                      onChange={handleEmployeeChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Employee"
                          variant="outlined"
                          fullWidth
                          placeholder="Search employee..."
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </Grid>

                  {/* Shift Start and End Time */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Start Time"
                      name="startTime"
                      type="datetime-local"
                      value={formatLocalDateTime(formData.startTime)}
                      fullWidth
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="End Time"
                      name="endTime"
                      type="datetime-local"
                      value={formatLocalDateTime(formData.endTime)}
                      fullWidth
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Activities Section */}
            <Card sx={{ mb: 3 }}>
              <CardHeader
                title="Activities"
                avatar={
                  <Iconify icon="mdi:clipboard-list-outline" width={24} />
                }
                action={
                  <Tooltip title="Activities performed during this shift">
                    <IconButton>
                      <Iconify icon="mdi:information-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                {formData.activities && formData.activities.length > 0 ? (
                  <Stack spacing={2}>
                    {formData.activities.map((activity, index) => {
                      const selectedProduct = vasProducts?.find(
                        p => p.id === activity.productId
                      );

                      // Calculate duration in hours
                      const durationInHours = calculateDurationInHours(
                        activity.startTime,
                        activity.endTime
                      );

                      // Calculate total PCS for this activity
                      const totalPcs =
                        (activity.boxesCompleted || 0) *
                        (activity.productPcs || 0);

                      // Use performance from activity if available, otherwise calculate it
                      const performance =
                        activity.performance ||
                        (activity.boxesCompleted !== undefined &&
                        activity.productPcs &&
                        durationInHours
                          ? Math.round(
                              ((activity.boxesCompleted * activity.productPcs) /
                                durationInHours) *
                                100
                            ) / 100
                          : 0);

                      return (
                        <Card
                          key={activity.id || index}
                          variant="outlined"
                          sx={{ position: 'relative' }}
                        >
                          <Box
                            sx={{ position: 'absolute', top: 10, right: 10 }}
                          >
                            <Chip
                              label={`Activity ${index + 1}`}
                              size="small"
                              color="primary"
                              variant="soft"
                            />
                          </Box>
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                  <TextField
                                    label="Start Time"
                                    name="startTime"
                                    type="datetime-local"
                                    value={formatLocalDateTime(
                                      activity.startTime
                                    )}
                                    onChange={e =>
                                      handleActivityChange(index, e)
                                    }
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                  />
                                  <TextField
                                    label="End Time"
                                    name="endTime"
                                    type="datetime-local"
                                    value={formatLocalDateTime(
                                      activity.endTime
                                    )}
                                    onChange={e =>
                                      handleActivityChange(index, e)
                                    }
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                  />

                                  {/* Duration and Performance Metrics */}
                                  <Paper
                                    variant="outlined"
                                    sx={{
                                      p: 1.5,
                                      bgcolor: 'background.neutral',
                                    }}
                                  >
                                    <Stack spacing={1}>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Iconify
                                          icon="mdi:timer-outline"
                                          color="info.main"
                                        />
                                        <Typography variant="caption">
                                          Duration:{' '}
                                          <strong>{durationInHours} h</strong>
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Iconify
                                          icon="mdi:chart-line"
                                          color="success.main"
                                        />
                                        <Typography variant="caption">
                                          Performance:{' '}
                                          <strong>
                                            {performance !== undefined &&
                                            performance !== null
                                              ? performance
                                              : 0}{' '}
                                            kpl/h
                                          </strong>
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Paper>
                                </Stack>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel id={`product-label-${index}`}>
                                      Product
                                    </InputLabel>
                                    <Select
                                      labelId={`product-label-${index}`}
                                      label="Product"
                                      name="productId"
                                      value={
                                        vasProducts &&
                                        vasProducts.some(
                                          product =>
                                            product.id === activity.productId
                                        )
                                          ? activity.productId
                                          : ''
                                      }
                                      onChange={e =>
                                        handleActivityChange(index, e)
                                      }
                                    >
                                      <MenuItem value="">
                                        Select a product
                                      </MenuItem>
                                      {vasProducts &&
                                        vasProducts.map(product => (
                                          <MenuItem
                                            key={product.id}
                                            value={product.id}
                                          >
                                            {product.productCode}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>

                                  <TextField
                                    label="Boxes Completed"
                                    name="boxesCompleted"
                                    value={
                                      activity.boxesCompleted !== undefined &&
                                      activity.boxesCompleted !== null
                                        ? activity.boxesCompleted
                                        : ''
                                    }
                                    onChange={e =>
                                      handleActivityChange(index, e)
                                    }
                                    fullWidth
                                    type="number"
                                    onWheel={handleWheel}
                                    InputProps={{
                                      startAdornment: (
                                        <Iconify
                                          icon="mdi:package-variant-closed"
                                          sx={{
                                            mr: 1,
                                            color: 'text.secondary',
                                          }}
                                        />
                                      ),
                                    }}
                                    variant="outlined"
                                  />

                                  {selectedProduct && (
                                    <Paper
                                      variant="outlined"
                                      sx={{
                                        p: 1.5,
                                        bgcolor: 'background.neutral',
                                      }}
                                    >
                                      <Stack spacing={1}>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={1}
                                        >
                                          <Iconify
                                            icon="mdi:information-outline"
                                            color="info.main"
                                          />
                                          <Typography variant="caption">
                                            Product Pcs:{' '}
                                            <strong>
                                              {activity.productPcs || 0}
                                            </strong>
                                          </Typography>
                                        </Stack>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={1}
                                        >
                                          <Iconify
                                            icon="mdi:package-variant-closed"
                                            color="success.main"
                                          />
                                          <Typography variant="caption">
                                            Total Pcs:{' '}
                                            <strong>{totalPcs}</strong>
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </Paper>
                                  )}
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Stack>
                ) : (
                  <Box sx={{ py: 4, textAlign: 'center' }}>
                    <Iconify
                      icon="mdi:clipboard-text-outline"
                      width={40}
                      height={40}
                      sx={{ mb: 2, color: 'text.secondary' }}
                    />
                    <Typography variant="body1" color="text.secondary">
                      No activities available for this shift
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => navigate('/tools/vas-shifts/')}
                startIcon={<Iconify icon="mdi:arrow-left" />}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="mdi:content-save" />}
              >
                Save Changes
              </Button>
            </Stack>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default VasShiftDetails;
