import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

/**
 * ProductHeader component displays product info and switch button
 */
const ProductHeader = ({ product, onDeselect }) => {
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h5" color="text.primary">
          {product.productDescription}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.productCode}
        </Typography>
      </Box>
      <Button variant="contained" color="primary" onClick={onDeselect}>
        {t('selectedProduct.switchProduct')}
      </Button>
    </Box>
  );
};

ProductHeader.propTypes = {
  product: PropTypes.object.isRequired,
  onDeselect: PropTypes.func.isRequired,
};

export default ProductHeader;