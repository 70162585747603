import useReportFormData from './useReportFormData';

/**
 * useReportData Hook
 *
 * @deprecated Use useReportFormData instead. This hook is maintained for backward compatibility.
 * @returns {Object} Data and utility functions for reports
 */
export const useReportData = () => {
  // Simply re-export the report form data hook's functionality
  return useReportFormData();
};

export default useReportData;
