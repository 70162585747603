import {
    DELETE_VAS_SHIFTS_FAILURE,
    DELETE_VAS_SHIFTS_REQUEST,
    DELETE_VAS_SHIFTS_SUCCESS,
    GET_VAS_SHIFT_FAILURE,
    GET_VAS_SHIFT_REQUEST,
    GET_VAS_SHIFT_SUCCESS,
    GET_VAS_SHIFTS_FAILURE,
    GET_VAS_SHIFTS_REQUEST,
    GET_VAS_SHIFTS_SUCCESS,
} from '../actions/vasShiftActions';

const initialState = {
    vasShiftsResponse: {
        data: [],
        total: 0,
        page: 1,
        limit: 25,
        totalPages: 0,
    },
    vasShift: {},
    loading: false,
    error: null,
};

export default function vasShiftsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VAS_SHIFTS_REQUEST:
        case GET_VAS_SHIFT_REQUEST:
        case DELETE_VAS_SHIFTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case GET_VAS_SHIFTS_SUCCESS:
            return {
                ...state,
                vasShiftsResponse: action.payload,
                loading: false,
                error: null,
            };

        case GET_VAS_SHIFT_SUCCESS:
            return {
                ...state,
                vasShift: action.payload,
                loading: false,
                error: null,
            };

        case DELETE_VAS_SHIFTS_SUCCESS: {
            const deletedIds = action.payload;
            const updatedData = state.vasShiftsResponse.data.filter(
                (shift) => !deletedIds.includes(shift.id)
            );
            return {
                ...state,
                vasShiftsResponse: {
                    ...state.vasShiftsResponse,
                    data: updatedData,
                    total: state.vasShiftsResponse.total - deletedIds.length,
                },
                loading: false,
                error: null,
            };
        }

        case GET_VAS_SHIFTS_FAILURE:
        case GET_VAS_SHIFT_FAILURE:
        case DELETE_VAS_SHIFTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}
