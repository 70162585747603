import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const Routing = ({ permission }) => {
  const routeComponents = permission.map(({ path, component }, key) => (
    <Route path={path} element={component} key={key} />
  ));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routeComponents}
        <Route
          path="*"
          element={<div>No Match: {window.location.pathname}</div>}
        />
      </Routes>
    </Suspense>
  );
};

export default Routing;
