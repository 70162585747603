const liveStatsMonitor = {
  en: {
    header: 'Live Statistics Monitor',
    avgPerformanceTitle: 'Group Live Average Performance:',
    na: 'N/A',
    dayAverage: 'Day Average',
    chartTitle: 'Previous Days Average',
    chartSubtitle: 'Previous Days Data',
    noPreviousData: 'No previous days data available yet.',
    topPerformances: 'Top Live Performances',
    topLabel: 'Top {{index}}:',
    noLiveData: 'No live data available yet.',
    waitingConnection: 'Waiting for connection...',
    noProductTypes: 'No product types available',
    totalBoxes: 'Total Completed Boxes',
    totalHours: 'Total Hours',
  },
  fi: {
    header: 'Live Tilastojen Seuranta',
    avgPerformanceTitle: 'Ryhmän Live Keskiteho:',
    na: 'N/A',
    dayAverage: 'Päivän keskiarvo',
    chartTitle: 'Edellisten Päivien Keskiarvo',
    chartSubtitle: 'Edellisten Päivien Data',
    noPreviousData: 'Edellisiä päivädataa ei ole vielä saatavilla.',
    topPerformances: 'Parhaat Live Suoritukset',
    topLabel: 'Paras {{index}}:',
    noLiveData: 'Live dataa ei ole vielä saatavilla.',
    waitingConnection: 'Odotetaan yhteyttä...',
    noProductTypes: 'Tuotetyyppejä ei ole saatavilla',
    totalBoxes: 'Valmiita Laatikoita Yhteensä',
    totalHours: 'Tunteja Yhteensä',
  },
  vi: {
    header: 'Theo dõi thống kê trực tiếp',
    avgPerformanceTitle: 'Trung bình hiệu suất nhóm:',
    na: 'N/A',
    dayAverage: 'Trung bình ngày',
    chartTitle: 'Trung bình những ngày trước',
    chartSubtitle: 'Dữ liệu những ngày trước',
    noPreviousData: 'Không có dữ liệu của những ngày trước.',
    topPerformances: 'Hiệu suất tốt nhất',
    topLabel: 'Tốt nhất {{index}}:',
    noLiveData: 'Không có dữ liệu trực tiếp.',
    waitingConnection: 'Đang chờ kết nối...',
    noProductTypes: 'Không có loại sản phẩm',
    totalBoxes: 'Tổng Số Hộp Hoàn Thành',
    totalHours: 'Tổng Số Giờ',
  },
};

export default liveStatsMonitor;
