const navigation = {
  en: {
    dashboard: 'Dashboard',
    homepage: 'Home',
    tools: 'Tools',
    setting: 'Setting',
    instructions: 'Instructions',
    stamp: 'Time card',
    reports: 'Reports',
    rules: 'In House Regulations',
    reportAndStatistics: 'Report and Statistics',
    workersPermission: 'Workers Permission',
    liveStatistics: 'Live Statistics',
    LiveVASStats: 'Live VAS Stats',
    printReport: 'Print Report',
    listOfEmployees: 'List of Employees',
    marketShare: 'Market Share',
    createShifts: 'Create Shifts',
    'inventory-list': 'Inventory List',
    'vas-products-list': 'VAS Products List',
    'vas-shifts': 'VAS Shifts',
    sickleave: 'Sick Leave',
    'sickleave-list': 'Sick Leave List',
    safety: 'Safety',
    rackSafety: 'Rack Safety',
    machineSafety: 'Machine Safety',
    'live-stats-monitor': 'Live Statistics Monitor',
  },
  fi: {
    dashboard: 'Etusivu',
    homepage: 'Etusivu',
    tools: 'Työkalut',
    setting: 'Asetukset',
    instructions: 'Ohjeet',
    stamp: 'Kellokortti',
    reports: 'Raportit',
    rules: 'Työsäännöt',
    reportAndStatistics: 'Raportit ja tilastot',
    workersPermission: 'Työntekijöiden oikeudet',
    liveStatistics: 'Live Työ Status',
    LiveVASStats: 'Live VAS Stats',
    printReport: 'Tulosta raportti',
    listOfEmployees: 'Työntekijälista',
    marketShare: 'Jakaumat',
    createShifts: 'Luo vuorot',
    'inventory-list': 'Varastolista',
    'vas-products-list': 'VAS tuotteet',
    'vas-shifts': 'VAS vuorot',
    sickleave: 'Sairasloma',
    'sickleave-list': 'Sairasloma Raportti',
    safety: 'Turvallisuus',
    rackSafety: 'Hyllyturvallisuus',
    machineSafety: 'Laiteturvallisuus',
    'live-stats-monitor': 'Live Tilastot Monitori',
  },
  vi: {
    dashboard: 'Trang chủ',
    homepage: 'Trang chủ',
    tools: 'Công cụ',
    setting: 'Cài đặt',
    instructions: 'Hướng dẫn',
    stamp: 'Thẻ giờ',
    reports: 'Báo cáo',
    rules: 'Quy tắc làm việc',
    reportAndStatistics: 'Báo cáo và thống kê',
    workersPermission: 'Giới hạn của nhân viên',
    liveStatistics: 'Thống kê trực tiếp',
    LiveVASStats: 'Thống kê VAS trực tiếp',
    printReport: 'In báo cáo',
    listOfEmployees: 'Danh sách nhân viên',
    marketShare: 'Thị phần',
    createShifts: 'Tạo ca làm việc',
    'inventory-list': 'Danh sách tồn kho',
    'vas-products-list': 'Danh sách sản phẩm VAS',
    'vas-shifts': 'Ca làm việc VAS',
    sickleave: 'Nghỉ bệnh',
    'sickleave-list': 'Danh sách nghỉ bệnh',
    safety: 'Safety',
    rackSafety: 'Rack Safety',
    machineSafety: 'Machine Safety',
    'live-stats-monitor': 'Màn hình Thống kê Trực tiếp',
  },
};

export default navigation;
