import { useState, useEffect, useCallback } from 'react';
import { axiosMain } from '../api/axiosConfig';

/**
 * useReportFormData Hook
 *
 * Provides data specifically needed for report search forms across different report features
 * like billing-report, vas-report, etc. This hook handles fetching and managing common
 * entities needed for filtering reports.
 *
 * @returns {Object} Data and utility functions for report search forms
 */
export const useReportFormData = () => {
  const [loading, setLoading] = useState(false);
  const [warehousesData, setWarehousesData] = useState([]);
  const [principalsData, setPrincipalsData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  // Fetch warehouses
  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await axiosMain.get('/warehouse/');
        const filteredData = response.data.filter((data) => data.is_active === true);
        setWarehousesData(filteredData);
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }
    };

    fetchWarehouses();
  }, []);

  // Fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosMain.get('/accounts/list/');
        setUsersData(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Fetch principals based on warehouse
  const fetchPrincipals = useCallback(async (warehouseId) => {
    if (!warehouseId) return;
    setLoading(true);
    try {
      const response = await axiosMain.get(`/warehouse/${warehouseId}/principal/`);
      const filteredData = response.data.filter((data) => data.is_active === true);
      setPrincipalsData(filteredData);
    } catch (error) {
      console.error('Error fetching principals:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch tasks based on warehouse and principal
  const fetchTasks = useCallback(async (warehouseId, principalId) => {
    if (!warehouseId || !principalId) return;
    setLoading(true);
    try {
      const response = await axiosMain.get(
        `/warehouse/${warehouseId}/principal/${principalId}/task/`,
      );
      setTasksData(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    warehousesData,
    principalsData,
    tasksData,
    usersData,
    fetchPrincipals,
    fetchTasks,
  };
};

export default useReportFormData;
