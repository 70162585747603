import { memo, useState, useCallback } from 'react';
import { Container, Tabs, Tab, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import Page from '@/components/Page';
import useSettings from '@/hooks/useSettings';
import VasReportFilter from './components/VasReportFilter';
import RenderVasReport from './components/RenderVasReport';
import RenderProductTypeReport from './components/RenderProductTypeReport';

// Constants moved to a separate object for better organization
const REPORT_CONFIG = {
  BREADCRUMBS: [
    { name: 'Dashboard', href: '/' },
    { name: 'Tools', href: '/tools' },
    { name: 'Reports', href: '/tools/reports' },
    { name: 'VAS Report' },
  ],
  TYPES: {
    PRODUCT: 0,
    PRODUCT_TYPE: 1,
  },
};

const VasReport = () => {
  const { themeStretch } = useSettings();
  const [currentTab, setCurrentTab] = useState(REPORT_CONFIG.TYPES.PRODUCT);
  const { t } = useTranslation();

  const handleTabChange = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const getReportTypeFromTab = useCallback((tabIndex) => {
    return tabIndex === REPORT_CONFIG.TYPES.PRODUCT ? 'product' : 'product-type';
  }, []);

  return (
    <>
      <Helmet>
        <title>VAS Report | Dashboard</title>
      </Helmet>

      <Page>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs heading="VAS Report" links={REPORT_CONFIG.BREADCRUMBS} />

          <Box sx={{ width: '100%', mb: 3 }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab label={t('report.productLevel')} />
              <Tab label={t('report.productTypeLevel')} />
            </Tabs>
          </Box>

          <VasReportFilter reportType={getReportTypeFromTab(currentTab)} />

          {currentTab === REPORT_CONFIG.TYPES.PRODUCT ? (
            <RenderVasReport />
          ) : (
            <RenderProductTypeReport />
          )}
        </Container>
      </Page>
    </>
  );
};

export default memo(VasReport);
