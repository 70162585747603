import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

/**
 * ProductImage component to display the product image or a placeholder
 */
const ProductImage = ({ product }) => {
  return (
    <>
      {product.image ? (
        <Box
          component="img"
          src={product.image}
          alt={product.productDescription}
          sx={{
            width: 100,
            height: 100,
            objectFit: 'cover',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'grey.300',
          }}
        />
      ) : (
        <Box
          sx={{
            width: 100,
            height: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'grey.100',
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            No Image
          </Typography>
        </Box>
      )}
    </>
  );
};

ProductImage.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductImage;