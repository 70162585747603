import { addDoc, doc, getDocs, query, where, updateDoc, orderBy } from 'firebase/firestore';
import { formatDocs } from '../utils/formatFirestore';

import { notificationsCollectionRef } from '../db/firestore';

export const getNotifications = async (user_id) => {
  try {
    const q = query(
      notificationsCollectionRef,
      where('user_id', '==', user_id),
      orderBy('createdAt', 'desc'),
    );
    const response = await getDocs(q);
    return formatDocs(response);
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
};

export const readedNotification = async (id) => {
  try {
    const notificationRef = doc(notificationsCollectionRef, id);
    await updateDoc(notificationRef, {
      isRead: true,
    });
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

export const markAllNotificationReaded = async (user_id) => {
  try {
    const q = query(
      notificationsCollectionRef,
      where('user_id', '==', user_id),
      where('isRead', '==', false),
    );
    const response = await getDocs(q);
    const notifications = formatDocs(response);
    await Promise.all(
      notifications.map(async (notification) => {
        const notificationRef = doc(notificationsCollectionRef, notification.id);
        await updateDoc(notificationRef, {
          isRead: true,
        });
      }),
    );
  } catch (error) {
    console.error('Error marking all notifications as read:', error);
    throw error;
  }
};

export const createNotification = async (data) => {
  try {
    const response = await addDoc(notificationsCollectionRef, {
      ...data,
      createdAt: new Date(), // Ensure createdAt is always set
    });
    return response;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};
