import { useState, useCallback } from 'react';

/**
 * Custom hook to manage a countdown delay
 * @param {number} initialCount - Initial countdown value in seconds
 * @returns {Object} - Contains isDelay, delayCountdown, and startDelay function
 */
const useDelayCountdown = (initialCount = 5) => {
  const [isDelay, setIsDelay] = useState(false);
  const [delayCountdown, setDelayCountdown] = useState(0);

  const startDelay = useCallback(() => {
    setIsDelay(true);
    setDelayCountdown(initialCount);

    const intervalId = setInterval(() => {
      setDelayCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(intervalId);
          setIsDelay(false);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [initialCount]);

  return { isDelay, delayCountdown, startDelay };
};

export default useDelayCountdown;