import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { FIREBASE_API } from './configs/config';

// Firebase configuration for notification and sickleave only
const firebaseConfig = {
  apiKey: FIREBASE_API.apiKey,
  authDomain: FIREBASE_API.authDomain,
  projectId: FIREBASE_API.projectId,
  storageBucket: FIREBASE_API.storageBucket,
  messagingSenderId: FIREBASE_API.messagingSenderId,
  appId: FIREBASE_API.appId,
};

// Initialize Firebase app only for notification and sickleave functionality
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// Export db for notification and sickleave only
export const db = getFirestore(firebaseApp);

// Keep notification functionality
export const fetchToken = ({ setTokenFound, setToken }) => {
  return getToken(messaging, {
    vapidKey:
      'BCndqkOIWvPiTMi8YyPKrENNqaZo2-MTXpMyCiNrO5Zr9m6jdXXwjyu2ulyhEXM3p0Et6hXWOD24xK5IDpYxie0',
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        setToken(currentToken);
      } else {
        console.error('No registration token available. Request permission to generate one.');
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });
};

// Keep notification message listener
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
