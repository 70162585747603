const workTypes = {
  en: {
    'Pennala 1 - Unloading - Purku': 'Unloading',
    'Pennala 1 - Receiving - Vastaanotto': 'Receiving',
    'Pennala 1 - Putaway - Hyllytys': 'Putaway',
    'Pennala 1 - Picking - Keräily': 'Picking',
    'Pennala 1 - Training - Koulutus': 'Training',
    'Pennala 1 - Replenishment - Täydennys': 'Replenishment',
    'Pennala 1 - Meeting - Palaveri': 'Meeting',
    'Pennala 1 - Production planning - Tuotannon suunnittelu': 'Production planning',
    'Pennala 1 - Temporary no work - Ei töitä väliaikaisesti': 'Temporary no work',
    'Pennala 1 - Labeling - Tarroittaminen': 'Labeling',
    'Pennala 1 - Break - Tauko': 'Break',
    'Pennala 1 - Cleaning - Siivous': 'Cleaning',
    'Pennala 1 - Others - Varasto tukitoiminto projektit': 'Warehouse support function',
    'Pennala 1 - Kuittaus & Pakkaus': 'Receipting & Packing',
    'Pennala 1 - Waiting Replenishment - Täydennyksen odottaminen': 'Waiting Replenishment',
    'Pennala 1 - Loading': 'Loading',
    'Pennala 1 - Others': 'Others',
    '------------------': '------------------',
    'Pennala 2 - Unloading - Purku': 'Unloading',
    'Pennala 2 - Receiving - Vastaanotto': 'Receiving',
    'Pennala 2 - Putaway - Hyllytys': 'Putaway',
    'Pennala 2 - Picking - Keräily': 'Picking',
    'Pennala 2 - Dispatching - Lähetys': 'Dispatching',
    'Pennala 2 - Quality control - Laatu': 'Quality control',
    'Pennala 2 - Training - Koulutus': 'Training',
    'Pennala 2 - Packing - Pakkaaminen': 'Packing',
    'Pennala 2 - Replenishment - Täydennys': 'Replenishment',
    'Pennala 2 - Meeting - Palaveri': 'Meeting',
    'Pennala 2 - Production planning - Tuotannon suunnittelu': 'Production planning',
    'Pennala 2 - Temporary no work - Ei töitä väliaikaisesti': 'Temporary no work',
    'Pennala 2 - Labeling - Tarroittaminen': 'Labeling',
    'Pennala 2 - Break - Tauko': 'Break',
    'Pennala 2 - Change Battery - Akun vaihto': 'Change Battery',
    'Pennala 2 - Cleaning - Siivous': 'Cleaning',
    'Pennala 2 - Others - Varasto tukitoiminto projektit': 'Warehouse support function',
    'Pennala 2 - RaKe kollikeräily': 'RaKe bulk collecting',
    'Pennala 2 - RaKe Erikoistavarat': 'RaKe Erikoistavarat',
    'Pennala 2 - Terminaali - Terminal': 'Terminal',
  },
  fi: {
    'Pennala 1 - Unloading - Purku': 'Purku',
    'Pennala 1 - Receiving - Vastaanotto': 'Vastaanotto',
    'Pennala 1 - Putaway - Hyllytys': 'Hyllytys',
    'Pennala 1 - Picking - Keräily': 'Keräily',
    'Pennala 1 - Training - Koulutus': 'Koulutus',
    'Pennala 1 - Replenishment - Täydennys': 'Täydennys',
    'Pennala 1 - Meeting - Palaveri': 'Palaveri',
    'Pennala 1 - Production planning - Tuotannon suunnittelu': 'Tuotannon suunnittelu',
    'Pennala 1 - Temporary no work - Ei töitä väliaikaisesti': 'Ei töitä väliaikaisesti',
    'Pennala 1 - Labeling - Tarroittaminen': 'Tarroittaminen',
    'Pennala 1 - Break - Tauko': 'Tauko',
    'Pennala 1 - Cleaning - Siivous': 'Siivous',
    'Pennala 1 - Others - Varasto tukitoiminto projektit': 'Varasto tukitoiminto projektit',
    'Pennala 1 - Kuittaus & Pakkaus': 'Kuittaus & Pakkaus',
    'Pennala 1 - Waiting Replenishment - Täydennyksen odottaminen': 'Täydennyksen odottaminen',
    'Pennala 1 - Loading': 'Loading',
    'Pennala 1 - Others': 'Muu',
    '------------------': '------------------',
    'Pennala 2 - Unloading - Purku': 'Purku',
    'Pennala 2 - Receiving - Vastaanotto': 'Vastaanotto',
    'Pennala 2 - Putaway - Hyllytys': 'Hyllytys',
    'Pennala 2 - Picking - Keräily': 'Keräily',
    'Pennala 2 - Dispatching - Lähetys': 'Lähetys',
    'Pennala 2 - Quality control - Laatu': 'Laatu',
    'Pennala 2 - Training - Koulutus': 'Koulutus',
    'Pennala 2 - Packing - Pakkaaminen': 'Pakkaaminen',
    'Pennala 2 - Replenishment - Täydennys': 'Täydennys',
    'Pennala 2 - Meeting - Palaveri': 'Palaveri',
    'Pennala 2 - Production planning - Tuotannon suunnittelu': 'Tuotannon suunnittelu',
    'Pennala 2 - Temporary no work - Ei töitä väliaikaisesti': 'Ei töitä väliaikaisesti',
    'Pennala 2 - Labeling - Tarroittaminen': 'Tarroittaminen',
    'Pennala 2 - Break - Tauko': 'Tauko',
    'Pennala 2 - Change Battery - Akun vaihto': 'Akun vaihto',
    'Pennala 2 - Cleaning - Siivous': 'Siivous',
    'Pennala 2 - Others - Varasto tukitoiminto projektit': 'Varasto tukitoiminto projektit',
    'Pennala 2 - RaKe kollikeräily': 'RaKe kollikeräily',
    'Pennala 2 - RaKe Erikoistavarat': 'RaKe Erikoistavarat',
    'Pennala 2 - Terminaali - Terminal': 'Terminaali',
  },
  vi: {
    'Pennala 1 - Unloading - Purku': 'Unloading - Purku',
    'Pennala 1 - Receiving - Vastaanotto': 'Receiving - Vastaanotto',
    'Pennala 1 - Putaway - Hyllytys': 'Putaway - Hyllytys',
    'Pennala 1 - Picking - Keräily': 'Picking - Keräily',
    'Pennala 1 - Training - Koulutus': 'Training - Koulutus',
    'Pennala 1 - Replenishment - Täydennys': 'Replenishment - Täydennys',
    'Pennala 1 - Meeting - Palaveri': 'Meeting - Palaveri',
    'Pennala 1 - Production planning - Tuotannon suunnittelu': 'Production planning - Tuotannon suunnittelu',
    'Pennala 1 - Temporary no work - Ei töitä väliaikaisesti': 'Temporary no work - Ei töitä väliaikaisesti',
    'Pennala 1 - Labeling - Tarroittaminen': 'Labeling - Tarroittaminen',
    'Pennala 1 - Break - Tauko': 'Break - Tauko',
    'Pennala 1 - Cleaning - Siivous': 'Cleaning - Siivous',
    'Pennala 1 - Others - Varasto tukitoiminto projektit': 'Others - Varasto tukitoiminto projektit',
    'Pennala 1 - Kuittaus & Pakkaus': 'Kuittaus & Pakkaus',
    'Pennala 1 - Waiting Replenishment - Täydennyksen odottaminen': 'Waiting Replenishment - Täydennyksen odottaminen',
    'Pennala 1 - Loading': 'Loading',
    'Pennala 1 - Others': 'Others',
    '------------------': '------------------',
    'Pennala 2 - Unloading - Purku': 'Unloading - Purku',
    'Pennala 2 - Receiving - Vastaanotto': 'Receiving - Vastaanotto',
    'Pennala 2 - Putaway - Hyllytys': 'Putaway - Hyllytys',
    'Pennala 2 - Picking - Keräily': 'Picking - Keräily',
    'Pennala 2 - Dispatching - Lähetys': 'Dispatching - Lähetys',
    'Pennala 2 - Quality control - Laatu': 'Quality control - Laatu',
    'Pennala 2 - Training - Koulutus': 'Training - Koulutus',
    'Pennala 2 - Packing - Pakkaaminen': 'Packing - Pakkaaminen',
    'Pennala 2 - Replenishment - Täydennys': 'Replenishment - Täydennys',
    'Pennala 2 - Meeting - Palaveri': 'Meeting - Palaveri',
    'Pennala 2 - Production planning - Tuotannon suunnittelu': 'Production planning - Tuotannon suunnittelu',
    'Pennala 2 - Temporary no work - Ei töitä väliaikaisesti': 'Temporary no work - Ei töitä väliaikaisesti',
    'Pennala 2 - Labeling - Tarroittaminen': 'Labeling - Tarroittaminen',
    'Pennala 2 - Break - Tauko': 'Break - Tauko',
    'Pennala 2 - Change Battery - Akun vaihto': 'Change Battery - Akun vaihto',
    'Pennala 2 - Cleaning - Siivous': 'Cleaning - Siivous',
    'Pennala 2 - Others - Varasto tukitoiminto projektit': 'Others - Varasto tukitoiminto projektit',
    'Pennala 2 - RaKe kollikeräily': 'RaKe kollikeräily',
    'Pennala 2 - RaKe Erikoistavarat': 'RaKe Erikoistavarat',
    'Pennala 2 - Terminaali - Terminal': 'Terminaali - Terminal',
  }
};

export default workTypes;