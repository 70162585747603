const common = {
  en: {
    selectWarehouse: 'Select Warehouse',
    selectPrincipal: 'Select Principal',
    selectWorktype: 'Select Worktype',
    selectEmployee: 'Select Employee',
    selectProductType: 'Select Product Type',
    update: 'Update',
    live: 'Live',
    done: 'Done',
    close: 'Close',
    or: 'Or',
    send: 'Send',
    cancel: 'Cancel',
    error: 'Error',
    next: 'Next',
    finish: 'Finish',
    all: 'All',
    show: 'Show',
    performanceUnit: 'kpl/h',
  },
  fi: {
    selectWarehouse: 'Valitse työpaikka, kustannuspaikka',
    selectPrincipal: 'Valitse päämies',
    selectWorktype: 'Valitse työlaji',
    selectEmployee: 'Valitse työntekijä',
    selectProductType: 'Valitse tuotetyyppi',
    update: 'Päivitä',
    live: 'Live',
    done: 'Valmis',
    close: 'Sulje',
    or: 'Tai',
    send: 'Lähetä',
    cancel: 'Peruuta',
    next: 'Seuraava',
    finish: 'Valmis',
    all: 'Kaikki',
    show: 'Näytä',
  },
  vi: {
    selectWarehouse: 'Chọn kho',
    selectPrincipal: 'Chọn khách hàng',
    selectWorktype: 'Chọn loại công việc',
    selectEmployee: 'Chọn nhân viên',
    selectProductType: 'Chọn loại sản phẩm',
    update: 'Cập nhật',
    live: 'Live',
    done: 'Hoàn thành',
    close: 'Đóng',
    or: 'Hoặc',
    send: 'Gửi',
    cancel: 'Hủy',
    next: 'Tiếp theo',
    finish: 'Hoàn thành',
    all: 'Tất cả',
    show: 'Hiện',
  }
};

export default common;