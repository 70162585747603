const sickleave = {
  en: {
    header: 'Sick leave',
    title: 'Please take a photo or upload your sick leave certificate',
    dropzoneDescription: "Drag 'n' drop certificate here, or click to select files",
    acceptedFiles: 'Accepted files: .jpg, .jpeg and .png',
    errorAccept: 'Only .jpg, .jpeg and .png format allowed!',
    uploadedSuccessfully: 'You sick leave certificate sent successfully',
    uploadedError: 'Error while uploading sick leave certificate, please retake photo of certificate and try again.',
    uploadStep1: 'Announce To Supervisor',
    uploadStep2: 'Upload Certificate',
    uploadStep3: 'Send Certificate',
    uploadStep4: 'Waiting For Approval',
    step1Description: 'Report sick leave to your supervisor',
    step4Description1: 'We will notify you when your sick leave has been checked.',
    'step4Description2-1': 'You can view your notifications in the bell icon',
    'step4Description2-2': 'in top right corner.',
  },
  fi: {
    header: 'Sairasloma',
    title: 'Ole Hyvä ota valokuva tai lataa sairauslomatodistus',
    dropzoneDescription: 'Vedä ja pudota varmenne tähän tai valitse tiedostot napsauttamalla',
    acceptedFiles: 'Hyväksytyt tiedostot: .jpg, .jpeg ja .png',
    errorAccept: 'Vain .jpg, .jpeg ja .png muoto sallitaan!',
    uploadedSuccessfully: 'Sairauslomatodistus lähetetty onnistuneesti',
    uploadedError: 'Virhe ladattaessa sairauslomatodistusta. Ota valokuva todistuksesta uudelleen ja yritä uudelleen.',
    uploadStep1: 'Ilmoita Esimiehelle',
    uploadStep2: 'Lataa Todistus',
    uploadStep3: 'Lähetä Todistus',
    uploadStep4: 'Odota Vahvistusta',
    step1Description: 'Ilmoita sairaslomasta esimiehelle',
    step4Description1: 'Ilmoitamme sinulle kun sairauslomasi on tarkistettu.',
    'step4Description2-1': 'Näet ilmoituksesi kellokuvakkeesta',
    'step4Description2-2': 'oikeassa yläkulmassa.',
  },
  vi: {
    header: 'Nghỉ bệnh',
    title: 'Vui lòng chụp ảnh hoặc đăng giấy chứng nhận của bác sĩ',
    dropzoneDescription: 'Kéo và thả chứng chỉ tại đây hoặc bấm vào đây để chọn tệp',
    acceptedFiles: 'Các tệp được chấp nhận: .jpg, .jpeg và .png',
    errorAccept: 'Chỉ cho phép các định dạng .jpg, .jpeg và .png!',
    uploadedSuccessfully: 'Giấy chứng nhận nghỉ bệnh của bạn đã được gửi thành công',
    uploadedError: 'Đã xảy ra lỗi khi tải lên giấy chứng nhận nghỉ bệnh, vui lòng chụp lại ảnh của giấy chứng nhận và thử lại.',
    uploadStep1: 'Thông báo cho người giám sát',
    uploadStep2: 'Tải lên giấy nghỉ bệnh',
    uploadStep3: 'Gửi giấy nghỉ bệnh',
    uploadStep4: 'Chờ phê duyệt',
    step1Description: 'Báo cáo nghỉ bệnh cho cấp trên của bạn',
    step4Description1: 'Chúng tôi sẽ thông báo cho bạn khi thời gian nghỉ bệnh của bạn được kiểm tra.',
    'step4Description2-1': 'Bạn sẽ thấy thông báo của mình trên biểu tượng chuông',
    'step4Description2-2': 'ở góc trên bên phải.',
  }
};

export default sickleave;