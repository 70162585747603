import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import Cookies from 'universal-cookie';
import { userIsIn } from './actions/userActions';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

function App() {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get('rsid');

  useEffect(() => {
    if (token) {
      dispatch(userIsIn());
    }
  }, [dispatch, token]);

  const user = useSelector((state) => state.user);

  return <HelmetProvider>{user && user.email ? <Dashboard /> : <Login />}</HelmetProvider>;
}

export default App;
