import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const { REACT_APP_NEW_BACKEND_URL } = process.env;
const baseUrl = `${REACT_APP_NEW_BACKEND_URL || 'http://localhost:3001'}/vas/product`;

const getAuthConfig = () => {
  const token = `Token ${cookies.get('rsid')}`;
  return {
    headers: { Authorization: token },
  };
};

/**
 * Fetch completed VAS work logs based on filters.
 * @param {Object} filters - The filters for the query.
 * @param {Date} filters.startDay - The start date for filtering logs.
 * @param {Date} filters.endDay - The end date for filtering logs.
 * @param {Array} filters.workerIds - Array of worker IDs to filter logs.
 * @param {Array} filters.warehouseIds - Array of warehouse IDs to filter logs.
 * @returns {Array} Filtered logs.
 */

/**
 * Fetch VAS products from the backend.
 * @returns {Array} VAS products data.
 */
export const getVasProductsApi = async () => {
  const config = getAuthConfig();
  try {
    const response = await axios.get(baseUrl, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching VAS products:', error);
    throw error;
  }
};

export const getVasProductsByWorkTypeIdApi = async (workTypeId) => {
  const config = getAuthConfig();
  const response = await axios.get(`${baseUrl}/work-type/${workTypeId}`, config);
  return response.data;
};

export const postVasProductApi = async (data) => {
  const config = getAuthConfig();
  try {
    const response = await axios.post(baseUrl, data, config);
    return response.data;
  } catch (error) {
    console.error('Error posting VAS product:', error);
    throw error;
  }
};

export const getproductTypeAndUOMApi = async () => {
  const config = getAuthConfig();
  try {
    const response = await axios.get(`${baseUrl}/product-types-and-uoms`, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching product type and UOM:', error);
    throw error;
  }
};

export const getProductTypesApi = async (workTypeId) => {
  const config = getAuthConfig();
  let url = `${baseUrl}/product-types`;
  if (workTypeId) {
    url += `?workTypeId=${workTypeId}`;
  }
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching product types:', error);
    throw error;
  }
};

export const deleteVasProductApi = async (id) => {
  const config = getAuthConfig();
  try {
    const url = `${baseUrl}/product/${id}`;
    const response = await axios.delete(url, config);
    return response.data;
  } catch (error) {
    console.error('Error deleting VAS product:', error);
    throw error;
  }
};

/**
 * Fetch a single VAS product by ID.
 * @param {number|string} id - The ID of the VAS product.
 * @returns {Object} The VAS product data.
 */
export const getVasProductByIdApi = async (id) => {
  const config = getAuthConfig();
  try {
    const response = await axios.get(`${baseUrl}/product/${id}`, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching VAS product by ID:', error);
    throw error;
  }
};

/**
 * Update an existing VAS product.
 * @param {number|string} id - The ID of the VAS product to update.
 * @param {Object} data - The updated product data.
 * @returns {Object} The updated VAS product data.
 */
export const updateVasProductApi = async (id, data) => {
  const config = getAuthConfig();
  try {
    const response = await axios.patch(`${baseUrl}/product/${id}`, data, config);
    return response.data;
  } catch (error) {
    console.error('Error updating VAS product:', error);
    throw error;
  }
};
