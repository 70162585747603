import { doc, getDoc } from 'firebase/firestore';

import { statisticsCollectionRef } from '../db/firestore';

const getCollectionRef = (path) => {
  switch (path) {
    case 'statistics':
      return statisticsCollectionRef;
    default:
      return null;
  }
};

export const formatDoc = (doc) => {
  const data = doc.data();
  for (const property in data) {
    if (data[property]?.seconds) {
      data[property] = data[property].toDate();
    }
  }
  return {
    id: doc.id,
    ...data,
  };
};

export const formatDocs = (response) => {
  const res = response.docs.map((doc) => formatDoc(doc));
  return res;
};

export const formatArray = (array) => {
  let result = [];
  Promise.all(
    array.map(async (s) => {
      const parent = s.parent.path;
      const collectionRef = getCollectionRef(parent);
      const response = await getDoc(doc(collectionRef, s.id));
      result.push(formatDoc(response));
    }),
  ).then(() => {
    return result;
  });
};
