import { axiosMain, axiosNew } from './axiosConfig';

const getToken = async (credentials) => {
  const response = await axiosNew.post('/auth/login', credentials);
  return response.data;
};

const getUserInfo = async (token) => {
  const response = await axiosMain.get('/accounts/user/');
  return response.data;
};

const getUsers = async (token) => {
  const response = await axiosMain.get('/accounts/list/');
  return response.data;
};

const createUser = async (body) => {
  const response = await axiosMain.post('/accounts/registration/', body.user);
  return response;
};

const updateUserPassword = async (token, data) => {
  let response;
  try {
    response = await axiosMain.post('/accounts/password/change/', data);
  } catch (err) {
    response = err.response;
  }
  return response;
};

const accountsApi = {
  getToken,
  getUserInfo,
  createUser,
  getUsers,
  updateUserPassword,
};

export default accountsApi;
