import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Card,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { uploadFile } from '../../../actions/sickleaveActions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/iconify/Iconify';
import { useSnackbar } from 'notistack';

const steps = [
  'sickleave.uploadStep1',
  'sickleave.uploadStep2',
  'sickleave.uploadStep3',
  'sickleave.uploadStep4',
];

const Sickleave = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState(null);

  const [activeStep, setActiveStep] = useState(0);

  const user = useSelector(state => state.user);

  const [uploading, setUploading] = useState(false);

  const onFileInputChange = event => {
    const { files } = event.target;
    const file = files[0];
    if (file?.type === 'image/jpeg' || file?.type === 'image/png') {
      handleNext();
      setError(null);
    } else {
      setError({
        message: t('sickleave.errorAccept'),
        type: 'file format',
        file: file?.name,
      });
    }

    setLoading(true);

    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = event => {
        setBase64(event.target.result);
        setLoading(false);
      };

      fileReader.readAsDataURL(file);
    }

    setFile(files[0]);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setUploading(true);
    const responsesuccess = await uploadFile(file, base64, user);

    setUploading(false);
    if (responsesuccess.error) {
      setError({
        message: responsesuccess.error + '. Please, retake picture',
        file: responsesuccess.file,
      });
      setActiveStep(1);
    } else {
      setFile(null);
      handleNext();
      enqueueSnackbar(t('sickleave.uploadedSuccessfully'), {
        variant: 'success',
      });
    }
  };

  return (
    <Card sx={{ padding: 4 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{t(`${label}`)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && (
        <Box sx={{ paddingTop: 5 }}>
          <ul>
            <li>
              <Typography>{t('sickleave.step1Description')}</Typography>
            </li>
          </ul>
          <Box className="d-flex justify-content-end">
            <Button onClick={handleNext}>
              {t('common.next')} <Iconify icon="eva:arrow-ios-forward-fill" />
            </Button>
          </Box>
        </Box>
      )}
      {(activeStep === 1 || activeStep === 2) && (
        <>
          <section
            className={
              `my-4 dropzone` +
              (file
                ? error
                  ? ' dropzone-declined'
                  : ' dropzone-accepted'
                : '')
            }
          >
            {base64 && <p>{base64}</p>}
            <div className="justify-content-center ">
              <input
                type="file"
                className="mb-3"
                onChange={onFileInputChange}
              />

              {loading && <CircularProgress />}
              {!file ? (
                <>
                  <p>{t('sickleave.acceptedFiles')}</p>
                </>
              ) : (
                error && (
                  <>
                    <p>
                      {error?.type === 'file format'
                        ? t('sickleave.errorAccept')
                        : t('sickleave.uploadedError')}
                    </p>
                  </>
                )
              )}
            </div>
          </section>
        </>
      )}
      <Box className="d-flex justify-content-end">
        <Stack direction="row" gap={2}>
          {activeStep === 2 && (
            <LoadingButton
              onClick={handleSubmit}
              loading={uploading}
              disabled={Boolean(error) || !file || loading}
              variant="contained"
              size="large"
            >
              {t('common.send')}
            </LoadingButton>
          )}
          {activeStep === 1 && (
            <Button
              onClick={handleNext}
              disabled={Boolean(error) || !file || loading}
            >
              {t('common.next')} <Iconify icon="eva:arrow-ios-forward-fill" />
            </Button>
          )}
        </Stack>
      </Box>

      {activeStep === 3 && (
        <Box sx={{ paddingTop: 5 }}>
          <Typography>{t('sickleave.step4Description1')}</Typography>
          <Typography>
            {t('sickleave.step4Description2-1')}{' '}
            <Iconify icon="eva:bell-fill" />{' '}
            {t('sickleave.step4Description2-2')}
          </Typography>
          <Box className="d-flex justify-content-end">
            <Button
              onClick={() => {
                navigate('/');
              }}
            >
              {activeStep === steps.length - 1 ? 'Finish' : t('common.next')}
            </Button>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default Sickleave;
