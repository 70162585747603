import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { formatDuration } from '../utils/timerUtils';

/**
 * TimerDisplay component shows elapsed time
 */
const TimerDisplay = ({ currentTime, startTime }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
      {/* Timer Display */}
      <Box
        sx={{
          p: 2,
          bgcolor: 'grey.100',
          borderRadius: 1,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="text.secondary">
          {t('selectedProduct.timeElapsed')}
        </Typography>
        <Typography variant="h3" color="text.primary">
          {formatDuration(currentTime - startTime)}
        </Typography>
      </Box>
    </Box>
  );
};

TimerDisplay.propTypes = {
  currentTime: PropTypes.number.isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired,
};

export default TimerDisplay;