import { collection } from 'firebase/firestore';
import { db } from '../firebase';

// Keep only the collections needed for notification and sickleave
export const absencesCollectionRef = collection(db, 'absences');
export const notificationsCollectionRef = collection(db, 'notification');
export const statisticsCollectionRef = collection(db, 'statistics');
export const sickleaveReasonsCollectionRef = collection(
  db,
  'statistics',
  'sickleaveReasons',
  'reasons',
);
