// This file has been modified to remove Firebase dependency
// The functionality is now deprecated except for notification and sickleave

export const getTeam = async (id) => {
  // Return empty array as this functionality has been removed
  return [];
};

export const postOffDay = async (data) => {
  // This is a stub function as the functionality has been removed
  console.log('Off day posting has been disabled', data);
  return {};
};
