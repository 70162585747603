import React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { formatSeconds } from '@/utils/DateTime';
import { getWarehouseNameByID } from '@/utils/getName';

const RenderWorkTypeSummary = ({ warehouseWorkTypeTotals, warehouses }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h3" className="text-center" id="summaryHeader">
        {t('report.workTypeSummary')}
      </Typography>
      {Object.entries(warehouseWorkTypeTotals).map(([warehouseId, workTypes], i) => (
        <div key={warehouseId} id={`summaryToPrint-${i}`}>
          <Typography variant="h6">
            {t('report.warehouse')}: {getWarehouseNameByID(warehouseId, warehouses)}
          </Typography>
          <table className="table">
            <thead>
              <tr>
                <th>{t('report.workType')}</th>
                <th className="w-120">{t('report.totalHours')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(workTypes).map(({ work_type_name, totalSeconds }) => (
                <tr key={work_type_name}>
                  <td>{t(work_type_name)}</td>
                  <td className="w-120">{formatSeconds(totalSeconds)} h</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default RenderWorkTypeSummary;
