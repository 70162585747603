import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const EndShiftModal = ({ open, handleClose, handleSubmitModal, unit }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = React.useState('');

  // Prevent scroll wheel from changing number input values
  const handleWheel = e => {
    // Prevent the default scroll behavior when input is focused
    e.target.blur();
  };

  const handleSubmit = () => {
    const val = amount || 0;
    handleSubmitModal(val);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {unit === 'calendar'
          ? t('timer.performanceCalendar')
          : t('timer.performanceCalendarBox')}
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1" sx={{ mb: 3, fontWeight: 'bold' }}>
          {t('timer.performanceCalendarDescription')}
        </Typography>

        <TextField
          fullWidth
          label={unit || 'Calendar'}
          type="number"
          variant="outlined"
          InputProps={{ inputProps: { min: 0 } }}
          value={amount}
          onChange={e => setAmount(e.target.value)}
          onWheel={handleWheel}
          margin="normal"
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3, justifyContent: 'space-between' }}>
        <Button onClick={handleSubmit} variant="contained" type="submit">
          {t('common.send')}
        </Button>
        <Button onClick={handleClose} variant="outlined">
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EndShiftModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmitModal: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

export default EndShiftModal;
