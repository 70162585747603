import Navigation from './Others/Navigation';
import DashboardContent from './Dashboard/';
import Footer from './Others/Footer';

const Dashboard = () => {
  return (
    <>
      <div className="main">
        <Navigation />
        <DashboardContent />
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
