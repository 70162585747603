const notification = {
  en: {
    notifications: 'Notifications',
    'You-have': 'You have',
    'unread-messages': 'unread messages',
    unread: 'unread',
    read: 'read',
    'Mark-all-as-read': 'Mark all as read',
    'You-sick-leave-is-processed': 'Your sick leave is processed:',
    accepted: 'Accepted',
    rejected: 'Rejected',
    'Is-sick': 'Is sick',
    'Is-offday': 'Is on holiday',
  },
  fi: {
    notifications: 'Ilmoitukset',
    'You-have': 'Sinulla on',
    'unread-messages': 'lukematonta viestiä',
    unread: 'lukematon',
    read: 'luettu',
    'Mark-all-as-read': 'Merkitse kaikki luetuksi',
    'You-sick-leave-is-processed': 'Sairauslomasi on käsitelty:',
    accepted: 'Hyväksytty',
    rejected: 'Hylätty',
    'Is-sick': 'On sairaana',
    'Is-offday': 'on vapaalla',
  },
  vi: {
    notifications: 'Thông báo',
    'You-have': 'Bạn có',
    'unread-messages': 'tin nhắn chưa đọc',
    unread: 'Chưa đọc',
    read: 'Đã đọc',
    'Mark-all-as-read': 'Đánh dấu tất cả là đã đọc',
    'You-sick-leave-is-processed': 'Giấy chứng nhận nghỉ bệnh của bạn đã được xử lý:',
    accepted: 'Đã chấp nhận',
    rejected: 'Đã từ chối',
    'Is-sick': 'Đã bệnh',
    'Is-offday': 'Đã nghỉ phép',
  }
};

export default notification;