import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from '@redux-devtools/extension';

import notificationReducer from './reducer/notificationReducer';
import PrincipalReducer from './reducer/PrincipalReducer';
import principalTasksReducer from './reducer/principalTasksReducer';
import reportReducer from './reducer/reportReducer';
import scheduleReducer from './reducer/scheduleReducer';
import shiftReducer from './reducer/shiftReducer';
import userReducer from './reducer/userReducer';
import usersReducer from './reducer/usersReducer';
import vasProductsReducer from './reducer/vasProductsReducer';
import vasShiftsReducer from './reducer/vasShiftsReducer';
import vasWorkLogReducer from './reducer/vasWorkLogReducer';
import warehouseReducer from './reducer/warehouseReducer';
import vasReportReducer from './features/vas-report/redux/vasReportSlice';
import billingReportReducer from './features/billing-report/redux/billingReportSlice';

const appReducer = combineReducers({
  message: notificationReducer,
  user: userReducer,
  users: usersReducer,
  warehouses: warehouseReducer,
  principal: PrincipalReducer,
  tasks: scheduleReducer,
  report: reportReducer,
  shift: shiftReducer,
  principalTasks: principalTasksReducer,
  vasProducts: vasProductsReducer,
  vasWorkLog: vasWorkLogReducer,
  vasShifts: vasShiftsReducer,
  vasReport: vasReportReducer,
  billingReport: billingReportReducer,
});

// Root reducer that handles the logout action for all states
const rootReducer = (state, action) => {
  // When logout action is dispatched, reset all states to their initial values
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
