import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useReportFormData } from '@/hooks';
import ReportSearchForm from '@/components/report/ReportSearchForm';
import { fetchBillingReport } from '../redux/billingReportSlice';
import { sortObject } from '@/components/HelperFunctions/Others';

/**
 * BillingReportSearch Component
 *
 * Provides a search interface for filtering billing reports.
 * Uses the useReportFormData hook for data fetching.
 *
 * @param {Object} props Component props
 * @param {Function} props.setLoading Function to set loading state
 * @returns {JSX.Element} The rendered search form
 */
const BillingReportSearch = ({ setLoading }) => {
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);

  // Use the common report data hook
  const {
    loading,
    warehousesData,
    principalsData,
    tasksData,
    usersData,
    fetchPrincipals,
    fetchTasks,
  } = useReportFormData();

  // Format data for the search form
  const formData = {
    warehouses: sortObject(warehousesData, 'name'),
    principals: sortObject(principalsData, 'name'),
    tasks: sortObject(tasksData, 'title'),
    users: sortObject(usersData, 'first_name'),
    fetchPrincipals,
    fetchTasks,
  };

  // Filter options for the search form
  const filterOptions = {
    sickleave: false,
    editmode: false,
  };

  // Handle search submission
  const handleSearch = async (searchData) => {
    setIsSearching(true);
    if (setLoading) setLoading(true);

    try {
      // Map the form fields to the parameters expected by our thunk
      const formattedSearchData = {
        ...searchData,
        worker: searchData.employees, // Map 'employees' to 'worker'
        // Parameters expected by our API/Redux
        employeeIds: searchData.employees,
        warehouseIds: searchData.warehouse,
        principalId: searchData.principal,
        workTypeId: searchData.task,
      };

      // Dispatch the thunk with properly formatted data
      await dispatch(fetchBillingReport(formattedSearchData));
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setIsSearching(false);
      if (setLoading) setLoading(false);
    }
  };

  return (
    <ReportSearchForm
      onSearch={handleSearch}
      data={formData}
      filterOptions={filterOptions}
      isLoading={isSearching || loading}
    />
  );
};

export default BillingReportSearch;
