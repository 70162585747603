import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchShifts, fetchSickLeaves } from '../services/api';
import { eachDayOfInterval, isWithinInterval } from 'date-fns';
import { getCheckIn, getCheckOut } from '@/utils/shiftTimeCompare';

/**
 * Process and filter shifts based on search criteria
 *
 * @param {Array} shifts - Raw shifts from the API
 * @param {Object} params - Search parameters
 * @returns {Array} - Filtered and processed shifts
 */
const processShifts = (shifts, params) => {
  const newShifts = [];

  for (let i = 0; i < shifts.length; i++) {
    const shift = shifts[i];
    const warehouse = shift.warehouse;
    const worker = shift.worker;
    const worker_name = shift.worker_name;
    const tasks = shift.tasks;

    // Filter by warehouse array
    if (Array.isArray(params.warehouseIds) && params.warehouseIds.length > 0) {
      const warehouseIds = params.warehouseIds.map((id) =>
        typeof id === 'string' ? parseInt(id, 10) : id,
      );
      if (!warehouseIds.includes(parseInt(warehouse, 10))) {
        continue;
      }
    }

    // Filter by worker array
    if (Array.isArray(params.employeeIds) && params.employeeIds.length > 0) {
      const workerIds = params.employeeIds.map((id) =>
        typeof id === 'string' ? parseInt(id, 10) : id,
      );
      if (!workerIds.includes(parseInt(worker, 10))) {
        continue;
      }
    }

    const newTask = [];
    let workTypeTotal = [];

    let check_in = getCheckIn(shift.check_in, shift.assigned_start);
    let check_out = getCheckOut(shift.check_out, shift.assigned_end, shift.is_extra);

    for (let j = 0; j < tasks.length; j++) {
      const element = tasks[j];

      // Filter by principal
      if (
        (element.principal_id === Number(params.principalId)) ===
        (params.principalId === 'All')
      ) {
        continue;
      }

      // Filter by task/work_type
      if ((element.work_type === Number(params.workTypeId)) === (params.workTypeId === 'All')) {
        continue;
      }

      const work_type = {
        work_type: element.work_type,
        work_type_name: element.work_type_name,
        total: [
          {
            start: element.start,
            end: element.end,
          },
        ],
      };

      const foundWorkType = workTypeTotal.find((e) => e.work_type === element.work_type);

      if (foundWorkType) {
        const indexFoundWorkType = workTypeTotal.indexOf(foundWorkType);
        const startEnd = { start: element.start, end: element.end };
        workTypeTotal[indexFoundWorkType].total.push(startEnd);
      } else {
        workTypeTotal.push(work_type);
      }

      newTask.push(element);
    }

    if (newTask.length > 0) {
      const newShift = {
        id: shift.id,
        check_in,
        check_out,
        assigned_start: shift.assigned_start,
        worker,
        worker_name,
        tasks: newTask,
        warehouse,
        workTypeTotal,
      };

      newShifts.push(newShift);
    }
  }

  return newShifts;
};

/**
 * Process sick leave data and filter by search criteria
 *
 * @param {Array} sickLeaves - Sick leave records
 * @param {Object} params - Search parameters
 * @param {Object} apiParams - Formatted API parameters
 * @returns {Array} - Processed sick leave shifts
 */
const processSickLeaves = (sickLeaves, params, apiParams) => {
  const newShifts = [];
  let filteredSickLeaves = [];

  if (Array.isArray(params.employeeIds) && params.employeeIds.length > 0) {
    const workerIds = params.employeeIds.map((id) =>
      typeof id === 'string' ? parseInt(id, 10) : id,
    );
    filteredSickLeaves = sickLeaves.filter((leave) => workerIds.includes(leave.user_id));
  } else if (apiParams.worker) {
    filteredSickLeaves = sickLeaves.filter((leave) => leave.user_id === Number(apiParams.worker));
  } else {
    filteredSickLeaves = sickLeaves;
  }

  filteredSickLeaves.forEach((leave) => {
    if (new Date(leave.end_date).toISOString() === new Date(leave.start_date).toISOString()) {
      const inSearch = isWithinInterval(new Date(leave.start_date), {
        start: new Date(apiParams.startDate),
        end: apiParams.endDate ? new Date(apiParams.endDate) : new Date(),
      });

      if (inSearch) {
        const newShift = {
          id: leave.id,
          check_in: leave.start_date,
          check_out: leave.end_date,
          worker: leave.user_id,
          worker_name: leave.worker_name,
          tasks: [],
          warehouse: 'SICK',
          workTypeTotal: [],
        };
        newShifts.push(newShift);
      }
    } else {
      const eachDay = eachDayOfInterval({
        start: leave.start_date,
        end: leave.end_date,
      });
      eachDay.forEach((day) => {
        const inSearch = isWithinInterval(new Date(day), {
          start: new Date(apiParams.startDate),
          end: apiParams.endDate ? new Date(apiParams.endDate) : new Date(),
        });

        if (inSearch) {
          const newShift = {
            id: leave.id,
            check_in: day,
            check_out: day,
            worker: leave.user_id,
            worker_name: leave.worker_name,
            tasks: [],
            warehouse: 'SICK',
            workTypeTotal: [],
          };
          newShifts.push(newShift);
        }
      });
    }
  });

  return newShifts;
};

// Remove reference to performance data in comment
export const fetchBillingReport = createAsyncThunk(
  'billingReport/fetchReport',
  async (searchData, { dispatch }) => {
    try {
      // Get raw shifts from the API service
      const shifts = await fetchShifts(searchData);

      // Process shifts with business logic
      let processedShifts = processShifts(shifts, searchData);

      // Handle sick leave data if requested
      if (searchData.filter?.sickleave) {
        const sickLeaves = await fetchSickLeaves();
        const apiParams = {
          startDate: shifts.length > 0 ? shifts[0].check_in : '',
          endDate: shifts.length > 0 ? shifts[0].check_out : '',
          worker: searchData.worker || '',
        };
        const sickLeaveShifts = processSickLeaves(sickLeaves, searchData, apiParams);
        processedShifts = [...processedShifts, ...sickLeaveShifts];
      }

      // Remove performance data fetching and logging

      // For backward compatibility, update the legacy Redux state
      dispatch({
        type: 'SEARCHREPORT',
        payload: processedShifts,
      });

      // Format warehouse param to match legacy behavior
      if (!Number.isInteger(searchData.warehouse) && !Array.isArray(searchData.warehouse)) {
        searchData.warehouse = 'All';
      }

      dispatch({
        type: 'SETSEACHPARAMS',
        payload: searchData,
      });

      // Return the processed data for our slice
      return {
        timeInterval: processedShifts,
        searchParams: searchData,
      };
    } catch (error) {
      console.error('Error fetching billing report:', error);
      throw error;
    }
  },
);

// Initial state - matches the legacy report state structure
const initialState = {
  timeInterval: [], // Array of processed shifts
  searchParams: null, // Search parameters used
  loading: false,
  error: null,
  searchPerformed: false,
};

// Create the slice
const billingReportSlice = createSlice({
  name: 'billingReport',
  initialState,
  reducers: {
    resetReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBillingReport.fulfilled, (state, action) => {
        state.loading = false;
        state.timeInterval = action.payload.timeInterval;
        state.searchParams = action.payload.searchParams;
        state.searchPerformed = true;
      })
      .addCase(fetchBillingReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.searchPerformed = true;
        console.error('Billing report fetch failed:', action.payload);
      });
  },
});

// Export actions and reducer
export const { resetReport } = billingReportSlice.actions;
export default billingReportSlice.reducer;
