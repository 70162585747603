import { axiosNew } from './axiosConfig';

export const getVasShiftsApi = async (page, limit) => {
  const response = await axiosNew.get(`/vas/shift-activity?page=${page}&limit=${limit}`);
  return response.data;
};

export const getVasShiftApi = async (shiftId) => {
  const response = await axiosNew.get(`/vas/shift-activity/${shiftId}`);
  return response.data;
};

export const startShiftActivityApi = async (data) => {
  const response = await axiosNew.post(`/vas/shift-activity/start-shift`, data);
  return response.data;
};

export const getActiveActivityApi = async (shiftId) => {
  const response = await axiosNew.get(`/vas/shift-activity/${shiftId}/active-activity`);
  return response.data;
};

export const endShiftApi = async (shiftId) => {
  const response = await axiosNew.post(`/vas/shift-activity/${shiftId}/end-shift`, {});
  return response.data;
};

export const deleteVasShiftApi = async (shiftId) => {
  const response = await axiosNew.delete(`/vas/shift-activity/${shiftId}`);
  return response.data;
};

export const putVasShiftApi = async (shiftId, data) => {
  const response = await axiosNew.put(`/vas/shift-activity/${shiftId}`, data);
  return response.data;
};

export const patchVasShiftApi = async (shiftId, data) => {
  const response = await axiosNew.patch(`/vas/shift-activity/${shiftId}`, data);
  return response.data;
};
