import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';

const LoadingCard = ({ message }) => (
  <Card sx={{ mt: 2 }}>
    <CardContent>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={3}>
        <CircularProgress sx={{ mb: 2 }} />
        <Typography variant="body1">{message}</Typography>
      </Box>
    </CardContent>
  </Card>
);

LoadingCard.propTypes = {
  message: PropTypes.string.isRequired,
};

export default LoadingCard;
