import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Divider, Typography, Paper } from '@mui/material';

import { fNumber } from '@/utils/formatNumber';
import LoadingCard from '@/components/common/LoadingCard';
import NoDataCard from '@/components/common/NoDataCard';

/**
 * ProductSummaryItem - Displays summary information for a single product
 */
const ProductSummaryItem = memo(({ item }) => {
  const { t } = useTranslation();

  return (
    <Box mb={2}>
      <Typography variant="h6">
        {t('report.productCode')}: {item.productCode}
      </Typography>
      <Typography>
        {item.uom}: {item.totalCompletedBoxes}
      </Typography>
      <Typography>
        {t('report.pieceCount')}: {fNumber(item.totalPcs)}
      </Typography>
      <Typography>
        {t('report.workingHours')}: {fNumber(item.totalDuration, '0,0.00')} h
      </Typography>
      <Typography>
        {t('report.performance')}: {fNumber(item.performance, '0,0.00')}{' '}
        {t('common.performanceUnit')}
      </Typography>
    </Box>
  );
});

ProductSummaryItem.propTypes = {
  item: PropTypes.shape({
    productCode: PropTypes.string.isRequired,
    uom: PropTypes.string.isRequired,
    totalCompletedBoxes: PropTypes.number.isRequired,
    totalPcs: PropTypes.number.isRequired,
    totalDuration: PropTypes.number.isRequired,
    performance: PropTypes.number.isRequired,
  }).isRequired,
};

ProductSummaryItem.displayName = 'ProductSummaryItem';

/**
 * RenderVasReport - Component to display VAS product-level report data
 */
const RenderVasReport = () => {
  const { t } = useTranslation();
  const { data, loading, searchPerformed } = useSelector((state) => state.vasReport);
  const daySummary = data?.daySummary || [];

  if (loading) {
    return <LoadingCard message={t('common.loading')} />;
  }

  // Only show "No Data Available" if a search has been performed
  if (searchPerformed && !daySummary.length) {
    return <NoDataCard message={t('report.noDataAvailable')} />;
  }

  // If no search has been performed yet, return null
  if (!searchPerformed) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ mt: 2 }}>
      <CardContent>
        <Paper>
          {daySummary.map((item, index) => (
            <React.Fragment key={item.productCode}>
              <ProductSummaryItem item={item} />
              {index < daySummary.length - 1 && <Divider sx={{ mb: 2 }} />}
            </React.Fragment>
          ))}
        </Paper>
      </CardContent>
    </Card>
  );
};

export default memo(RenderVasReport);
