import {
    endShiftApi,
    getActiveActivityApi,
    startShiftActivityApi,
} from '../api/shift-activity';
import {
    addBoxesApi,
    endActivityApi,
    selectProductApi,
    startActivityApi,
} from '../api/vas-activity';

/**
 * @param {Object} data
 * @param {number} data.shiftId - The ID of the shift
 * @param {number} data.principalId - The ID of the principal
 * @param {number} data.taskId - The ID of the task
 * @param {number} data.employeeId - The ID of the employee
 * @param {number} data.warehouseId - The ID of the warehouse
 */
export const startTaskActivity = (data, isStartShift) => {
    return async (dispatch) => {
        dispatch({
            type: 'START_TASK_ACTIVITY_REQUEST',
        });

        const activityData = {
            employeeId: data.employeeId,
            warehouseId: data.warehouseId,
            principalId: data.principalId,
            taskId: data.taskId,
        };

        let res_start_shift = null;

        if (isStartShift) {
            try {
                res_start_shift = await startShiftActivityApi({
                    warehouseId: data.warehouseId,
                    employeeId: data.employeeId,
                    shiftId: data.shiftId,
                });
            } catch (shiftError) {
                console.error('Error starting shift activity:', shiftError);
            }
        }

        try {
            await startActivityApi(data.shiftId, activityData);

            dispatch({
                type: 'START_TASK_ACTIVITY_SUCCESS',
                payload: res_start_shift,
            });
        } catch (error) {
            dispatch({
                type: 'START_TASK_ACTIVITY_ERROR',
                payload: error.message,
            });
        }
    };
};

export const selectProduct = (task, product) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'SELECT_PRODUCT_REQUEST',
            });

            const data = {
                productId: product.id,
                productPcs: product.pcs,
                principalId: task.principal_id,
                taskId: task.id,
            };
            const response = await selectProductApi(task.shift, data);

            dispatch({
                type: 'SELECT_PRODUCT_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'SELECT_PRODUCT_ERROR',
                payload: error.message,
            });
        }
    };
};

export const fetchActiveActivity = (shiftId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_ACTIVE_ACTIVITY_REQUEST',
            });

            const response = await getActiveActivityApi(shiftId);

            dispatch({
                type: 'GET_ACTIVE_ACTIVITY_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'GET_ACTIVE_ACTIVITY_ERROR',
                payload: error.message,
            });
        }
    };
};

export const addBoxes = (shiftId, boxesData) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'ADD_BOXES_REQUEST',
            });

            const response = await addBoxesApi(shiftId, boxesData);

            dispatch({
                type: 'ADD_BOXES_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'ADD_BOXES_ERROR',
                payload: error.message,
            });
        }
    };
};

export const endActivity = (shiftId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'END_ACTIVITY_REQUEST',
            });

            const response = await endActivityApi(shiftId);

            dispatch({
                type: 'END_ACTIVITY_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'END_ACTIVITY_ERROR',
                payload: error.message,
            });
        }
    };
};

export const endShift = (shiftId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'END_SHIFT_REQUEST',
            });

            const response = await endShiftApi(shiftId);

            dispatch({
                type: 'END_SHIFT_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'END_SHIFT_ERROR',
                payload: error.message,
            });
        }
    };
};
