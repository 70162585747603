import common from './common';
import login from './login';
import navigation from './navigation';
import timer from './timer';
import report from './report';
import setting from './setting';
import instruction from './instruction';
import liveStatsMonitor from './liveStatsMonitor';
import sickleave from './sickleave';
import notification from './notification';
import rules from './rules';
import selectedProduct from './selectedProduct';
import workTypes from './workTypes';
import vasShifts from './vasShifts';

const resources = {
  en: {
    common: common.en,
    login: login.en,
    navigation: navigation.en,
    timer: timer.en,
    report: report.en,
    setting: setting.en,
    instruction: instruction.en,
    liveStatsMonitor: liveStatsMonitor.en,
    sickleave: sickleave.en,
    notification: notification.en,
    rules: rules.en,
    selectedProduct: selectedProduct.en,
    workTypes: workTypes.en,
    vasShifts: vasShifts.en,
  },
  fi: {
    common: common.fi,
    login: login.fi,
    navigation: navigation.fi,
    timer: timer.fi,
    report: report.fi,
    setting: setting.fi,
    instruction: instruction.fi,
    liveStatsMonitor: liveStatsMonitor.fi,
    sickleave: sickleave.fi,
    notification: notification.fi,
    rules: rules.fi,
    selectedProduct: selectedProduct.fi,
    workTypes: workTypes.fi,
    vasShifts: vasShifts.fi,
  },
  vi: {
    common: common.vi,
    login: login.vi,
    navigation: navigation.vi,
    timer: timer.vi,
    report: report.vi,
    setting: setting.vi,
    instruction: instruction.vi,
    liveStatsMonitor: liveStatsMonitor.vi,
    sickleave: sickleave.vi,
    notification: notification.vi,
    rules: rules.vi,
    selectedProduct: selectedProduct.vi,
    workTypes: workTypes.vi,
    vasShifts: vasShifts.vi,
  },
};

export default resources;
