import { axiosNew } from '@/api/axiosConfig';

/**
 * Helper function to properly serialize parameters for API requests
 * Handles array parameters correctly
 * @param {Object} params - The parameters to serialize
 * @returns {URLSearchParams} Serialized parameters
 */
export const serializeParams = (params) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // Add each array item individually with the same key
      value.forEach((item) => {
        searchParams.append(key, item);
      });
    } else if (value !== undefined && value !== null) {
      searchParams.append(key, value);
    }
  });

  return searchParams;
};

/**
 * Get day summary report data from the API
 * @param {Object} params - Report filter parameters
 * @returns {Promise<Array>} Report data
 */
export const getDaySummaryApi = async (params) => {
  const serializedParams = serializeParams(params);
  const url = `/vas/report/day-summary?${serializedParams.toString()}`;

  try {
    const response = await axiosNew.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching day summary report:', error);
    throw error;
  }
};

/**
 * Get product type summary report data from the API
 * @param {Object} params - Report filter parameters
 * @returns {Promise<Array>} Report data
 */
export const getProductTypeSummaryApi = async (params) => {
  const serializedParams = serializeParams(params);
  const url = `/vas/report/product-type-summary?${serializedParams.toString()}`;

  try {
    const response = await axiosNew.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching product type summary report:', error);
    throw error;
  }
};
