/**
 * Helper function to format a duration (in ms) as "Xh Ym Zs"
 * @param {number} duration - Duration in milliseconds
 * @returns {string} - Formatted duration string
 */
export const formatDuration = (duration) => {
  const safeDuration = Math.max(0, duration);
  const totalSeconds = Math.floor(safeDuration / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}h ${minutes}m ${seconds}s`;
};

/**
 * Helper function to get background color based on performance percentage
 * @param {number} currentPerformance - Current performance value
 * @param {number} targetPerformance - Target performance value
 * @param {number} boxCount - Number of boxes completed
 * @returns {string} - MUI color value for background
 */
export const getPerformanceBackgroundColor = (currentPerformance, targetPerformance, boxCount = 0) => {
  // If no boxes completed yet, return neutral color
  if (boxCount === 0) return 'grey.100';

  // Default color if no valid target
  if (!targetPerformance || targetPerformance <= 0) return 'grey.100';

  const performanceRatio = currentPerformance / targetPerformance;

  if (performanceRatio < 0.875) {
    return '#FFCDD2'; // Light red background for < 87.5% of target
  } else if (performanceRatio < 1) {
    return '#FFF9C4'; // Light yellow background for 87.5% - 100% of target
  } else {
    return '#C8E6C9'; // Light green background for > 100% of target
  }
};