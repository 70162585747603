import React from 'react';

import { Typography } from '@mui/material';

import { timestampToDecimal } from '@/utils/DateTime';
import { formatSearchingDate } from '@/utils/formatDates';

/**
 * BillingReportHeader Component
 *
 * Displays header information for the billing report, including total working hours.
 *
 * @param {Object} props Component props
 * @param {Object} props.SearchParams Search parameters used to generate the report
 * @param {Object} props.newReports Report data to display
 * @returns {JSX.Element} The rendered header component
 */
const BillingReportHeader = ({ SearchParams, newReports }) => {
  const getGrandTotal = (newReports) => {
    let grandTotal = 0;
    for (let worker in newReports) {
      grandTotal = grandTotal + newReports[worker].total;
    }

    return timestampToDecimal(grandTotal);
  };

  return (
    <div id="headerToPrint" className="pb-3">
      <Typography variant="h3" className="andromeda-logo">
        ANDROMEDA
      </Typography>
      <Typography variant="h4" className="text-center">
        Työt ajalta: {formatSearchingDate(SearchParams?.startDate, SearchParams?.endDate)}
      </Typography>
      <Typography variant="h4" className="text-center">
        {'Työtunnit yht. ' + getGrandTotal(newReports) + ' h'}
      </Typography>
    </div>
  );
};

export default BillingReportHeader;
