import axios from 'axios';
import { getStorage, ref, uploadString } from 'firebase/storage';
import {
  doc,
  updateDoc,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { formatDocs } from '../utils/formatFirestore';
import { absencesCollectionRef } from '../db/firestore';
import { createNotification } from './notification';

const { REACT_APP_FIREBASE_API_KEY } = process.env;

// Firebase storage functionality for sickleave uploads with error handling
const uploadFileApi = async (base64, filename) => {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `sickleave/${filename}`);
    return await uploadString(storageRef, base64, 'data_url');
  } catch (error) {
    console.error('Error uploading sickleave file:', error);
    throw new Error('Failed to upload sickleave document');
  }
};

// Google Vision API integration with error handling
const postImage = async (body) => {
  try {
    const response = await axios.post(
      `https://eu-vision.googleapis.com/v1/images:annotate?key=${REACT_APP_FIREBASE_API_KEY}`,
      body,
    );
    return response.data;
  } catch (error) {
    console.error('Error processing image with Vision API:', error);
    throw new Error('Failed to process sickleave document');
  }
};

// Post absence with error handling
const postAbsence = async (data) => {
  try {
    await addDoc(absencesCollectionRef, data);
  } catch (error) {
    console.error('Error creating absence record:', error);
    throw new Error('Failed to create sickleave record');
  }
};

// Get absences with error handling
export const getAbsences = async () => {
  try {
    const q = query(absencesCollectionRef, orderBy('time_stamp', 'desc'));
    const response = await getDocs(q);
    return formatDocs(response);
  } catch (error) {
    console.error('Error fetching absences:', error);
    return [];
  }
};

export const getUserAbsences = async (user_id) => {
  try {
    const q = query(absencesCollectionRef, where('user_id', '==', user_id));
    const response = await getDocs(q);
    return formatDocs(response);
  } catch (error) {
    console.error('Error fetching user absences:', error);
    return [];
  }
};

export const getReportAbsences = async () => {
  try {
    const q = query(absencesCollectionRef, where('accepted', '==', true));
    const response = await getDocs(q);
    return formatDocs(response);
  } catch (error) {
    console.error('Error fetching report absences:', error);
    return [];
  }
};

export const updateStatus = async (inputData) => {
  try {
    const acceptRef = doc(absencesCollectionRef, inputData.uuid);
    let data = {
      processed: true,
      accepted_time_stamp: new Date(),
      accepted_by: inputData.accepted_by,
      description: inputData.description || '',
    };

    // Prepare notification data
    const notificationData = {
      title: 'You-sick-leave-is-processed',
      description:
        inputData.status === 'accepted' ? 'Accepted' : `Rejected, ${inputData?.description}`,
      createdAt: new Date(),
      user_id: inputData.user_id,
      type: 'sickleave',
      isRead: false,
    };

    if (inputData.status === 'accepted') {
      data = { ...data, accepted: true, status: 'accepted' };
    } else {
      data = { ...data, accepted: false, status: 'rejected' };
    }

    await updateDoc(acceptRef, data);
    await createNotification(notificationData);
  } catch (error) {
    console.error('Error updating sickleave status:', error);
    throw new Error('Failed to update sickleave status');
  }
};

export const updateDates = async (inputData) => {
  try {
    const docRef = doc(absencesCollectionRef, inputData.uuid);
    const data = {
      start_date: inputData.start_date,
      end_date: inputData.end_date,
      reason: inputData.reason,
    };
    if (inputData.user_id) {
      data.user_id = inputData.user_id;
    }
    await updateDoc(docRef, data);
  } catch (error) {
    console.error('Error updating sickleave dates:', error);
    throw new Error('Failed to update sickleave dates');
  }
};

export const deleteAbsence = async (id) => {
  try {
    const deleteRef = doc(absencesCollectionRef, id);
    await deleteDoc(deleteRef);
  } catch (error) {
    console.error('Error deleting absence:', error);
    throw new Error('Failed to delete sickleave record');
  }
};

// eslint-disable-next-line
export default {
  uploadFileApi,
  postImage,
  postAbsence,
};
