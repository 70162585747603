import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getVasReport } from '../redux/vasReportSlice';
import { useReportData } from '@/hooks/useReportData';
import ReportSearchForm from '@/components/report/ReportSearchForm';

/**
 * VasReportFilter - Component for filtering VAS report data
 * @param {Object} props - Component props
 * @param {string} props.reportType - Type of report ('product' or 'product-type')
 */
const VasReportFilter = ({ reportType }) => {
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);

  const {
    warehousesData,
    principalsData,
    tasksData,
    usersData,
    fetchPrincipals,
    fetchTasks,
    loading: dataLoading,
  } = useReportData();

  // Format date for API submission
  const formatDate = useCallback((date) => {
    return date instanceof Date ? date.toISOString().split('T')[0] : date;
  }, []);

  // Handle search form submission
  const handleSearch = useCallback(
    async (searchData) => {
      setIsSearching(true);
      try {
        // Format dates properly and add report type
        const formattedData = {
          ...searchData,
          startDate: formatDate(searchData.startDate),
          endDate: formatDate(searchData.endDate),
          reportType,
        };

        await dispatch(getVasReport(formattedData));
      } catch (error) {
        console.error('Error during VAS report search:', error);
      } finally {
        setIsSearching(false);
      }
    },
    [dispatch, reportType, formatDate],
  );

  // Prepare form data object
  const formData = {
    warehouses: warehousesData,
    principals: principalsData,
    tasks: tasksData,
    users: usersData,
    fetchPrincipals,
    fetchTasks,
  };

  return (
    <ReportSearchForm
      onSearch={handleSearch}
      data={formData}
      isLoading={isSearching || dataLoading}
    />
  );
};

VasReportFilter.propTypes = {
  reportType: PropTypes.oneOf(['product', 'product-type']).isRequired,
};

export default memo(VasReportFilter);
