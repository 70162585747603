import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake';

import { getWarehouseNameByID } from '../components/HelperFunctions/Others';
import { formatSearchingDate } from './formatDates';

const genPdf = async (newReports, SearchParams, warehouses, summaryLength, setDownloadLoading) => {
    setDownloadLoading(true);
    const pdfData = [];

    const pdfTable = document.getElementById(`headerToPrint`);
    if (pdfTable) {
      const header = await html2canvas(pdfTable).then(function (canvas) {
        const imgObj = {
          image: canvas.toDataURL(),
          width: 550,
          style: {
            alignment: 'center',
          },
        };
        return imgObj;
      });
      pdfData.push(header);
    }

    const keysLength = Object.keys(newReports).length;
    for (let i = 0; i < keysLength; i++) {
      const pdfTable = document.getElementById(`divToPrint-${i}`);
      const imgs = await html2canvas(pdfTable).then(function (canvas) {
        const imgObj = {
          image: canvas.toDataURL(),
          width: 550,
          style: {
            alignment: 'center',
          },
        };
        return imgObj;
      });
      pdfData.push(imgs);
    }
  
    pdfData.push({ text: '', pageBreak: 'before' });
  
    const summaryheader = document.getElementById(`summaryHeader`);
    if (summaryheader) {
      const header = await html2canvas(summaryheader).then(function (canvas) {
        const imgObj = {
          image: canvas.toDataURL(),
          width: 550,
          style: {
            alignment: 'center',
          },
        };
        return imgObj;
      });
      pdfData.push(header);
    }
  

    for (let i = 0; i < summaryLength; i++) {
      const pdfTable = document.getElementById(`summaryToPrint-${i}`);
      const imgs = await html2canvas(pdfTable).then(function (canvas) {
        const imgObj = {
          image: canvas.toDataURL(),
          width: 550,
          style: {
            alignment: 'center',
          },
        };
        return imgObj;
      });
      pdfData.push(imgs);
    }
  
  
    const documentDefinition = {
      content: pdfData,
      defaultStyle: {
        font: 'NimbusSans',
      },
      pageOrientation: 'portrait',
    };
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.download(
      `${formatSearchingDate(
        SearchParams?.startDate,
        SearchParams?.endDate
      )}(${getWarehouseNameByID(SearchParams?.warehouse, warehouses)}).pdf`
    );
    setDownloadLoading(false);
  };


  export default genPdf;