import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Card,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import Scrollbar from '../../../scrollbar';
import Iconify from '../../../iconify';
import { visuallyHidden } from '@mui/utils';
import { getUsers } from '../../../../actions/userActions';
import EmptyContent from '../../../empty-content';

const TABLE_HEAD = [
  {
    id: 'id',
    numeric: false,
    label: 'ID',
  },
  {
    id: 'last_name',
    numeric: false,
    label: 'Last Name',
  },
  {
    id: 'first_name',
    numeric: false,
    label: 'First Name',
  },
  {
    id: 'email',
    numeric: false,
    label: 'Email',
  },
  {
    id: 'user_type',
    numeric: false,
    label: 'Role',
  },
  {
    id: 'total_work_hours',
    numeric: false,
    label: 'Total Work Hours',
  },
  {
    id: 'annual_work_hours',
    numeric: false,
    label: 'Annual Work Hours',
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// ----------------------------------------------------------------------

export default function EmployeeList() {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterName, setFilterName] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  // Keep useSelector at the top level
  const usersFromStore = useSelector(state => state.users);

  // Memoize the users array to maintain a stable reference
  const users = React.useMemo(() => {
    return usersFromStore || [];
  }, [usersFromStore]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = event => {
    setFilterName(event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getUsers());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const filteredUsers = React.useMemo(() => {
    if (!Array.isArray(users)) return [];

    return users.filter(user =>
      Object.values(user)
        .join(' ')
        .toLowerCase()
        .includes(filterName.toLowerCase())
    );
  }, [users, filterName]); // Now we can safely include the memoized users array in dependencies

  const isNotFound = !filteredUsers.length && !!filterName;
  const isUsersEmpty = !loading && filteredUsers.length === 0;

  // Calculate empty rows for a nice table display when data doesn't fill the page
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6">Employees</Typography>
        <TextField
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search employee..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      hideSortIcon
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={event => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box sx={{ ...visuallyHidden }}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                    <Stack direction="row" justifyContent="center">
                      <CircularProgress />
                    </Stack>
                  </TableCell>
                </TableRow>
              ) : isNotFound || isUsersEmpty ? (
                <TableRow>
                  <TableCell colSpan={TABLE_HEAD.length}>
                    <EmptyContent
                      title={
                        isNotFound
                          ? 'No matching results'
                          : 'No employees found'
                      }
                      sx={{ py: 10 }}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {stableSort(filteredUsers, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {TABLE_HEAD.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                          >
                            {row[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={TABLE_HEAD.length} />
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}
