const selectedProduct = {
  en: {
    loading: 'Loading',
    switchProduct: 'Switch product',
    timeElapsed: 'Time Elapsed',
    totalBoxesCompleted: 'Total Boxes Completed',
    totalBoxesCompletedDetail: '({{count}} stk)',
    avgPerformance: 'Avg Performance',
    performanceUnit: 'stk/hour',
    addBox: 'Add Box',
    delayCountdown: 'Waiting for {{count}}s',
    target: 'Target',
    underTarget: 'Under target',
    targetReached: 'Target reached!',
    scanBarcode: 'Scan Barcode',
    lastScannedBarcode: 'Last Scanned Barcode',
    noBarcodeScanYet: 'No barcode scanned yet',
    pallet: {
      found: 'Existing pallet found',
      notFound: 'New pallet will be created',
    },
  },
  fi: {
    loading: 'Ladataan tuotetietoja...',
    switchProduct: 'Vaihda tuotetta',
    timeElapsed: 'Kulunut aika',
    totalBoxesCompleted: 'Tehtyjä laatikoita yhteensä',
    totalBoxesCompletedDetail: '({{count}} kpl)',
    avgPerformance: 'Keskiarvo',
    performanceUnit: 'kpl/tunti',
    addBox: 'Lisää laatikko',
    delayCountdown: 'Odottaa {{count}}s',
    target: 'Tavoite',
    underTarget: 'Alle tavoitteen',
    targetReached: 'Tavoite saavutettu!',
    scanBarcode: 'Skannaa viivakoodi',
    lastScannedBarcode: 'Viimeisin skannattu viivakoodi',
    noBarcodeScanYet: 'Ei vielä skannattu viivakoodia',
    pallet: {
      found: 'Lava löytyi',
      notFound: 'Uusi lava luodaan',
    },
  },
  vi: {
    loading: 'Đang tải chi tiết sản phẩm...',
    switchProduct: 'Chuyển sản phẩm',
    timeElapsed: 'Thời gian đã trôi',
    totalBoxesCompleted: 'Tổng số hộp đã hoàn thành',
    totalBoxesCompletedDetail: '({{count}} cái)',
    avgPerformance: 'Hiệu suất trung bình',
    performanceUnit: 'cái/giờ',
    addBox: 'Thêm hộp',
    delayCountdown: 'Chờ {{count}}s',
    target: 'Mục tiêu',
    underTarget: 'Dưới mục tiêu',
    targetReached: 'Đạt mục tiêu!',
    scanBarcode: 'Quét mã vạch',
    lastScannedBarcode: 'Mã vạch đã quét cuối cùng',
    noBarcodeScanYet: 'Chưa có mã vạch nào được quét',
    pallet: {
      found: 'Đã tìm thấy pallet',
      notFound: 'Sẽ tạo pallet mới',
    },
  },
};

export default selectedProduct;
