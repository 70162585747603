import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Material UI imports
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Chip,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

// Actions
import { getWorkingShift } from '../../../actions/shiftActions';
import { getUsers } from '../../../actions/userActions';
import { getWarehouse } from '../../../actions/warehouseActions';

// Helper components and utilities
import CountTime from '../../HelperFunctions/CountTime';
import { convertIsoToLocalDate, extractTime } from '../../../utils/DateTime';

/**
 * CurrentWorking component displays the currently working shifts grouped by warehouse
 */
const CurrentWorking = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filterID, setFilterID] = useState(null);

  // Fetch required data on component mount
  useEffect(() => {
    dispatch(getWorkingShift());
    dispatch(getUsers());
    dispatch(getWarehouse());
  }, [dispatch]);

  // Redux state selectors
  const shifts = useSelector(state => state.shift) || [];
  const warehouses = useSelector(state => state.warehouses) || [];

  // Filter working shifts based on selected warehouse
  const workingShifts = shifts.filter(
    shift =>
      shift.is_working === true &&
      (filterID === null || shift.warehouse === parseInt(filterID))
  );

  /**
   * Gets warehouse name by ID
   * @param {number} warehouseID - Warehouse ID
   * @returns {string|null} Warehouse name or null if not found
   */
  const getWarehouseNameByID = warehouseID => {
    if (!warehouses || warehouses.length === 0) return null;

    const warehouse = warehouses.find(w => w.id === warehouseID);
    return warehouse ? warehouse.name : null;
  };

  /**
   * Groups shifts by warehouse and counts them
   * @param {Array} shiftsData - Shifts data to group
   * @returns {Object} Grouped warehouse data with counts
   */
  const groupWarehouses = shiftsData => {
    if (!shiftsData || shiftsData.length === 0) return {};

    const grouped = {};
    shiftsData.forEach(shift => {
      const warehouse = shift.warehouse;
      if (warehouse in grouped) {
        grouped[warehouse].total++;
      } else {
        grouped[warehouse] = {
          name: getWarehouseNameByID(warehouse),
          total: 1,
        };
      }
    });
    return grouped;
  };

  // Group warehouses for display
  const groupedWarehouses = groupWarehouses(shifts);

  /**
   * Updates current working data
   */
  const updateCurrent = () => {
    dispatch(getWorkingShift());
  };

  /**
   * Resets filter to show all warehouses
   */
  const resetFilter = () => {
    setFilterID(null);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">
          {t('report.total')}: {shifts.length}
        </Typography>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={updateCurrent}
        >
          {t('common.update')}
        </Button>
      </Box>

      {/* Warehouse filter chips */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {Object.keys(groupedWarehouses).map(id => (
          <Grid item key={id}>
            <Chip
              label={`${groupedWarehouses[id].name}: ${groupedWarehouses[id].total}`}
              onClick={() => setFilterID(id)}
              color={filterID === id ? 'primary' : 'default'}
              clickable
              sx={{ cursor: 'pointer' }}
            />
          </Grid>
        ))}
        {filterID && (
          <Grid item>
            <Chip
              label={t('common.showAll')}
              onClick={resetFilter}
              variant="outlined"
              clickable
            />
          </Grid>
        )}
      </Grid>

      {/* Shifts table */}
      {filterID && workingShifts.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="current working shifts table">
            <TableHead>
              <TableRow>
                <TableCell>{t('report.employee')}</TableCell>
                <TableCell>{t('report.principal')}</TableCell>
                <TableCell>{t('report.workType')}</TableCell>
                <TableCell>{t('report.startWorking')}</TableCell>
                <TableCell>{t('report.workingTime')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workingShifts.map(shift => (
                <TableRow key={shift.id}>
                  <TableCell>{shift.worker_name}</TableCell>
                  <TableCell>{shift.tasks.principal_name}</TableCell>
                  <TableCell>{t(shift.tasks.work_type_name)}</TableCell>
                  <TableCell>
                    {convertIsoToLocalDate(shift.check_in)}{' '}
                    {extractTime(shift.check_in)}
                  </TableCell>
                  <TableCell>
                    <CountTime startTime={Date.parse(shift.check_in)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {filterID && workingShifts.length === 0 && (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography variant="body1" color="text.secondary">
            {t('common.noData')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

CurrentWorking.propTypes = {
  // Add prop types if needed
};

export default CurrentWorking;
