const initialWorkLogState = {
  shiftActivity: {},
  shiftActivities: [],
  activeShiftActivity: null,
  loading: false,
  error: null,
};

export default function vasWorkLogReducer(state = initialWorkLogState, action) {
  switch (action.type) {
    case 'START_TASK_ACTIVITY_REQUEST':
    case 'SELECT_PRODUCT_REQUEST':
    case 'GET_ACTIVE_ACTIVITY_REQUEST':
    case 'ADD_BOXES_REQUEST':
    case 'END_ACTIVITY_REQUEST':
    case 'END_SHIFT_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'GET_ACTIVE_ACTIVITY_SUCCESS': {
      const activities = action.payload.activities.filter(
        (activity) => activity.productId !== null,
      );
      const activeActivity = activities.find((activity) => activity.endTime === null);
      return {
        ...state,
        shiftActivity: {
          ...action.payload,
          activities,
        },
        shiftActivities: activities,
        activeShiftActivity: activeActivity || null,
        loading: false,
        error: null,
      };
    }

    case 'START_TASK_ACTIVITY_SUCCESS':
      return {
        ...state,
        shiftActivity: action.payload,
        shiftActivities: [],
        activeShiftActivity: null,
        loading: false,
        error: null,
      };

    case 'SELECT_PRODUCT_SUCCESS': {
      const newActivity = action.payload;
      const isNew = !state.shiftActivities.some((activity) => activity.id === newActivity.id);

      let updatedShiftActivities;
      let updatedNestedActivities;

      if (isNew) {
        updatedShiftActivities = [...state.shiftActivities, newActivity];
        updatedNestedActivities = [...(state.shiftActivity.activities || []), newActivity];
      } else {
        updatedShiftActivities = state.shiftActivities
          .filter((activity) => activity.productId === newActivity.productId)
          .map((activity) => (activity.id === newActivity.id ? newActivity : activity));
        updatedNestedActivities = (state.shiftActivity.activities || [])
          .filter((activity) => activity.productId === newActivity.productId)
          .map((activity) => (activity.id === newActivity.id ? newActivity : activity));
      }

      updatedShiftActivities = updatedShiftActivities.filter(
        (activity) => activity.productId !== null,
      );

      return {
        ...state,
        activeShiftActivity: newActivity,
        shiftActivities: updatedShiftActivities,
        shiftActivity: {
          ...state.shiftActivity,
          activities: updatedNestedActivities,
        },
        loading: false,
        error: null,
      };
    }

    case 'ADD_BOXES_SUCCESS': {
      const updatedActivity = action.payload;
      const updatedShiftActivities = state.shiftActivities
        .map((activity) => (activity.id === updatedActivity.id ? updatedActivity : activity))
        .filter((activity) => activity.productId !== null);
      return {
        ...state,
        activeShiftActivity: updatedActivity,
        shiftActivities: updatedShiftActivities,
        loading: false,
        error: null,
      };
    }

    case 'END_ACTIVITY_SUCCESS':
      return {
        ...state,
        activeShiftActivity: null,
        loading: false,
        error: null,
      };

    case 'END_SHIFT_SUCCESS':
      return initialWorkLogState;

    case 'START_TASK_ACTIVITY_ERROR':
    case 'SELECT_PRODUCT_ERROR':
    case 'ADD_BOXES_ERROR':
    case 'END_ACTIVITY_ERROR':
    case 'END_SHIFT_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'DESELECT_PRODUCT':
      return {
        ...state,
        activeShiftActivity: null,
        loading: false,
      };

    default:
      return state;
  }
}
