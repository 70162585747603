import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const Navigation = () => {
  const user = useSelector(state => state.user);
  return (
    <>
      {!user && (
        <Box
          sx={{
            bgcolor: 'primary.main',
            display: 'flex',
            justifyContent: 'center',
            py: 1,
          }}
          className="bg-red"
        >
          <Link to="/" style={{ textDecoration: 'none' }} className="logo-link">
            <span className="andromeda-logo">ANDROMEDA</span>
          </Link>
        </Box>
      )}
    </>
  );
};

export default Navigation;
