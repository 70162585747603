import {
    deleteVasShiftApi,
    getVasShiftApi,
    getVasShiftsApi,
    patchVasShiftApi,
} from '../api/shift-activity';

export const GET_VAS_SHIFTS_REQUEST = 'GET_VAS_SHIFTS_REQUEST';
export const GET_VAS_SHIFTS_SUCCESS = 'GET_VAS_SHIFTS_SUCCESS';
export const GET_VAS_SHIFTS_FAILURE = 'GET_VAS_SHIFTS_FAILURE';

export const GET_VAS_SHIFT_REQUEST = 'GET_VAS_SHIFT_REQUEST';
export const GET_VAS_SHIFT_SUCCESS = 'GET_VAS_SHIFT_SUCCESS';
export const GET_VAS_SHIFT_FAILURE = 'GET_VAS_SHIFT_FAILURE';

export const DELETE_VAS_SHIFTS_REQUEST = 'DELETE_VAS_SHIFTS_REQUEST';
export const DELETE_VAS_SHIFTS_SUCCESS = 'DELETE_VAS_SHIFTS_SUCCESS';
export const DELETE_VAS_SHIFTS_FAILURE = 'DELETE_VAS_SHIFTS_FAILURE';

/**
 * Action to fetch paginated VAS shifts.
 * @param {number} page - The current page.
 * @param {number} limit - The number of items per page.
 */
export const getVasShifts = (page = 1, limit = 25) => {
    return async (dispatch) => {
        dispatch({ type: GET_VAS_SHIFTS_REQUEST });

        try {
            const response = await getVasShiftsApi(page, limit);
            dispatch({ type: GET_VAS_SHIFTS_SUCCESS, payload: response });
        } catch (error) {
            const errorMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                'Failed to fetch VAS shifts';

            dispatch({ type: GET_VAS_SHIFTS_FAILURE, payload: errorMessage });
        }
    };
};

/**
 * Action to fetch a single VAS shift by its ID.
 * @param {number|string} shiftId - The ID of the shift to fetch.
 */
export const getVasShift = (shiftId) => {
    return async (dispatch) => {
        dispatch({ type: GET_VAS_SHIFT_REQUEST });

        try {
            const response = await getVasShiftApi(shiftId);
            dispatch({ type: GET_VAS_SHIFT_SUCCESS, payload: response });
        } catch (error) {
            const errorMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                'Failed to fetch VAS shift';

            dispatch({ type: GET_VAS_SHIFT_FAILURE, payload: errorMessage });
        }
    };
};

/**
 * Action to delete one or more VAS shifts.
 * If the API only supports single deletion, we loop through the IDs.
 *
 * @param {Array<number|string>} shiftIds - An array of shift IDs to delete.
 */
export const deleteVasShifts = (shiftIds) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_VAS_SHIFTS_REQUEST });

        try {
            // If your API supports bulk deletion, you might call it like:
            // const response = await deleteVasShiftsApi(shiftIds);
            // Otherwise, if the API supports only single deletion, use Promise.all:
            await Promise.all(shiftIds.map((id) => deleteVasShiftApi(id)));

            // Optionally, you can return the deleted IDs so reducers can update state accordingly.
            dispatch({ type: DELETE_VAS_SHIFTS_SUCCESS, payload: shiftIds });
        } catch (error) {
            const errorMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                'Failed to delete VAS shifts';

            dispatch({
                type: DELETE_VAS_SHIFTS_FAILURE,
                payload: errorMessage,
            });
        }
    };
};

export const UPDATE_VAS_SHIFT_REQUEST = 'UPDATE_VAS_SHIFT_REQUEST';
export const UPDATE_VAS_SHIFT_SUCCESS = 'UPDATE_VAS_SHIFT_SUCCESS';
export const UPDATE_VAS_SHIFT_FAILURE = 'UPDATE_VAS_SHIFT_FAILURE';

export const updateVasShift = (id, updatedData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_VAS_SHIFT_REQUEST });

        const { data } = await patchVasShiftApi(id, updatedData);

        dispatch({ type: UPDATE_VAS_SHIFT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_VAS_SHIFT_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};
