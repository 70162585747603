import reportApi from '@/api/report';
import { getReportAbsences } from '@/api/sickleave';
import { dateToUtcStart, dateToUtcEnd } from '@/utils/DateTime';

/**
 * Helper function to prepare API params for report search
 *
 * @param {Object} params - The search parameters
 * @returns {Object} Formatted parameters for the API
 */
export const prepareSearchParams = (params) => {
  // Format dates for API
  const startDate = params.startDate ? dateToUtcStart(params.startDate) : '';
  const endDate = params.endDate ? dateToUtcEnd(params.endDate) : '';

  // Handle warehouse parameter
  let warehouse = '';
  if (
    (Number.isInteger(parseInt(params.warehouseIds)) || typeof params.warehouseIds === 'number') &&
    !Array.isArray(params.warehouseIds)
  ) {
    warehouse = params.warehouseIds;
  }

  // Handle worker parameter
  let worker = '';
  if (
    (Number.isInteger(parseInt(params.employeeIds)) || typeof params.employeeIds === 'number') &&
    !Array.isArray(params.employeeIds)
  ) {
    worker = params.employeeIds;
  }

  // Return formatted params
  return {
    startDate,
    endDate,
    warehouse,
    worker,
  };
};

/**
 * Fetch shifts data from the report API
 *
 * @param {Object} params - Search parameters
 * @returns {Promise<Array>} - Array of shifts
 */
export const fetchShifts = async (params) => {
  const apiParams = prepareSearchParams(params);
  console.log('Fetching shifts with params:', apiParams);
  return await reportApi.searchShifts(apiParams);
};

/**
 * Fetch sick leave data
 *
 * @returns {Promise<Array>} - Array of sick leave records
 */
export const fetchSickLeaves = async () => {
  return await getReportAbsences();
};
