import {
    deleteVasProductApi,
    getproductTypeAndUOMApi,
    getProductTypesApi,
    getVasProductByIdApi,
    getVasProductsApi,
    getVasProductsByWorkTypeIdApi,
    postVasProductApi,
    updateVasProductApi,
} from '../api/vasproduct';

export function getVasProducts() {
    return async (dispatch) => {
        dispatch({ type: 'GET_VAS_PRODUCTS_REQUEST' });
        try {
            const response = await getVasProductsApi();
            if (response) {
                dispatch({
                    type: 'GET_VAS_PRODUCTS_SUCCESS',
                    payload: response,
                });
            }
        } catch (error) {
            dispatch({
                type: 'GET_VAS_PRODUCTS_FAILURE',
                payload: error.message || 'Failed to fetch VAS products.',
            });
        }
    };
}

export function getVasProductsByWorkTypeId(workTypeId) {
    return async (dispatch) => {
        dispatch({ type: 'GET_VAS_PRODUCTS_BY_WORKTYPE_REQUEST' });
        try {
            const response = await getVasProductsByWorkTypeIdApi(workTypeId);
            if (response) {
                dispatch({
                    type: 'GET_VAS_PRODUCTS_BY_WORKTYPE_SUCCESS',
                    payload: response,
                });
            }
        } catch (error) {
            dispatch({
                type: 'GET_VAS_PRODUCTS_BY_WORKTYPE_FAILURE',
                payload:
                    error.message ||
                    'Failed to fetch VAS products by work type.',
            });
        }
    };
}

export function addVasProduct(data) {
    return async (dispatch) => {
        try {
            // Convert pcs and targetPerformance to numbers only if they have a value.
            if (data.pcs) {
                data.pcs = Number(data.pcs);
            }
            if (data.targetPerformance) {
                data.targetPerformance = Number(data.targetPerformance);
            }

            const response = await postVasProductApi(data);

            dispatch({
                type: 'ADD_VAS_PRODUCT_SUCCESS',
                payload: response,
            });
            return response;
        } catch (error) {
            let errors = { general: 'Failed to add VAS product.' };

            if (error.response && error.response.data) {
                const errorData = error.response.data;

                if (Array.isArray(errorData.message)) {
                    errors = {};
                    errorData.message.forEach((msg) => {
                        const lowerMsg = msg.toLowerCase();
                        if (lowerMsg.includes('product code')) {
                            errors.productCode = msg;
                        }
                        if (lowerMsg.includes('uom')) {
                            errors.uom = msg;
                        }
                        if (lowerMsg.includes('pcs')) {
                            errors.pcs = msg;
                        }
                        if (lowerMsg.includes('producttype')) {
                            errors.productType = msg;
                        }
                        if (
                            lowerMsg.includes('principalname') ||
                            lowerMsg.includes('principal name')
                        ) {
                            errors.principalName = msg;
                        }
                        if (lowerMsg.includes('targetperformance')) {
                            errors.targetPerformance = msg;
                        }
                        if (
                            lowerMsg.includes('worktypeid') ||
                            lowerMsg.includes('work type id')
                        ) {
                            errors.workTypeId = msg;
                        }
                        // Add other field checks as needed
                    });
                } else if (typeof errorData.message === 'string') {
                    errors.general = errorData.message;
                }
            }

            dispatch({
                type: 'ADD_VAS_PRODUCT_FAILURE',
                payload: errors.general, // This will update your Redux state
            });
            throw errors; // Throw the full error object for the component
        }
    };
}

export function deleteVasProduct(docId) {
    return async (dispatch) => {
        try {
            await deleteVasProductApi(docId);
            dispatch({
                type: 'DELETE_VAS_PRODUCT_SUCCESS',
                payload: docId,
            });
        } catch (error) {
            console.error(
                'ERROR',
                error.message || 'Failed to delete VAS product.'
            );
        }
    };
}

export function getProductTypesAndUoms() {
    return async (dispatch) => {
        dispatch({ type: 'GET_PRODUCT_TYPES_AND_UOMS_REQUEST' });
        try {
            const response = await getproductTypeAndUOMApi();
            dispatch({
                type: 'GET_PRODUCT_TYPES_AND_UOMS_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'GET_PRODUCT_TYPES_AND_UOMS_FAILURE',
                payload:
                    error.message || 'Failed to fetch product types and UOMs.',
            });
        }
    };
}

export function getProductTypes(workTypeId) {
    return async (dispatch) => {
        dispatch({ type: 'GET_PRODUCT_TYPES_REQUEST' });
        try {
            const response = await getProductTypesApi(workTypeId);
            dispatch({
                type: 'GET_PRODUCT_TYPES_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'GET_PRODUCT_TYPES_FAILURE',
                payload: error.message || 'Failed to fetch product types.',
            });
        }
    };
}

export function getVasProductById(id) {
    return async (dispatch) => {
        dispatch({ type: 'GET_VAS_PRODUCT_BY_ID_REQUEST' });
        try {
            const response = await getVasProductByIdApi(id);
            dispatch({
                type: 'GET_VAS_PRODUCT_BY_ID_SUCCESS',
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: 'GET_VAS_PRODUCT_BY_ID_FAILURE',
                payload: error.message || 'Failed to fetch VAS product.',
            });
        }
    };
}

// Update an existing VAS product
export function updateVasProduct(id, data) {
    return async (dispatch) => {
        try {
            // Convert pcs and targetPerformance to numbers only if they have a value.
            if (data.pcs) {
                data.pcs = Number(data.pcs);
            }
            if (data.targetPerformance) {
                data.targetPerformance = Number(data.targetPerformance);
            }

            const response = await updateVasProductApi(id, data);

            dispatch({
                type: 'UPDATE_VAS_PRODUCT_SUCCESS',
                payload: response,
            });
            return response;
        } catch (error) {
            let errors = { general: 'Failed to update VAS product.' };

            if (error.response && error.response.data) {
                const errorData = error.response.data;

                if (Array.isArray(errorData.message)) {
                    errors = {};
                    errorData.message.forEach((msg) => {
                        const lowerMsg = msg.toLowerCase();
                        if (lowerMsg.includes('product code')) {
                            errors.productCode = msg;
                        }
                        if (lowerMsg.includes('uom')) {
                            errors.uom = msg;
                        }
                        if (lowerMsg.includes('pcs')) {
                            errors.pcs = msg;
                        }
                        if (lowerMsg.includes('producttype')) {
                            errors.productType = msg;
                        }
                        if (
                            lowerMsg.includes('principalname') ||
                            lowerMsg.includes('principal name')
                        ) {
                            errors.principalName = msg;
                        }
                        if (lowerMsg.includes('targetPerformance')) {
                            errors.targetPerformance = msg;
                        }
                        if (
                            lowerMsg.includes('worktypeid') ||
                            lowerMsg.includes('work type id')
                        ) {
                            errors.workTypeId = msg;
                        }
                        // Add other field checks as needed
                    });
                } else if (typeof errorData.message === 'string') {
                    errors.general = errorData.message;
                }
            }

            dispatch({
                type: 'UPDATE_VAS_PRODUCT_FAILURE',
                payload: errors.general,
            });
            throw errors;
        }
    };
}
