import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../actions/userActions';
import { setMessage } from '../../actions/notificationActions';
import { useTranslation } from 'react-i18next';

// Material UI imports
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Styled components
const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const LoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async event => {
    event.preventDefault();
    setIsLoading(true);

    dispatch(userLogin(email.toLowerCase(), password))
      .catch(err => {
        console.error('exc', err);
        dispatch(
          setMessage(
            t('login.wrongCredentials', 'Wrong username or password'),
            'error',
            5
          )
        );
        setPassword('');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledRoot>
      <Card
        sx={{
          width: '100%',
          maxWidth: { xs: '95%', sm: 450, md: 500 },
          boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
          p: { xs: 3, sm: 4 },
          borderRadius: 2,
        }}
      >
        <Stack spacing={2} alignItems="center" mb={4}>
          <Typography variant="h4" fontWeight="bold">
            {t('login.header')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('login.subheader', 'Sign in to access your account')}
          </Typography>
        </Stack>

        <Box component="form" onSubmit={handleLogin} noValidate>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label={t('login.email')}
              id="email"
              value={email}
              placeholder={t('login.emailPlaceholder', 'Enter your email')}
              onChange={e => setEmail(e.target.value)}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label={t('login.password')}
              id="password"
              value={password}
              placeholder={t(
                'login.passwordPlaceholder',
                'Enter your password'
              )}
              onChange={e => setPassword(e.target.value)}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading}
            sx={{
              py: 1.5,
              mt: 3,
              mb: 3,
              fontSize: '1rem',
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
            ) : null}
            {t('login.login')}
          </Button>
        </Box>
      </Card>
    </StyledRoot>
  );
};

export default LoginForm;
