const rules = {
  en: {
    header: 'In-House Regulations',
    content: 'General rules and policies',
    downloadPdf: 'Download PDF',
    rule1: "Arrive at the workplace on time and start work on schedule. When starting work, log into the app.dth.fi application with your DTH time card and select the assigned/approved work and finish at the end of the day. Do not stamp the client's time card in advance and then go for coffee!",
    rule2: "After work is completed, change your work status to -> 'Temporary no work' and contact your nearest manager to arrange work for you.",
    rule3: "When on a break, change your work status to -> 'Break' and change it back when starting work again in the app.dth.fi application.",
    rule4: 'Correctly perform the tasks assigned and do not delegate or instruct others without approval.',
    rule5: 'When working, wear the required clothing and protective equipment, such as safety shoes (mandatory), reflective clothing (mandatory), masks (as per location regulations), gloves, and maintain a 2-meter distance during Covid times.',
    rule6: 'Keep your personal belongings in the designated/agreed place. If instructions are not followed, the company/client is not liable for lost items.',
    rule7: 'Eating and drinking are only allowed in break areas. Taking drinks to production areas is strictly forbidden. Do not drink alcohol before work and during working hours.',
    rule8: 'Do not smoke/use matches/lighters inside your workplace. Smoking is only allowed during breaks and in designated smoking areas. This applies to all client locations.',
    rule9: 'Listening to music is prohibited at the workplace. To reduce the risk of accidents and to improve concentration in work, do not listen to music at the workplace.',
    rule10: 'Do not use your phone for personal purposes while working. Inform your nearest manager if you have an urgent matter that requires your attention. Watching movies/videos during work hours is not allowed!!!',
    rule11: "Follow general rules when working in client's warehouses/facilities.",
    rule12: 'Do not use machinery or equipment without permission and guidance from a manager.',
    rule13: "Work in designated/agreed work areas during work hours. Free movement to other areas is not allowed without the client's consent.",
    rule14: "Everyone is responsible for protecting the company's and client's property. If you notice damaged/unusable items, inform your nearest manager.",
    rule15: 'Always keep the workplace clean during and after work. Waste should be sorted at the agreed place.',
    rule16: 'Inform your manager if any accidents or incidents occur in the work area, fill out accident/near-miss forms and send them directly to higher management. Report issues/discoveries in the app.dth.fi application (in progress) so your manager is aware and can prevent future hazards.',
    rule17: "Do not bring friends to the work area without upper management's consent.",
    rule18: 'Do not perform new tasks that you have not been trained/instructed for. All tasks should always be guided by trainer.',
    rule19: "If you fall ill, call/schedule an appointment (mehilainen.fi -> occupational health) to see a doctor and immediately inform your nearest DTH manager. Report the number of sick days (photo from the doctor's certificate) in the app.dth.fi application.",
    rule20: 'Clients have the right to ask you to assist in other tasks/new jobs and provide training before starting the work.',
    rule21: "Communicate politely and appropriately with clients/client's workers.",
    rule22: 'All statements related to DTH company in public media, press, social networks, etc., must be made by an authorized representative.',
    rule23: "Any criminal activities or legal violations at the workplace are not acceptable and will be subject to the host country's (Finland) legal consequences.",
    rule24: "Respect the property rights of others (including the company and clients), and do not take others' (including the company and clients) belongings out of the workplace without the manager's consent.",
  },
  fi: {
    header: 'Työsäännöt',
    content: 'Yleiset säännöt ja käytännöt',
    downloadPdf: 'Lataa PDF',
    rule1: 'Saavu työpaikalle ajoissa ja aloita työ ajallaan. Työn alkaessa kirjaudu app.dth.fi-sovellukseen DTH-ajankortillasi ja valitse määrätty/hyväksytty työ ja lopeta työ päivän päätteeksi. Älä leimaa asiakkaan aikakorttia etukäteen ja mene sitten kahville!',
    rule2: 'Työn päätyttyä vaihda työtila -> "Temporary no work" ja ota yhteyttä lähimpään esimieheesi työn järjestämiseksi sinulle.',
    rule3: 'Tauolla ollessasi vaihda työtila -> "Tauko" ja vaihda se takaisin työn alkaessa app.dth.fi-sovelluksessa.',
    rule4: 'Suorita annetut työtehtävät oikein äläkä delegoi tai ohjaa muita ilman hyväksyntää.',
    rule5: 'Työskennellessäsi käytä vaadittuja vaatteita ja suojavarusteita, esim. turvakenkiä (pakolliset), heijastavia vaatteita (pakolliset), maskeja (paikan määräysten mukaan), käsineitä ja pidä 2 metrin etäisyys - Covid-aikana.',
    rule6: 'Säilytä henkilökohtaiset tavarasi määrätyssä/sovitussa paikassa. Ohjeiden noudattamatta jättämisestä yritys/asiakas ei ole korvausvelvollinen kadonneista esineistä.',
    rule7: 'Syöminen ja juominen sallittu vain taukotiloissa. Juomien vieminen tuotantoalueille on ankarasti kielletty. Ala juo alkoholia ennen työtä ja tyaikana.',
    rule8: 'Älä polta tupakkaa/käytä tulitikkuja/sytyttimiä työpaikallasi sisätiloissa. Tupakointi sallittu vain tauoilla ja määrätyillä tupakointialueilla. Tämä koskee kaikkia asiakkaan toimipaikkoja.',
    rule9: 'Musiikin kuunteleminen on kiellettyä työpaikalla. Onnettomuusriskin vähentämiseksi ja työskentelyn keskittymisen parantamiseksi älä kuuntele musiikkia työpaikalla',
    rule10: 'Älä käytä puhelintasi henkilökohtaisiin tarkoituksiin työskennellessäsi. Ilmoita lähimmälle esimiehellesi, jos sinulla on kiireellinen asia, joka vaatii huomiotasi. Elokuvien/videoiden katsominen työaikana ei ole sallittua!!!',
    rule11: 'Noudata yleisiä sääntöjä työskennellessäsi asiakkaan varastoissa/laitoksissa.',
    rule12: 'Älä käytä koneita tai laitteita ilman luvan ja ohjeiden saamista esimieheltä.',
    rule13: 'Työskentele määritetyillä/sovituilla työalueilla työaikana. Vapaa liikkuminen muihin alueisiin ei ole sallittua ilman asiakkaan suostumusta.',
    rule14: 'Kaikkien on vastuussa yrityksen ja asiakkaan omaisuuden suojelemisesta. Jos huomaat vahingoittuneita/käyttökelvottomia tavaroita, ilmoita siitä lähimmälle esimiehellesi.',
    rule15: 'Pidä aina työpaikka siistinä työskentelyn aikana ja sen jälkeen. Jätteet on lajiteltava sovitussa paikassa.',
    rule16: 'Ilmoita esimiehellesi, jos työalueella tapahtuu onnettomuuksia tai välikohtauksia, täytä onnettomuus- /lähes onnettomuuslomakkeet ja lähetä ne suoraan ylemmälle johdolle. Raportoi app.dth.fi- sovelluksessa olevaan ”ongelmat/havainnot”-osioon (kesken), jotta esimiehesi tietää ja voi estää tulevia vaaroja.',
    rule17: 'Älä tuo ystäviäsi työalueelle ilman ylemmän johdon suostumusta.',
    rule18: 'Älä suorita uusia tehtäviä, joita et ole saanut opetusta/ohjeistusta varten. Kaikkiin tehtäviin annetaan aina ohjeistus ja koulutus kouluttajan toimesta.',
    rule19: 'Sairastuessasi soita/varaa aika (mehilainen.fi -> työterveys) lääkärille ja ilmoita heti lähimmälle DTH:n esimiehellesi. Raportoi sairauspäivien määrä (lääkärintodistuksen kuva) app.dth.fi-sovellukseen.',
    rule20: 'Asiakkaalla on oikeus pyytää sinua avustamaan muissa tehtävissä/uusissa töissä ja antamaan koulutusta ennen työhön ryhtymistä.',
    rule21: 'Kommunikoi kohteliaasti ja asianmukaisesti asiakkaiden/asiakkaan työntekijöiden kanssa.',
    rule22: 'Kaikki DTH-yritystä koskevat lausunnot julkisissa tiedotusvälineissä, lehdistössä, sosiaalisessa mediassa jne. on annettava valtuutetun edustajan toimesta.',
    rule23: 'Kaikki työpaikalla tapahtuvat rikolliset toimet tai lainrikkomukset eivät ole hyväksyttäviä ja niistä seuraa isäntämaan (Suomi) lainsäädännön mukaiset seuraamukset.',
    rule24: 'Kunnioita muiden (sekä yrityksen että asiakkaiden) omaisuusoikeuksia, äläkä ota muiden (sekä yrityksen että asiakkaiden) tavaroita työpaikalta pois ilman esimiehen suostumusta.',
  },
  vi: {
    header: 'Nội quy làm việc',
    content: 'Quy định và chính sách chung',
    downloadPdf: 'Tải PDF',
    rule1: 'Đến nơi làm việc đúng giờ và bắt đầu làm việc đúng giờ. Khi bắt đầu công việc, hãy đăng nhập vào ứng dụng app.dth.fi bằng thẻ thời gian DTH của bạn và chọn công việc đã được chỉ định/chấp nhận và kết thúc công việc vào cuối ngày. Đừng đóng dấu thẻ giờ của khách hàng trước thời gian và sau đó đi uống cà phê!',
    rule2: "Khi công việc kết thúc, hãy thay đổi trạng thái công việc thành -> 'Không có việc làm tạm thời' và liên hệ với người quản lý gần nhất của bạn để sắp xếp công việc.",
    rule3: "Khi bạn nghỉ giải lao, hãy thay đổi trạng thái công việc thành -> 'Nghỉ giải lao' và thay đổi trở lại khi bạn bắt đầu làm việc trên ứng dụng app.dth.fi.",
    rule4: 'Hoàn thành đúng các nhiệm vụ được giao và không tự ý giao việc hoặc hướng dẫn người khác mà không có sự chấp thuận.',
    rule5: 'Khi làm việc, hãy mặc quần áo và trang bị bảo hộ cần thiết, ví dụ: giày bảo hộ (bắt buộc), quần áo phản quang (bắt buộc), khẩu trang (tùy theo quy định của địa điểm), găng tay và giữ khoảng cách 2 mét - trong thời gian Covid.',
    rule6: 'Bảo quản đồ dùng cá nhân của bạn ở nơi được chỉ định/đã thỏa thuận. Nếu không tuân theo hướng dẫn, công ty/khách hàng sẽ không chịu trách nhiệm bồi thường cho đồ đạc bị mất.',
    rule7: 'Ăn uống chỉ được phép trong khu vực nghỉ giải lao. Việc đưa đồ uống vào khu vực sản xuất bị nghiêm cấm. Không uống rượu trước và trong giờ làm việc.',
    rule8: 'Không hút thuốc/sử dụng diêm/bật lửa trong khu vực làm việc của bạn. Chỉ được phép hút thuốc trong giờ nghỉ và tại những khu vực đã được chỉ định. Điều này áp dụng cho tất cả các địa điểm của khách hàng.',
    rule9: 'Nghe nhạc tại nơi làm việc là không được phép. Để giảm thiểu nguy cơ tai nạn và cải thiện sự tập trung trong công việc, đừng nghe nhạc khi làm việc.',
    rule10: 'Không sử dụng điện thoại cá nhân cho mục đích riêng trong giờ làm việc. Hãy thông báo cho người quản lý gần nhất của bạn nếu có vấn đề khẩn cấp cần giải quyết. Xem phim/video trong giờ làm việc là không được phép!!!',
    rule11: 'Tuân theo các quy tắc chung khi làm việc tại các kho của khách hàng hoặc cơ sở của họ.',
    rule12: 'Không sử dụng máy móc, thiết bị mà không có sự cho phép và hướng dẫn của người quản lý.',
    rule13: 'Làm việc trong khu vực đã được chỉ định/đã thỏa thuận trong giờ làm việc. Không được tự do di chuyển đến các khu vực khác mà không có sự đồng ý của khách hàng.',
    rule14: 'Mỗi người có trách nhiệm bảo vệ tài sản của công ty và hàng hóa của khách hàng. Nếu bạn phát hiện hàng hóa bị hỏng hoặc không sử dụng được, vui lòng thông báo cho người quản lý gần nhất của bạn.',
    rule15: 'Luôn giữ cho nơi làm việc sạch sẽ trong và sau giờ làm việc. Rác thải phải được phân loại tại nơi đã thỏa thuận.',
    rule16: 'Thông báo cho người quản lý của bạn nếu có tai nạn hoặc sự cố nào xảy ra tại khu vực làm việc, điền vào biểu mẫu tai nạn/gần tai nạn và gửi trực tiếp cho cấp trên. Báo cáo các vấn đề/phát hiện trong phần mềm app.dth.fi (đang trong quá trình hoàn thiện) để người quản lý biết và ngăn chặn rủi ro trong tương lai.',
    rule17: 'Không được tự ý mang bạn bè đến khu vực làm việc nếu không có sự đồng ý của cấp trên.',
    rule18: 'Không thực hiện công việc mới mà bạn chưa được đào tạo/hướng dẫn. Mọi công việc đều phải được hướng dẫn và đào tạo bởi một huấn luyện viên.',
    rule19: 'Khi ốm, hãy gọi/đặt lịch hẹn (mehilainen.fi -> sức khỏe nghề nghiệp) để khám bác sĩ và thông báo ngay cho người quản lý DTH gần nhất của bạn. Báo cáo số ngày nghỉ ốm (hình ảnh từ giấy chứng nhận của bác sĩ) lên phần mềm app.dth.fi',
    rule20: 'Khách hàng có quyền yêu cầu bạn tham gia hỗ trợ ở các khu vực khác/công việc mới và cung cấp đào tạo trước khi bạn bắt đầu công việc.',
    rule21: 'Giao tiếp lịch sự và phù hợp với khách hàng và nhân viên của họ.',
    rule22: 'Mọi phát ngôn liên quan đến công ty DTH trên các phương tiện thông tin đại chúng, báo chí, mạng xã hội, v.v. phải được thực hiện bởi người có thẩm quyền phát ngôn.',
    rule23: 'Mọi hoạt động phạm tội hoặc vi phạm pháp luật tại nơi làm việc đều không được chấp nhận và sẽ phải chịu sự chế tài của pháp luật nước sở tại (Phần Lan).',
    rule24: 'Tôn trọng quyền sở hữu của người khác (bao gồm cả công ty và khách hàng), không tự ý lấy đồ đạc của người khác (bao gồm cả của công ty và khách hàng) ra khỏi nơi làm việc nếu không có sự đồng ý của người quản lý.',
  }
};

export default rules;