import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import Quagga from 'quagga';

import { addBoxes } from '../../../actions/vasActivityActions';
import { getPerformanceBackgroundColor } from './utils/timerUtils';
import useDelayCountdown from './hooks/useDelayCountdown';

// Component imports
import ProductHeader from './components/ProductHeader';
import ProductImage from './components/ProductImage';
import TimerDisplay from './components/TimerDisplay';
import StatisticsDisplay from './components/StatisticsDisplay';
import ActionButtons from './components/ActionButtons';
import BarcodeScannerDialog from './components/BarcodeScannerDialog';

/**
 * SelectedProductView component displays the currently selected product and its performance data
 * @param {Object} props - Component props
 * @param {Function} props.onDeselect - Function to call when product is deselected
 */
const SelectedProductView = ({ onDeselect }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Local state
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [finalizeDialogOpen, setFinalizeDialogOpen] = useState(false);
  const [scannerActive, setScannerActive] = useState(false);
  const [scannedData, setScannedData] = useState('');
  const [scanError, setScanError] = useState(null);

  // Reference to scanner container
  const scannerRef = useRef(null);

  // Custom hook to manage delay countdown
  const { isDelay, delayCountdown, startDelay } = useDelayCountdown(5);

  // Redux state slices
  const { activeShiftActivity, shiftActivity, shiftActivities } = useSelector(
    (state) => state.vasWorkLog,
  );
  const { vasProducts } = useSelector((state) => state.vasProducts);
  const user = useSelector((state) => state.user);

  // Update current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Get the product details for the active shift activity
  const product = useMemo(() => {
    if (activeShiftActivity && vasProducts) {
      return vasProducts.find((p) => p.id === activeShiftActivity.productId) || null;
    }
    return null;
  }, [activeShiftActivity, vasProducts]);

  // Calculate total boxes completed across all shift activities
  const totalBoxesCompleted = useMemo(() => {
    return shiftActivities
      ? shiftActivities.reduce((total, activity) => total + (activity.boxesCompleted || 0), 0)
      : 0;
  }, [shiftActivities]);

  // Calculate average performance with real-time updates
  const avgPerformance = useMemo(() => {
    if (!shiftActivities || shiftActivities.length === 0 || !product) {
      return 0;
    }

    // Create a working copy of the activities
    const workingActivities = [...shiftActivities];

    // Find the active activity (one without an endTime)
    const activeActivity = workingActivities.find((activity) => !activity.endTime);

    // For active activities, calculate duration up to current time
    if (activeActivity) {
      const startTimeMs = new Date(activeActivity.startTime).getTime();
      const durationMs = currentTime - startTimeMs;
      const durationSeconds = Math.max(0, durationMs / 1000); // Ensure non-negative

      // Calculate performance for active activity: (boxes * pcs) / hours
      const totalPieces = activeActivity.boxesCompleted * (product?.pcs || 1);
      const hoursElapsed = durationSeconds / 3600; // convert seconds to hours
      const currentPerformance = hoursElapsed > 0 ? Math.round(totalPieces / hoursElapsed) : 0;

      // Add live calculations to working copy
      activeActivity.liveCalcDuration = durationSeconds;
      activeActivity.liveCalcPerformance = currentPerformance;
    }

    // Calculate weighted average performance
    const { totalWeightedPerformance, totalDuration } = workingActivities.reduce(
      (acc, activity) => {
        // Use live calculation for active activity, otherwise use stored values
        const duration = activity.liveCalcDuration || activity.duration || 0;
        const performance = activity.liveCalcPerformance || activity.performance || 0;

        if (duration > 0) {
          acc.totalWeightedPerformance += performance * duration;
          acc.totalDuration += duration;
        }
        return acc;
      },
      { totalWeightedPerformance: 0, totalDuration: 0 },
    );

    return totalDuration > 0 ? (totalWeightedPerformance / totalDuration).toFixed(1) : 0;
  }, [shiftActivities, currentTime, product]);

  // Get the start time for the current activity
  const startTime = useMemo(() => {
    if (!activeShiftActivity) return new Date();

    return activeShiftActivity.lastBoxAddedAt
      ? new Date(activeShiftActivity.lastBoxAddedAt)
      : new Date(activeShiftActivity.startTime);
  }, [activeShiftActivity]);

  // Handle adding a box - dispatches the Redux action and starts delay
  const handleAddBox = useCallback(async () => {
    if (!shiftActivity?.shiftId) return;

    setIsLoading(true);
    try {
      await dispatch(addBoxes(shiftActivity.shiftId, { boxesCompleted: 1 }));
      startDelay();
    } catch (error) {
      console.error('Error adding box:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, shiftActivity, startDelay]);

  // Handle opening the finalize dialog
  const openFinalizeDialog = useCallback(() => {
    // Reset scanned data when opening the dialog
    setScannedData('');
    setScanError(null);
    setScannerActive(false);
    setFinalizeDialogOpen(true);
  }, []);

  // Handle toggling the scanner
  const toggleScanner = useCallback((isActive) => {
    setScannerActive(isActive);
  }, []);

  // Handle finalizing with barcode data
  const handleFinalize = useCallback(
    async (barcodeData) => {
      if (!shiftActivity?.shiftId) return;

      setIsLoading(true);
      try {
        // Send the barcode data along with the request
        await dispatch(
          addBoxes(shiftActivity.shiftId, {
            boxesCompleted: 1,
            barcodeData: barcodeData,
          }),
        );
        startDelay();
      } catch (error) {
        console.error('Error finalizing with barcode:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, shiftActivity, startDelay],
  );

  // Initialize and cleanup QuaggaJS
  useEffect(() => {
    if (scannerActive && scannerRef.current) {
      // Clear any previous errors
      setScanError(null);

      // Add scanner initialization message
      console.log('Initializing QuaggaJS scanner...');

      // Store permission state in localStorage to remember user choices
      const checkAndRequestPermission = async () => {
        try {
          // Check if we've already asked for permission
          const permissionState = localStorage.getItem('cameraPermissionRequested');

          // If we've never asked before or need to ask again
          if (!permissionState) {
            console.log('Requesting camera permission...');

            // Using the Permissions API to request camera access (when supported)
            if (navigator.permissions && navigator.permissions.query) {
              const result = await navigator.permissions.query({ name: 'camera' });

              // Mark that we've requested permission
              localStorage.setItem('cameraPermissionRequested', 'true');

              if (result.state === 'denied') {
                setScanError(
                  'Camera permission was denied. Please enable camera access in your browser settings.',
                );
                return false;
              }
            } else {
              // For browsers that don't support the Permissions API
              // We'll still try to access the camera which will trigger a permission prompt
              localStorage.setItem('cameraPermissionRequested', 'true');
            }
          }
          return true;
        } catch (err) {
          console.error('Error checking camera permission:', err);
          return true; // Still try to initialize camera
        }
      };

      // Check permission then initialize Quagga
      checkAndRequestPermission().then((canProceed) => {
        if (!canProceed) return;

        // Initialize Quagga when scanner is opened
        Quagga.init(
          {
            inputStream: {
              name: 'Live',
              type: 'LiveStream',
              target: scannerRef.current,
              constraints: {
                width: { ideal: 1280 },
                height: { ideal: 720 },
                facingMode: 'environment', // Use back camera on mobile devices
              },
              area: {
                // Only consider the center 80% of the video feed for scanning
                top: '10%',
                right: '10%',
                left: '10%',
                bottom: '10%',
              },
              willReadFrequently: true,
            },
            locator: {
              patchSize: 'medium',
              halfSample: true,
            },
            numOfWorkers: 1, // Reduce workers to avoid performance issues
            frequency: 10,
            decoder: {
              readers: ['code_39_reader'], // Specifically for CODE-39 barcodes
              debug: {
                drawBoundingBox: true,
                showFrequency: true,
                drawScanline: true,
                showPattern: true,
              },
            },
            locate: true,
          },
          function (err) {
            if (err) {
              console.error('QuaggaJS initialization error:', err);
              setScanError(
                `Scanner initialization error: ${err.message}. Please ensure camera permissions are granted.`,
              );
              return;
            }

            console.log('QuaggaJS started successfully');

            // Start scanning after a short delay to allow camera to initialize
            setTimeout(() => {
              Quagga.start();
            }, 500);
          },
        );
      });

      // Add debugging to see processed frames
      Quagga.onProcessed(function (result) {
        if (!result) return;

        const drawingCtx = Quagga.canvas.ctx.overlay;
        const drawingCanvas = Quagga.canvas.dom.overlay;

        if (!drawingCtx || !drawingCanvas) return;

        try {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width')),
            parseInt(drawingCanvas.getAttribute('height')),
          );

          if (result.boxes) {
            result.boxes
              .filter(function (box) {
                return box !== result.box;
              })
              .forEach(function (box) {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                  color: 'green',
                  lineWidth: 2,
                });
              });
          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
              color: 'blue',
              lineWidth: 2,
            });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, {
              color: 'red',
              lineWidth: 3,
            });
          }
        } catch (e) {
          console.error('Error in onProcessed:', e);
        }
      });

      // Handle detected barcodes
      Quagga.onDetected((result) => {
        console.log('Barcode detected:', result.codeResult.code);

        if (result && result.codeResult && result.codeResult.code) {
          const code = result.codeResult.code;
          setScannedData(code);
          setScannerActive(false);

          // Stop scanner after successful scan
          Quagga.stop();
        }
      });

      return () => {
        // Cleanup when component unmounts or dialog closes
        console.log('Stopping QuaggaJS...');
        Quagga.offDetected();
        Quagga.offProcessed();
        Quagga.stop();
      };
    }
  }, [scannerActive]);

  // Determine performance background color based on current performance and target
  const performanceBackgroundColor = useMemo(() => {
    return getPerformanceBackgroundColor(
      parseFloat(avgPerformance),
      product?.targetPerformance,
      totalBoxesCompleted,
    );
  }, [avgPerformance, product, totalBoxesCompleted]);

  // Determine performance status text
  const performanceStatusText = useMemo(() => {
    if (!product?.targetPerformance || product.targetPerformance <= 0) return '';

    const performanceRatio = parseFloat(avgPerformance) / product.targetPerformance;

    return performanceRatio < 1
      ? t('selectedProduct.underTarget')
      : t('selectedProduct.targetReached');
  }, [avgPerformance, product, t]);

  // If product or activeShiftActivity isn't loaded yet, show loading
  if (!product || !activeShiftActivity) {
    return <Typography>{t('selectedProduct.loading')}</Typography>;
  }

  return (
    <Box
      sx={{
        p: 3,
        mt: 2,
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: 1,
        bgcolor: 'background.paper',
      }}
    >
      {/* Header: Product info and switch button */}
      <ProductHeader product={product} onDeselect={onDeselect} />

      {/* Timer */}
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        {/* Product Image */}
        <ProductImage product={product} />

        {/* Timer and Barcode Display */}
        <TimerDisplay currentTime={currentTime} startTime={startTime} />
      </Box>

      {/* Statistics */}
      <StatisticsDisplay
        totalBoxesCompleted={totalBoxesCompleted}
        avgPerformance={avgPerformance}
        performanceBackgroundColor={performanceBackgroundColor}
        performanceStatusText={performanceStatusText}
        product={product}
      />

      {/* Add Box and Finalize buttons */}
      <ActionButtons
        handleAddBox={handleAddBox}
        openFinalizeDialog={openFinalizeDialog}
        isLoading={isLoading}
        isDelay={isDelay}
        delayCountdown={delayCountdown}
        user={user}
      />

      {/* Finalize Dialog with Barcode Scanner */}
      <BarcodeScannerDialog
        open={finalizeDialogOpen}
        onClose={() => setFinalizeDialogOpen(false)}
        scanError={scanError}
        scannerRef={scannerRef}
        scannedData={scannedData}
        handleSubmit={handleFinalize}
        toggleScanner={toggleScanner}
        selectedProduct={product}
      />
    </Box>
  );
};

SelectedProductView.propTypes = {
  onDeselect: PropTypes.func.isRequired,
};

export default SelectedProductView;
