const report = {
  en: {
    from: 'From',
    to: 'To',
    all: 'All',
    search: 'Search',
    date: 'Date',
    notFound: 'Not Found',
    noDataAvailable: 'No data available',
    startWorking: 'Start',
    stopWorking: 'End',
    warehouse: 'Warehouse',
    principal: 'Principal',
    task: 'Task',
    workType: 'Work Type',
    workTypeStart: 'Start',
    workTypeEnd: 'End',
    workingTime: 'Working Time (h:m)',
    summary: 'Summary',
    total: 'Total',
    totalWorkingTime: 'Total Working Time',
    workingHours: 'Working Hours',
    employee: 'Employee',
    checkInTime: 'Check In Time',
    checkOutTime: 'Check Out Time',
    report: 'Interval Report',
    calendar: 'Calendars',
    box: 'Boxes',
    performance: 'Performance',
    totalHours: 'Total Hours',
    workTypeSummary: 'Work Type Summary',
    // New VAS report translations
    productCode: 'Product Code',
    pieceCount: 'Piece Count',
    productType: 'Product Type',
    totalProducts: 'Total Products',
    totalBoxes: 'Total Boxes',
    totalPieces: 'Total Pieces',
    averagePerformance: 'Average Performance',
    // VAS report tab labels
    productLevel: 'Product Level',
    productTypeLevel: 'Product Type Level',
  },
  fi: {
    from: 'Alkaen',
    to: 'Päättyen',
    all: 'Kaikki',
    search: 'Hae',
    date: 'Päivämäärä',
    notFound: 'Haku kriteereillä ei löytynyt',
    noDataAvailable: 'Ei tietoja saatavilla',
    startWorking: 'Aloitus',
    stopWorking: 'Lopetus',
    warehouse: 'Kustannuspaikka',
    principal: 'Päämies',
    task: 'Työ',
    workType: 'Työlaji',
    workTypeStart: 'Työn aloitus',
    workTypeEnd: 'Työn lopetus',
    workingTime: 'Työn kesto (h:m)',
    summary: 'Yhteenveto',
    total: 'yht.',
    totalWorkingTime: 'Työpäivän pituus',
    workingHours: 'Työtunnit',
    employee: 'Työntekijä',
    checkInTime: 'Kirjautumisaika',
    checkOutTime: 'Lopetusaika',
    report: 'Aikaväli Raportti',
    calendar: 'Kalenteria',
    box: 'Laatikkoa',
    performance: 'Työsuoritus',
    totalHours: 'Työtunnit',
    workTypeSummary: 'Työlajien yhteenveto',
    // New VAS report translations
    productCode: 'Tuotekoodi',
    pieceCount: 'Kappalemäärä',
    productType: 'Tuotetyyppi',
    totalProducts: 'Tuotteet yhteensä',
    totalBoxes: 'Laatikot yhteensä',
    totalPieces: 'Kappaleet yhteensä',
    averagePerformance: 'Keskimääräinen teho',
    // VAS report tab labels
    productLevel: 'Tuotetaso',
    productTypeLevel: 'Tuotetyyppi taso',
  },
  vi: {
    from: 'Từ',
    to: 'Đến',
    all: 'Tất cả',
    search: 'Tìm',
    date: 'Ngày',
    notFound: 'Không tìm thấy',
    noDataAvailable: 'Không có dữ liệu',
    startWorking: 'Bắt đầu',
    stopWorking: 'Kết thúc',
    warehouse: 'Kho',
    principal: 'Khách hàng',
    task: 'Công việc',
    workType: 'Loại công việc',
    workTypeStart: 'Bắt đầu',
    workTypeEnd: 'Kết thúc',
    workingTime: 'Thời gian làm việc (h:m)',
    summary: 'Tổng hợp',
    total: 'tổng',
    totalWorkingTime: 'Tổng thời gian làm việc',
    workingHours: 'Giờ làm việc',
    employee: 'Nhân viên',
    checkInTime: 'Thời gian vào',
    checkOutTime: 'Thời gian ra',
    report: 'Báo cáo',
    calendar: 'Cái lịch',
    box: 'cái hộp',
    performance: 'Hiệu suất',
    totalHours: 'Tổng giờ',
    workTypeSummary: 'Tổng hợp loại công việc',
    // New VAS report translations
    productCode: 'Mã sản phẩm',
    pieceCount: 'Số lượng',
    productType: 'Loại sản phẩm',
    totalProducts: 'Tổng số sản phẩm',
    totalBoxes: 'Tổng số hộp',
    totalPieces: 'Tổng số lượng',
    averagePerformance: 'Hiệu suất trung bình',
    // VAS report tab labels
    productLevel: 'Mức sản phẩm',
    productTypeLevel: 'Mức loại sản phẩm',
  },
};

export default report;
