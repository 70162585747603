import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Cookies from 'universal-cookie';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Typography,
} from '@mui/material';
import { getPrincipalsTasks, getWarehouse, getWarehousePrincipals } from '../../../actions';
import { getProductTypes } from '../../../actions/vasProductActions';

// Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LiveStatsMonitor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookies = useMemo(() => new Cookies(), []);

  // State management
  const [filters, setFilters] = useState({
    warehouseId: null,
    principalId: null,
    workTypeId: null,
    productTypeId: null,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    warehouseId: null,
    principalId: null,
    workTypeId: null,
    productTypeId: null,
  });
  const [filterApplied, setFilterApplied] = useState(false);
  const [liveStats, setLiveStats] = useState([]);
  const [previousDays, setPreviousDays] = useState([]);
  const [liveAvgPerformance, setLiveAvgPerformance] = useState(null);
  const [totalCompletedBoxes, setTotalCompletedBoxes] = useState(null);
  const [totalHours, setTotalHours] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  // Selectors
  const warehouses = useSelector((state) => state.warehouses || []);
  const principals = useSelector((state) => state.principal || []);
  const workTypes = useSelector((state) => state.principalTasks || []);
  const productTypes = useSelector((state) => state.vasProducts.productTypes || []);

  // Load warehouses on component mount
  useEffect(() => {
    dispatch(getWarehouse());
  }, [dispatch]);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => {
      const newFilters = { ...prev, [name]: value };
      // Reset dependent filters when parent filter changes
      if (name === 'warehouseId') {
        newFilters.principalId = null;
        newFilters.workTypeId = null;
        newFilters.productTypeId = null;
      }
      if (name === 'principalId') {
        newFilters.workTypeId = null;
        newFilters.productTypeId = null;
      }
      if (name === 'workTypeId') {
        newFilters.productTypeId = null;
      }
      return newFilters;
    });
  };

  // Function to remove all event listeners from socket
  const removeSocketEventListeners = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.off('connect');
      socketRef.current.off('connect_error');
      socketRef.current.off('disconnect');
      socketRef.current.off('error');
      socketRef.current.off('statsUpdate');
    }
  }, []);

  // Setup socket connection
  const setupSocketConnection = useCallback(
    (filterData) => {
      setIsLoading(true);
      setError(null);

      // If there's an existing socket, remove all listeners first
      if (socketRef.current) {
        removeSocketEventListeners();
      }

      // Create new socket connection if it doesn't exist
      if (!socketRef.current) {
        const socketUrl = `${
          process.env.REACT_APP_NEW_BACKEND_URL || 'http://localhost:3001'
        }/vas/report/live-summary`;

        const token = cookies.get('rsid');
        socketRef.current = io(socketUrl, {
          auth: { token },
          reconnectionAttempts: 5,
          timeout: 10000,
        });
      }

      // Socket event handlers
      socketRef.current.on('connect', () => {
        console.log('Socket connected! ID:', socketRef.current.id);
        setIsConnected(true);
        setReconnectAttempts(0);
        setIsLoading(false);
        socketRef.current.emit('subscribe', filterData);
      });

      socketRef.current.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
        setError(t('common.connectionError'));
        setIsLoading(false);

        // Attempt to reconnect
        if (reconnectAttempts < 3) {
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          reconnectTimeoutRef.current = setTimeout(() => {
            setReconnectAttempts((prev) => prev + 1);
            if (socketRef.current) {
              socketRef.current.connect();
            }
          }, 5000);
        }
      });

      socketRef.current.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
        setIsConnected(false);
        if (reason === 'io server disconnect') {
          // Server initiated disconnect, attempt to reconnect
          socketRef.current.connect();
        }
      });

      socketRef.current.on('error', (errorMsg) => {
        console.error('Socket error:', errorMsg);
        setError(errorMsg || t('common.socketError'));
        setIsLoading(false);
      });

      socketRef.current.on('statsUpdate', (data) => {
        console.log('Received stats update:', data);
        if (data.previousday) setPreviousDays(data.previousday);
        if (data.live) setLiveStats(data.live);
        if (data.liveAvgPerformance !== undefined) {
          setLiveAvgPerformance(data.liveAvgPerformance);
        }
        if (data.totalCompletedBoxes !== undefined) {
          setTotalCompletedBoxes(data.totalCompletedBoxes);
        }
        if (data.totalHours !== undefined) {
          setTotalHours(data.totalHours);
        }
        setIsLoading(false);
      });

      // Use existing socket connection
      if (socketRef.current.connected) {
        socketRef.current.emit('subscribe', filterData);
        setIsLoading(false);
      } else {
        socketRef.current.connect();
      }
    },
    [t, reconnectAttempts, removeSocketEventListeners, cookies],
  );

  // Handle filter submission
  const handleFilterSubmit = useCallback(() => {
    const newFilterData = {
      warehouseId: filters.warehouseId ? Number(filters.warehouseId) : undefined,
      principalId: filters.principalId ? Number(filters.principalId) : undefined,
      workTypeId: filters.workTypeId ? Number(filters.workTypeId) : undefined,
      productTypeId: filters.productTypeId ? Number(filters.productTypeId) : undefined,
    };

    // Unsubscribe from the previous subscription if one exists.
    // (Only unsubscribe if filters were applied previously.)
    if (socketRef.current && socketRef.current.connected && filterApplied) {
      socketRef.current.emit('unsubscribe', appliedFilters);
    }

    // Update applied filters with the new filters.
    setAppliedFilters(newFilterData);
    setFilterApplied(true);

    // Reset data
    setLiveStats([]);
    setPreviousDays([]);
    setLiveAvgPerformance(null);
    setTotalCompletedBoxes(null);
    setTotalHours(null);

    // Set up new subscription using new filters.
    setupSocketConnection(newFilterData);
  }, [filters, appliedFilters, filterApplied, setupSocketConnection]);

  // Load principals when warehouse changes
  useEffect(() => {
    if (filters.warehouseId) {
      dispatch(getWarehousePrincipals(Number(filters.warehouseId)));
    }
  }, [filters.warehouseId, dispatch]);

  // Load tasks when principal changes
  useEffect(() => {
    if (filters.principalId) {
      dispatch(
        getPrincipalsTasks(
          filters.warehouseId ? Number(filters.warehouseId) : undefined,
          Number(filters.principalId),
        ),
      );
    }
  }, [filters.principalId, filters.warehouseId, dispatch]);

  // Load product types when work type changes
  useEffect(() => {
    if (filters.workTypeId) {
      dispatch(getProductTypes(Number(filters.workTypeId)));
    }
  }, [filters.workTypeId, dispatch]);

  // Cleanup socket connection on component unmount
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        removeSocketEventListeners();
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [removeSocketEventListeners]);

  // Prepare chart data
  const sortedPreviousDays = [...previousDays].sort((a, b) => new Date(a.day) - new Date(b.day));

  const chartData = {
    labels: sortedPreviousDays.map((dayData) => {
      const date = new Date(dayData.day);
      return date.toLocaleDateString();
    }),
    datasets: [
      {
        label: t('liveStatsMonitor.dayAverage'),
        data: sortedPreviousDays.map((dayData) => dayData.dayAverage),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: t('liveStatsMonitor.chartTitle'),
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: t('common.performanceUnit'),
        },
      },
      x: {
        title: {
          display: true,
          text: t('common.date'),
        },
      },
    },
  };

  // Top performers, sorted by performance
  const topLiveStats = [...liveStats].sort((a, b) => b.performance - a.performance).slice(0, 3);

  // Form is complete when all filters have values
  const isFormComplete =
    filters.warehouseId && filters.principalId && filters.workTypeId && filters.productTypeId;

  return (
    <Box sx={{ margin: '20px' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        {t('liveStatsMonitor.header')}
      </Typography>

      {/* Filters form */}
      <Box component="form" noValidate autoComplete="off" sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="warehouse-label">{t('common.selectWarehouse')}</InputLabel>
              <Select
                labelId="warehouse-label"
                name="warehouseId"
                value={filters.warehouseId || ''}
                label={t('common.selectWarehouse')}
                onChange={handleFilterChange}
              >
                {warehouses.map((wh) => (
                  <MenuItem key={wh.id} value={wh.id}>
                    {wh.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="principal-label">{t('common.selectPrincipal')}</InputLabel>
              <Select
                labelId="principal-label"
                name="principalId"
                value={filters.principalId || ''}
                label={t('common.selectPrincipal')}
                onChange={handleFilterChange}
                disabled={!filters.warehouseId}
              >
                {principals.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="worktype-label">{t('common.selectWorktype')}</InputLabel>
              <Select
                labelId="worktype-label"
                name="workTypeId"
                value={filters.workTypeId || ''}
                label={t('common.selectWorktype')}
                onChange={handleFilterChange}
                disabled={!filters.principalId}
              >
                {workTypes.map((wt) => (
                  <MenuItem key={wt.id} value={wt.id}>
                    {wt.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="producttype-label">
                {t('common.selectProductType') || 'Product Type'}
              </InputLabel>
              <Select
                labelId="producttype-label"
                name="productTypeId"
                value={filters.productTypeId || ''}
                label={t('common.selectProductType') || 'Product Type'}
                onChange={handleFilterChange}
                disabled={!filters.workTypeId}
              >
                {productTypes.length > 0 ? (
                  productTypes.map((pt) => (
                    <MenuItem key={pt.id} value={pt.id}>
                      {pt.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value="">
                    {filters.workTypeId
                      ? t('liveStatsMonitor.noProductTypes')
                      : t('common.selectWorktype')}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={!isFormComplete || isLoading}
              onClick={handleFilterSubmit}
              startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
            >
              {isLoading ? t('common.loading') : t('common.show')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Error message */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Main content */}
      {filterApplied ? (
        isConnected &&
        (liveAvgPerformance !== null || previousDays.length > 0 || liveStats.length > 0) ? (
          <>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                mb: 3,
                p: 2,
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
                boxShadow: 1,
                backgroundColor: 'background.paper',
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                {t('liveStatsMonitor.avgPerformanceTitle')}
                {appliedFilters.warehouseId && (
                  <Typography variant="subtitle1" component="div" sx={{ mt: 1 }}>
                    {warehouses.find((w) => w.id === Number(appliedFilters.warehouseId))?.name}
                    {appliedFilters.principalId &&
                      ` - ${
                        principals.find((p) => p.id === Number(appliedFilters.principalId))?.name
                      }`}
                    {appliedFilters.workTypeId &&
                      ` - ${
                        workTypes.find((w) => w.id === Number(appliedFilters.workTypeId))?.title
                      }`}
                    {appliedFilters.productTypeId &&
                      ` - ${
                        productTypes.find((p) => p.id === Number(appliedFilters.productTypeId))
                          ?.name
                      }`}
                  </Typography>
                )}
              </Typography>
              <Typography
                variant="h2"
                component="h1"
                color="primary"
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '2.5rem',
                    sm: '3.5rem',
                    md: '4rem',
                  },
                }}
              >
                {liveAvgPerformance !== null ? liveAvgPerformance : t('liveStatsMonitor.na')} kpl/h
              </Typography>

              {/* Additional metrics */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2, boxShadow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {t('liveStatsMonitor.totalBoxes', { defaultValue: 'Total Completed Boxes' })}
                    </Typography>
                    <Typography variant="h4" color="secondary.main" fontWeight="bold">
                      {totalCompletedBoxes !== null
                        ? totalCompletedBoxes
                        : t('liveStatsMonitor.na')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ p: 2, border: '1px solid #eee', borderRadius: 2, boxShadow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {t('liveStatsMonitor.totalHours', { defaultValue: 'Total Hours' })}
                    </Typography>
                    <Typography variant="h4" color="secondary.main" fontWeight="bold">
                      {totalHours !== null ? totalHours : t('liveStatsMonitor.na')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Grid layout for chart and live stats */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    p: 2,
                    border: '1px solid #eee',
                    borderRadius: 2,
                    boxShadow: 1,
                    backgroundColor: 'background.paper',
                    height: '400px',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {t('liveStatsMonitor.chartSubtitle')}
                  </Typography>
                  {previousDays.length > 0 ? (
                    <Box sx={{ height: 'calc(100% - 40px)' }}>
                      <Bar data={chartData} options={chartOptions} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100% - 40px)',
                      }}
                    >
                      <Typography>{t('liveStatsMonitor.noPreviousData')}</Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: 2,
                    border: '1px solid #eee',
                    borderRadius: 2,
                    boxShadow: 1,
                    backgroundColor: 'background.paper',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {t('liveStatsMonitor.topPerformances')}
                  </Typography>
                  {topLiveStats.length > 0 ? (
                    topLiveStats.map((stat, index) => (
                      <Box
                        key={index}
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: 1,
                          margin: '10px 0',
                          padding: '10px',
                          backgroundColor: index === 0 ? 'rgba(255, 215, 0, 0.1)' : 'transparent',
                          boxShadow: 1,
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          {t('liveStatsMonitor.topLabel', {
                            index: index + 1,
                          })}{' '}
                          <Box component="span" color="primary.main">
                            {stat.performance} kpl/h
                          </Box>
                        </Typography>
                        {stat.name && <Typography variant="body2">{stat.name}</Typography>}
                      </Box>
                    ))
                  ) : (
                    <Alert severity="info">{t('liveStatsMonitor.noLiveData')}</Alert>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              textAlign: 'center',
              mt: 3,
              p: 3,
              border: '1px dashed #ccc',
              borderRadius: 2,
            }}
          >
            {isLoading ? (
              <>
                <CircularProgress size={40} sx={{ mb: 2 }} />
                <Typography>{t('common.loading')}</Typography>
              </>
            ) : (
              <Typography>{t('liveStatsMonitor.waitingConnection')}</Typography>
            )}
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default LiveStatsMonitor;
