import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

import { getWarehouse } from '../../../actions/warehouseActions';
import { getWarehousePrincipals, getPrincipalsTasks } from '../../../actions';
import { internalReportSearch } from '../../../actions/reportAction';
import { sortObject } from '../../HelperFunctions/Others';
import ReportSearchForm from '../../report/ReportSearchForm';
import RenderReportSection from './Internal/RenderReportSection';
import Page from '../../Page';
import HeaderBreadcrumbs from '../../HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';

const InternalReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { themeStretch } = useSettings();

  // Use fallback to empty arrays if Redux state is null
  const warehousesData = useSelector((state) => state.warehouses) || [];
  const principalsData = useSelector((state) => state.principal) || [];
  const tasksData = useSelector((state) => state.principalTasks) || [];

  // Load initial data only once when component mounts
  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getWarehouse());
      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded]);

  // Prepare data for the ReportSearchForm
  const formData = {
    warehouses: sortObject(warehousesData, 'name'),
    principals: sortObject(principalsData, 'name'),
    tasks: sortObject(tasksData, 'title'),
    fetchPrincipals: (warehouseId) => dispatch(getWarehousePrincipals(warehouseId)),
    fetchTasks: (warehouseId, principalId) =>
      dispatch(getPrincipalsTasks(warehouseId, principalId)),
  };

  const handleSearch = async (searchData) => {
    setLoading(true);
    try {
      await dispatch(internalReportSearch(searchData));
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page title={t('report.report')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('report.report')}
          links={[{ name: t('navigation.homepage'), href: '/' }, { name: t('report.report') }]}
        />

        <ReportSearchForm onSearch={handleSearch} data={formData} isLoading={loading} />
        <RenderReportSection loading={loading} setLoading={setLoading} />
      </Container>
    </Page>
  );
};

export default InternalReport;
