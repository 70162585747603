import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FcOvertime } from 'react-icons/fc';
import { addHours } from 'date-fns';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

import { CSVDownloadLink } from '@/utils/csvGeneration';
import {
  convertIsoToLocalDate,
  extractTime,
  calcDifferenceTimeDecimal,
  timestampToDecimal,
} from '@/utils/DateTime';
import { getWarehouseNameByID, getWorkerNameByID } from '@/utils/getName';
import NoDataCard from '@/components/common/NoDataCard';
import LoadingCard from '@/components/common/LoadingCard';
import getNewReport from '@/utils/getNewReport';
import { roundToNearestQuarter } from '@/utils/roundTime';
import { updateAssignedTime } from '@/actions/shiftActions';
import genPdf from '@/utils/pdfGeneration';
import { getPerformanceLabel } from '@/components/HelperFunctions/Others';
import { useReportFormData } from '@/hooks';

import RenderWorkTypeSummary from './RenderWorkTypeSummary';
import BillingReportHeader from './BillingReportHeader';
import aggregateWorkTypeTotalsByWarehouse from './aggregateWorkTypeTotalsByWarehouse';

const dayNames = ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'];

/**
 * Safely get worker name with error handling
 * @param {Array} workers - Array of worker objects
 * @param {string|number} workerId - ID of the worker to find
 * @returns {string} Worker name or fallback message
 */
const safeGetWorkerName = (workers, workerId) => {
  try {
    return getWorkerNameByID(workerId, workers);
  } catch (error) {
    console.error(`Error getting worker name for ID ${workerId}:`, error);
    return `Worker ${workerId}`;
  }
};

/**
 * Safely get warehouse name with error handling
 * @param {Array} warehouses - Array of warehouse objects
 * @param {string|number} warehouseId - ID of the warehouse to find
 * @returns {string} Warehouse name or fallback message
 */
const safeGetWarehouseName = (warehouses, warehouseId) => {
  try {
    return getWarehouseNameByID(warehouseId, warehouses);
  } catch (error) {
    console.error(`Error getting warehouse name for ID ${warehouseId}:`, error);
    return `Warehouse ${warehouseId}`;
  }
};

/**
 * RenderBillingReport Component
 *
 * Displays the billing report data in a structured format with options for
 * downloading as CSV or PDF, and updating assigned times.
 *
 * @param {Object} props Component props
 * @param {boolean} props.loading Indicates if data is currently loading
 * @param {Function} props.setLoading Function to set loading state
 * @returns {JSX.Element} The rendered billing report table
 */
const RenderBillingReport = ({ loading, setLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  // Get common report data using the hook
  const { loading: dataLoading, warehousesData, usersData } = useReportFormData();

  // Get billing report data from Redux store
  const billingReportState = useSelector((state) => state.billingReport);
  const legacyReportState = useSelector((state) => state.report);
  const rawPerformance = useSelector((state) => state.performance);

  // Get raw data from selectors with safety checks using useMemo
  const rawReports = useMemo(
    () => billingReportState?.timeInterval || legacyReportState?.TimeInterval || [],
    [billingReportState?.timeInterval, legacyReportState?.TimeInterval],
  );

  const searchParams = useMemo(
    () => billingReportState?.searchParams || legacyReportState?.SearchParams,
    [billingReportState?.searchParams, legacyReportState?.SearchParams],
  );

  // Check if a search has been performed
  useEffect(() => {
    if (searchParams) {
      setHasSearched(true);
    }
  }, [searchParams]);

  // Use useMemo with safety checks to ensure data is always arrays
  const workers = useMemo(
    () => (usersData && Array.isArray(usersData) ? usersData : []),
    [usersData],
  );

  const warehouses = useMemo(
    () => (warehousesData && Array.isArray(warehousesData) ? warehousesData : []),
    [warehousesData],
  );

  const performance = useMemo(
    () => (rawPerformance && Array.isArray(rawPerformance) ? rawPerformance : []),
    [rawPerformance],
  );

  // Sort and filter reports (only if we have reports)
  const sortedReports = useMemo(
    () =>
      rawReports.length > 0
        ? [...rawReports]
            .sort((a, b) => new Date(b.check_in) - new Date(a.check_in))
            .filter((e) => e.check_out !== null)
        : [],
    [rawReports],
  );

  // Process reports with getNewReport (with null safety)
  const newReports = useMemo(
    () => (sortedReports.length > 0 ? getNewReport(sortedReports, performance) : false),
    [sortedReports, performance],
  );

  // Calculate work type totals by warehouse (with null safety)
  const warehouseWorkTypeTotals = useMemo(
    () =>
      newReports && workers && warehouses
        ? aggregateWorkTypeTotalsByWarehouse(newReports, workers, warehouses)
        : false,
    [newReports, workers, warehouses],
  );

  /**
   * Calculate summary length - used for PDF generation
   * This is needed by the PDF generator even if not directly used in the component
   */
  const summaryLength = useMemo(
    () => (warehouseWorkTypeTotals ? Object.keys(warehouseWorkTypeTotals).length : 0),
    [warehouseWorkTypeTotals],
  );

  // Log summary length for debugging when it changes
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.debug('Summary length for PDF generation:', summaryLength);
    }
  }, [summaryLength]);

  // Handle PDF download
  const handleDownloadPDF = useCallback(() => {
    genPdf(newReports, searchParams, warehouses, summaryLength, setDownloadLoading);
  }, [newReports, searchParams, warehouses, summaryLength]);

  // Calculate and format assigned time for a shift
  const getAssignTime = useCallback(
    (check_in, check_out, worker_id, shift_id) => {
      // Use the roundToNearestQuarter function for precise 15-minute intervals
      const checkIn = roundToNearestQuarter(check_in);
      let checkOut = roundToNearestQuarter(check_out);

      const diffreenceTime = calcDifferenceTimeDecimal(checkIn, check_out);
      const diffHours = parseFloat(diffreenceTime?.replace(',', '.'));

      // Try to find previous assigned shifts for this worker to use as a prediction pattern
      if (worker_id && newReports) {
        const workerShifts = newReports[worker_id]?.shifts || [];

        // Look for shifts that already have assigned times set
        const assignedShifts = workerShifts
          .filter((s) => s.assigned_start && s.assigned_end && s.id !== shift_id)
          .sort((a, b) => new Date(b.check_in) - new Date(a.check_in));

        // If we have previous assigned shifts, use the most recent one's pattern
        if (assignedShifts.length > 0) {
          const recentShift = assignedShifts[0];
          const startTime = new Date(recentShift.assigned_start);

          // Get the hour and minute values
          const startHour = startTime.getHours();
          const startMinute = startTime.getMinutes();

          // Create a new date with the same hour and minute from the reference shift
          const newStartDate = new Date(checkIn);
          newStartDate.setHours(startHour);
          newStartDate.setMinutes(startMinute);

          // Calculate expected end time (8 hours from start)
          const newEndDate = new Date(newStartDate);
          newEndDate.setTime(newStartDate.getTime() + 8 * 60 * 60 * 1000);

          // Round both times to the nearest quarter hour
          const predictedCheckIn = roundToNearestQuarter(newStartDate.toISOString());
          const predictedCheckOut = roundToNearestQuarter(newEndDate.toISOString());

          // Check if the actual worked hours are less than 7.66 (allows for a lunch break)
          if (diffHours < 7.66) {
            return { checkIn: predictedCheckIn, checkOut };
          }

          // Use the predicted times without extra flag if it's exactly 8 hours
          const predictedDiff = calcDifferenceTimeDecimal(predictedCheckIn, predictedCheckOut);
          const predictedHours = parseFloat(predictedDiff?.replace(',', '.'));

          return {
            checkIn: predictedCheckIn,
            checkOut: predictedCheckOut,
            // Only mark as extra if it exceeds 8 hours
            extra: predictedHours > 8,
          };
        }
      }

      // Default logic (when no previous shifts exist)
      // Calculate a standard 8-hour shift from the check-in time
      const standardCheckOut = addHours(new Date(checkIn), 8);

      // If the actual shift is less than 7.66 hours, use the actual times
      if (diffHours < 7.66) {
        return { checkIn, checkOut };
      }

      // Round the standard 8-hour checkout to the nearest quarter hour
      const predictedCheckOut = roundToNearestQuarter(standardCheckOut.toISOString());

      // Calculate the difference between check-in and predicted check-out
      const standardDiff = calcDifferenceTimeDecimal(checkIn, predictedCheckOut);
      const standardHours = parseFloat(standardDiff?.replace(',', '.'));

      // If shift is exactly 8 hours, don't mark as extra
      return {
        checkIn,
        checkOut: predictedCheckOut,
        // Only mark as extra if it exceeds 8 hours
        extra: standardHours > 8,
      };
    },
    [newReports],
  );

  // Update assigned time for a shift
  const handleUpdateAssignedTime = useCallback(
    (times, shiftID) => {
      const checkIn = times.checkIn.slice(0, -1) + '+00:00';
      const checkOut = times.checkOut.slice(0, -1) + '+00:00';
      const extra = times.extra;

      const data = {
        start: checkIn,
        end: checkOut,
        extra,
      };

      dispatch(updateAssignedTime(data, shiftID));
    },
    [dispatch],
  );

  // Early return if data is not available
  const hasValidData =
    newReports && typeof newReports === 'object' && Object.keys(newReports).length > 0;

  // Combine all loading states
  const isLoading = loading || dataLoading || downloadLoading;

  return (
    <>
      {isLoading && (
        <LoadingCard
          message={dataLoading ? 'Loading required data...' : 'Loading report data...'}
        />
      )}
      <div className="d-flex justify-content-end">
        {hasValidData && searchParams && (
          <>
            <Button>
              <CSVDownloadLink
                newReports={newReports}
                SearchParams={searchParams}
                warehouses={warehouses}
                workers={workers}
              />
            </Button>
            <LoadingButton onClick={handleDownloadPDF} loading={downloadLoading}>
              Download PDF
            </LoadingButton>
          </>
        )}
      </div>

      <div className="table-responsive-sm">
        {newReports && searchParams && (
          <BillingReportHeader SearchParams={searchParams} newReports={newReports} />
        )}

        {hasValidData ? (
          Object.keys(newReports).map((worker, i) => {
            // Skip if worker data is invalid
            if (!newReports[worker] || !newReports[worker].shifts) {
              return null;
            }

            return (
              <React.Fragment key={`${worker}${i}`}>
                <table className="table" id={`divToPrint-${i}`}>
                  <thead className="table-info">
                    <tr>
                      <td>{t('report.employee')}</td>
                      <td>Pv.</td>
                      <td>{t('report.date')}</td>
                      <td>{t('report.warehouse')}</td>
                      <td>{t('report.startWorking')}</td>
                      <td>{t('report.stopWorking')}</td>
                      <td className="w-120">{t('report.workingHours')}</td>
                      {searchParams?.filter?.performance && <td>{t('report.performance')}</td>}
                      {searchParams?.filter?.editmode && <td> Add Assined time </td>}
                    </tr>
                  </thead>
                  <tbody key={worker}>
                    {newReports[worker].shifts.map((shift) => {
                      // Safe date conversion
                      let date;
                      try {
                        date = new Date(shift.check_in);
                      } catch (e) {
                        console.error('Invalid date format:', shift.check_in);
                        date = new Date();
                      }

                      return (
                        <tr
                          key={`${shift.check_in}${shift.check_out}`}
                          className={
                            shift.warehouse === 'SICK'
                              ? searchParams?.filter?.sickleave
                                ? 'text-red'
                                : 'd-none'
                              : ''
                          }
                        >
                          <td>{safeGetWorkerName(workers, shift.worker)}</td>
                          <td>{dayNames[date.getDay()]}</td>
                          <td>{convertIsoToLocalDate(shift.check_in)}</td>
                          <td>{safeGetWarehouseName(warehouses, shift.warehouse)}</td>
                          <td>{extractTime(shift.check_in)}</td>
                          <td>{extractTime(shift.check_out)}</td>
                          <td className="w-120">
                            {calcDifferenceTimeDecimal(shift.check_in, shift.check_out)} h
                          </td>
                          {searchParams?.filter?.performance && shift.performance && (
                            <td>
                              {getPerformanceLabel(
                                shift.performance,
                                t(`report.${shift.performanceUnit}`),
                              )}
                            </td>
                          )}
                          {searchParams?.filter?.editmode && (
                            <td>
                              {shift.assigned_start ? (
                                ''
                              ) : (
                                <Button
                                  onClick={() => {
                                    try {
                                      const assignTime = getAssignTime(
                                        shift.check_in,
                                        shift.check_out,
                                        shift.worker,
                                        shift.id,
                                      );
                                      handleUpdateAssignedTime(assignTime, shift.id);
                                    } catch (error) {
                                      console.error('Error updating assigned time:', error);
                                    }
                                  }}
                                  endIcon={(() => {
                                    try {
                                      const assignTime = getAssignTime(
                                        shift.check_in,
                                        shift.check_out,
                                        shift.worker,
                                        shift.id,
                                      );
                                      return assignTime.extra ? <FcOvertime /> : null;
                                    } catch (error) {
                                      console.error('Error getting assign time:', error);
                                      return null;
                                    }
                                  })()}
                                >
                                  {(() => {
                                    try {
                                      const assignTime = getAssignTime(
                                        shift.check_in,
                                        shift.check_out,
                                        shift.worker,
                                        shift.id,
                                      );
                                      return (
                                        <>
                                          {extractTime(assignTime.checkIn)} <br />{' '}
                                          {extractTime(assignTime.checkOut)}
                                        </>
                                      );
                                    } catch (error) {
                                      console.error('Error displaying assign time:', error);
                                      return 'Error';
                                    }
                                  })()}
                                </Button>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                    <tr className="table-success">
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>{t('report.total')}</td>
                      <td>{timestampToDecimal(newReports[worker].total)} h</td>
                      {searchParams?.filter?.performance && (
                        <td>{newReports[worker].performanceTotal}</td>
                      )}
                      {searchParams?.filter?.editmode && <td />}
                    </tr>
                    <tr>
                      <td colSpan={6}></td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            );
          })
        ) : hasSearched ? (
          <NoDataCard message={t('report.notFound')} />
        ) : null}
      </div>

      {warehouseWorkTypeTotals && (
        <RenderWorkTypeSummary
          warehouseWorkTypeTotals={warehouseWorkTypeTotals}
          warehouses={warehouses}
        />
      )}
    </>
  );
};

export default RenderBillingReport;
