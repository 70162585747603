import { useTranslation } from 'react-i18next';
import { BiStats } from 'react-icons/bi';
import { BsFillGearFill } from 'react-icons/bs';

import { IconLinkCard } from '../IconLinkCard';

const Warehouse = () => {
  const { t } = useTranslation();

  return (
    <div className="my-2 row">
      <IconLinkCard
        link="/live-stats-monitor"
        icon={<BiStats />}
        title={t('navigation.live-stats-monitor')}
      />
      <IconLinkCard
        link="/setting"
        icon={<BsFillGearFill />}
        title={t('navigation.setting')}
      />
    </div>
  );
};

export default Warehouse;
