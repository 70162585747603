import { memo, useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import Page from '@/components/Page';
import useSettings from '@/hooks/useSettings';
import BillingReportSearch from './components/BillingReportSearch';
import RenderBillingReport from './components/RenderBillingReport';
import { resetReport } from './redux/billingReportSlice';

// Constants moved to a separate object for better organization
const REPORT_CONFIG = {
  BREADCRUMBS: [
    { name: 'Dashboard', href: '/' },
    { name: 'Tools', href: '/tools' },
    { name: 'Reports', href: '/tools/reports' },
    { name: 'Billing Report' },
  ],
};

/**
 * BillingReport Component
 *
 * The main component for the Billing Report feature. It combines the search interface
 * and report display in a clean, organized layout.
 *
 * @returns {JSX.Element} The rendered BillingReport component
 */
const BillingReport = () => {
  const { themeStretch } = useSettings();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Reset report state when component unmounts
  useEffect(() => {
    return () => {
      // Make sure billingReport is in Redux store before trying to reset it
      try {
        dispatch(resetReport());
      } catch (error) {
        console.warn('Could not reset billingReport state:', error);
      }
    };
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Billing Report | Dashboard</title>
      </Helmet>

      <Page>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs heading="Billing Report" links={REPORT_CONFIG.BREADCRUMBS} />

          <BillingReportSearch setLoading={setLoading} />
          <RenderBillingReport loading={loading} setLoading={setLoading} />
        </Container>
      </Page>
    </>
  );
};

// Export individual components for reuse
export { BillingReportSearch, RenderBillingReport };

export default memo(BillingReport);
