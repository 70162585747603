import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
// MUI imports
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const rules = [
  'rule1',
  'rule2',
  'rule3',
  'rule4',
  'rule5',
  'rule6',
  'rule7',
  'rule8',
  'rule9',
  'rule10',
  'rule11',
  'rule12',
  'rule13',
  'rule14',
  'rule15',
  'rule16',
  'rule17',
  'rule18',
  'rule19',
  'rule20',
  'rule21',
  'rule22',
  'rule23',
  'rule24',
];

const Rules = ({ show, setShown }) => {
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setShown(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        elevation: 3,
        sx: { borderRadius: 2 },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          color: 'error.main',
          fontWeight: 'bold',
        }}
      >
        {t('navigation.rules')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <IconButton onClick={() => i18n.changeLanguage('en')} sx={{ mx: 1 }}>
            <ReactCountryFlag
              svg
              countryCode="GB"
              style={{
                fontSize: '1.5em',
                lineHeight: '1.5em',
              }}
              aria-label="English"
            />
          </IconButton>

          <IconButton onClick={() => i18n.changeLanguage('fi')} sx={{ mx: 1 }}>
            <ReactCountryFlag
              svg
              countryCode="FI"
              style={{
                fontSize: '1.5em',
                lineHeight: '1.5em',
              }}
              aria-label="Finnish"
            />
          </IconButton>

          <IconButton onClick={() => i18n.changeLanguage('vi')} sx={{ mx: 1 }}>
            <ReactCountryFlag
              svg
              countryCode="VN"
              style={{
                fontSize: '1.5em',
                lineHeight: '1.5em',
              }}
              aria-label="Vietnamese"
            />
          </IconButton>
        </Box>

        <List
          sx={{
            pl: 2,
            listStyleType: 'decimal',
            '& .MuiListItem-root': {
              display: 'list-item',
              py: 1,
            },
          }}
        >
          {rules.map((rule, index) => (
            <ListItem key={index} disableGutters>
              <ListItemText
                primary={t(`rules.${rule}`)}
                primaryTypographyProps={{
                  variant: 'body1',
                }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          sx={{ borderRadius: 1 }}
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Rules;
