const login = {
  en: {
    header: 'Login',
    subheader: 'Sign in to access your account',
    email: 'Email',
    emailPlaceholder: 'Enter your email',
    password: 'Password',
    passwordPlaceholder: 'Enter your password',
    login: 'Login',
    wrongCredentials: 'Wrong username or password',
    noAccount: "Don't have an account?",
    forgot: 'Forgot Password?',
    registration: 'Sign Up',
    logout: 'Logout',
  },
  fi: {
    header: 'Kirjaudu sisään',
    subheader: 'Kirjaudu sisään käyttääksesi tiliäsi',
    email: 'Sähköpostiosoite',
    emailPlaceholder: 'Syötä sähköpostiosoitteesi',
    password: 'Salasana',
    passwordPlaceholder: 'Syötä salasanasi',
    login: 'Kirjaudu',
    wrongCredentials: 'Väärä käyttäjätunnus tai salasana',
    noAccount: 'Eikö sinulla ole käyttäjätunnusta?',
    forgot: 'Unohditko salasanasi?',
    registration: 'Rekisteröidy',
    logout: 'Kirjaudu ulos',
  },
  vi: {
    header: 'Đăng nhập',
    subheader: 'Đăng nhập để truy cập tài khoản của bạn',
    email: 'Địa chỉ email',
    emailPlaceholder: 'Nhập địa chỉ email của bạn',
    password: 'Mật khẩu',
    passwordPlaceholder: 'Nhập mật khẩu của bạn',
    login: 'Đăng nhập',
    wrongCredentials: 'Sai tên người dùng hoặc mật khẩu',
    noAccount: 'Bạn chưa có tài khoản?',
    forgot: 'Bạn quên mật khẩu?',
    registration: 'Đăng ký',
    logout: 'Đăng xuất',
  },
};

export default login;
