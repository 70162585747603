import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  keyframes,
  Autocomplete,
  CircularProgress,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { axiosNew } from '../../../../api/axiosConfig';

// Define the scanning animation keyframes
const scanAnimation = keyframes`
  0% { top: 10%; }
  50% { top: 90%; }
  100% { top: 10%; }
`;

/**
 * BarcodeScannerDialog component displays a dialog with a barcode form and scanner
 */
const BarcodeScannerDialog = ({
  open,
  onClose,
  scanError,
  scannerRef,
  scannedData,
  handleSubmit,
  toggleScanner,
  selectedProduct,
}) => {
  const { t } = useTranslation();
  const [barcode, setBarcode] = useState('');
  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [palletData, setPalletData] = useState(null);
  const [formData, setFormData] = useState({
    batchNumber: localStorage.getItem('scanner_batchNumber') || '',
    palletNumber: '',
    quantity: localStorage.getItem('scanner_quantity') || '',
    productId: selectedProduct?.id || null,
  });

  // Get products from Redux store
  const { vasProducts: products, loading: productsLoading } = useSelector(
    (state) => state.vasProducts,
  );

  const lookupPallet = useCallback(
    async (palletNumber) => {
      setLoading(true);
      setError('');
      try {
        const response = await axiosNew.get(`/vas/work-order/pallet/${palletNumber}`);
        setPalletData(response.data);
        setFormData((prev) => ({
          ...prev,
          ...response.data,
          productId: response.data.productId || selectedProduct?.id,
        }));
      } catch (err) {
        if (err.response?.status === 404) {
          setPalletData(null);
        } else {
          setError(err.response?.data?.message || 'Error looking up pallet');
        }
      } finally {
        setLoading(false);
      }
    },
    [selectedProduct],
  );

  // Update barcode input and lookup pallet when scanned data changes
  useEffect(() => {
    if (scannedData) {
      setBarcode(scannedData);
      setFormData((prev) => ({ ...prev, palletNumber: scannedData }));
      lookupPallet(scannedData);
      setScanning(false);
    }
  }, [scannedData, lookupPallet]);

  // Reset state when dialog opens/closes
  useEffect(() => {
    if (!open) {
      setScanning(false);
      setPalletData(null);
      setError('');
      setFormData({
        batchNumber: localStorage.getItem('scanner_batchNumber') || '',
        palletNumber: '',
        quantity: localStorage.getItem('scanner_quantity') || '',
        productId: selectedProduct?.id || null,
      });
    }
  }, [open, selectedProduct]);

  const onScanClick = () => {
    setScanning(true);
    toggleScanner(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Save batchNumber and quantity to localStorage when they change
    if (name === 'batchNumber' || name === 'quantity') {
      localStorage.setItem(`scanner_${name}`, value);
    }
  };

  const onSubmit = async () => {
    try {
      // Save current values to localStorage before submitting
      localStorage.setItem('scanner_batchNumber', formData.batchNumber);
      localStorage.setItem('scanner_quantity', formData.quantity);

      if (palletData) {
        // If pallet exists, submit to the ID-specific endpoint
        await axiosNew.post(`/vas/work-order/pallet/${palletData.id}/complete`);
      } else {
        // Then submit completed work order
        await axiosNew.post('/vas/work-order/pallet/completed', {
          batchNumber: formData.batchNumber,
          palletNumber: formData.palletNumber,
          quantity: formData.quantity,
          productId: formData.productId,
        });
      }
      setPalletData(null);
      setBarcode('');
      onClose();
    } catch (err) {
      setError(err.response?.data?.message || 'Error submitting pallet data');
    }
  };

  const isValid =
    formData.palletNumber &&
    formData.productId &&
    (!palletData ? formData.batchNumber && formData.quantity : true);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('selectedProduct.finalize', 'Finalize')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Barcode Input Field */}
        <TextField
          autoFocus
          margin="dense"
          id="barcode"
          label={t('selectedProduct.barcode', 'Barcode')}
          type="text"
          fullWidth
          variant="outlined"
          value={barcode}
          onChange={(e) => {
            setBarcode(e.target.value);
            setFormData((prev) => ({ ...prev, palletNumber: e.target.value }));
          }}
          sx={{ mb: 2 }}
        />

        {/* Scan Barcode Button */}
        <Button
          onClick={onScanClick}
          variant="outlined"
          startIcon={<QrCodeScannerIcon />}
          fullWidth
          sx={{ mb: 3 }}
        >
          {t('selectedProduct.scanBarcode', 'Scan Barcode')}
        </Button>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Pallet Status Alert */}
        {barcode && !loading && (
          <Alert severity={palletData ? 'success' : 'info'} sx={{ mb: 2 }}>
            {palletData ? t('selectedProduct.pallet.found') : t('selectedProduct.pallet.notFound')}
          </Alert>
        )}

        {/* Product Selection - Only show after scanning barcode and when creating new pallet */}
        {!palletData && barcode && (
          <Autocomplete
            value={products?.find((p) => p.id === formData.productId) || null}
            onChange={(event, newValue) => {
              setFormData((prev) => ({
                ...prev,
                productId: newValue?.id || null,
              }));
            }}
            loading={productsLoading}
            options={products || []}
            getOptionLabel={(option) => `${option.productCode} - ${option.productName}`}
            renderOption={(props, option) => (
              <li {...props}>
                <div>
                  <strong>{option.productCode}</strong> - {option.productName}
                  <br />
                  <small>
                    {option.productType?.name} | {option.pcs} pcs/{option.uom?.name}
                  </small>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {productsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            sx={{ mb: 2 }}
          />
        )}

        {/* Additional Fields for New Pallet */}
        {!palletData && barcode && (
          <>
            <TextField
              margin="dense"
              name="batchNumber"
              label="Batch Number"
              type="text"
              fullWidth
              required
              value={formData.batchNumber}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="dense"
              name="quantity"
              label="Quantity"
              type="number"
              fullWidth
              required
              value={formData.quantity}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
          </>
        )}

        {/* Scanner Section - Only visible when scanning */}
        {scanning && (
          <>
            {scanError && (
              <Typography color="error" sx={{ mb: 2 }}>
                {scanError}
              </Typography>
            )}

            <Box
              sx={{
                width: '100%',
                height: 300,
                position: 'relative',
                backgroundColor: '#000',
                overflow: 'hidden',
                '& canvas': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                },
                '& video': {
                  width: '100%',
                  height: '100%',
                  display: 'block',
                  objectFit: 'contain',
                },
              }}
              ref={scannerRef}
              id="interactive"
              className="viewport"
            >
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  top: 10,
                  left: 0,
                  right: 0,
                  color: 'white',
                  textAlign: 'center',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '4px',
                  zIndex: 10,
                }}
              >
                {t(
                  'selectedProduct.positionBarcode',
                  'Position the Code-39 barcode in the center of the camera',
                )}
              </Typography>

              {/* Scanning animation line */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '10%',
                  left: '5%',
                  right: '5%',
                  height: '3px',
                  backgroundColor: 'rgba(255, 0, 0, 0.7)',
                  boxShadow: '0px 0px 8px 2px rgba(255, 0, 0, 0.7)',
                  zIndex: 10,
                  animation: `${scanAnimation} 2.5s infinite ease-in-out`,
                }}
              />

              {/* Targeting box overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '25%',
                  left: '15%',
                  width: '70%',
                  height: '50%',
                  border: '2px dashed rgba(255, 255, 255, 0.6)',
                  zIndex: 9,
                  pointerEvents: 'none',
                  boxSizing: 'border-box',
                  borderRadius: '8px',
                }}
              />

              {/* Corner markers */}
              {[
                { top: '25%', left: '15%' },
                { top: '25%', right: '15%' },
                { bottom: '25%', left: '15%' },
                { bottom: '25%', right: '15%' },
              ].map((position, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    width: '15px',
                    height: '15px',
                    border: '2px solid rgba(0, 255, 0, 0.8)',
                    borderRadius: '2px',
                    ...position,
                    zIndex: 9,
                    pointerEvents: 'none',
                    ...(position.top && position.left
                      ? { borderRight: 'none', borderBottom: 'none' }
                      : position.top && position.right
                      ? { borderLeft: 'none', borderBottom: 'none' }
                      : position.bottom && position.left
                      ? { borderRight: 'none', borderTop: 'none' }
                      : { borderLeft: 'none', borderTop: 'none' }),
                  }}
                />
              ))}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel', 'Cancel')}</Button>
        <Button onClick={onSubmit} variant="contained" disabled={!isValid}>
          {t('common.submit', 'Submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BarcodeScannerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scanError: PropTypes.string,
  scannerRef: PropTypes.object.isRequired,
  scannedData: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  toggleScanner: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
};

export default BarcodeScannerDialog;
