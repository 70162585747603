import { useState } from 'react';

import PropTypes from 'prop-types';

// @mui
import {
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';

import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

VasProductTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
};

export default function VasProductTableRow({
    row,
    selected,
    onEditRow,
    onSelectRow,
    onDeleteRow,
}) {
    const {
        eanCode,
        pcs,
        productCode,
        principalName,
        productType,
        productName,
        uom,
    } = row;

    // State to manage the popover menu
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleCloseMenu();
        onEditRow();
    };

    const handleDelete = () => {
        handleCloseMenu();
        onDeleteRow();
    };

    return (
        <TableRow hover selected={selected}>
            <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow} />
            </TableCell>

            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {productCode}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {productName}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {productType?.name || '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {principalName}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {pcs}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {uom?.name || '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {eanCode}
                </Typography>
            </TableCell>

            {/* More Options Menu */}
            <TableCell align="right">
                <IconButton onClick={handleOpenMenu} size="large">
                    <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    PaperProps={{
                        sx: { width: 150, maxWidth: '100%' },
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem
                        onClick={handleEdit}
                        sx={{ color: 'text.secondary' }}
                    >
                        <Iconify
                            icon="eva:edit-fill"
                            sx={{ mr: 2, width: 20, height: 20 }}
                        />
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={handleDelete}
                        sx={{ color: 'error.main' }}
                    >
                        <Iconify
                            icon="eva:trash-2-outline"
                            sx={{ mr: 2, width: 20, height: 20 }}
                        />
                        Delete
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
}
