import React, {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {
    fetchActiveActivity,
    selectProduct,
} from '../../../actions/vasActivityActions';
import { getVasProductsByWorkTypeId } from '../../../actions/vasProductActions';
import ProductGrid from './ProductGrid';
import SelectedProductView from './SelectedProductView';
import ShiftActivitiesSection from './ShiftActivitiesSection';

const VasView = ({ latestShift, latestTask }) => {
    const dispatch = useDispatch();

    // local flag to indicate the user has manually deselected a product
    const [manualDeselection, setManualDeselection] = useState(false);

    const { vasProducts, vasProcductloading, error } = useSelector(
        (state) => state.vasProducts
    );

    const { shiftActivity, shiftActivities, activeShiftActivity } = useSelector(
        (state) => state.vasWorkLog
    );

    useEffect(() => {
        if (
            latestTask &&
            latestShift?.id &&
            Object.keys(shiftActivity ?? {}).length !== 0
        ) {
            dispatch(getVasProductsByWorkTypeId(latestTask.work_type));
        }
    }, [dispatch, latestTask, latestShift, shiftActivity]);

    useEffect(() => {
        if (latestShift?.id && Object.keys(shiftActivity ?? {}).length === 0) {
            dispatch(fetchActiveActivity(latestShift.id));
        }
    }, [dispatch, latestShift, shiftActivity]);

    // Auto-select an active shift activity only if the user hasn't manually deselected.
    useEffect(() => {
        if (
            !activeShiftActivity &&
            !manualDeselection &&
            shiftActivities &&
            shiftActivities.length > 0 &&
            vasProducts &&
            vasProducts.length > 0
        ) {
            const activeActivity = shiftActivities.find(
                (activity) =>
                    activity.productId !== null && activity.endTime === null
            );
            if (activeActivity) {
                dispatch({
                    type: 'SELECT_PRODUCT_SUCCESS',
                    payload: activeActivity,
                });
            }
        }
    }, [
        shiftActivities,
        vasProducts,
        activeShiftActivity,
        manualDeselection,
        dispatch,
    ]);

    const handleProductChange = (product) => {
        // When a product is manually selected from the grid,
        // clear the manual deselection flag.
        setManualDeselection(false);
        dispatch(selectProduct(latestTask, product));
    };

    const handleDeselectProduct = () => {
        // Set the flag so that auto-selection doesn't override manual deselection.
        setManualDeselection(true);
        dispatch({ type: 'DESELECT_PRODUCT' });
    };

    const selectedProductDetails = activeShiftActivity
        ? vasProducts.find((p) => p.id === activeShiftActivity.productId)
        : null;

    return (
        <div>
            {activeShiftActivity ? (
                selectedProductDetails ? (
                    <>
                        <SelectedProductView
                            onDeselect={handleDeselectProduct}
                        />
                        <ShiftActivitiesSection />
                    </>
                ) : (
                    <p>Loading product details...</p>
                )
            ) : vasProcductloading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <ProductGrid
                    products={vasProducts}
                    onProductSelect={handleProductChange}
                />
            )}
        </div>
    );
};

export default VasView;
