import Cookies from 'universal-cookie';
import userApi from '../api/accounts';
const cookies = new Cookies();

export const userLogin = (email, password) => {
  const current = new Date();
  const nextYear = new Date();

  nextYear.setFullYear(current.getFullYear() + 1);
  return async (dispatch) => {
    const loginRes = await userApi.getToken({
      email,
      password,
    });

    cookies.set('rsid', loginRes.token, {
      path: '/',
      secure: true,
      sameSite: 'strict',
      expires: nextYear,
    });

    const token = `Token ${cookies.get('rsid')}`;
    const user = await userApi.getUserInfo(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        user_type: user.user_type,
      },
    });
  };
};

export const userChagePassword = (password, confirmPassword) => {
  return async (dispatch) => {
    const token = `Token ${cookies.get('rsid')}`;
    const data = {
      new_password1: password,
      new_password2: confirmPassword,
    };
    const result = await userApi.updateUserPassword(token, data);
    const statusCode = result.status;

    if (statusCode === 200) {
      dispatch({
        type: 'SET_MESSAGE',
        message: [result.data.detail, 'success'],
      });
      setTimeout(() => {
        dispatch({
          type: 'SET_MESSAGE',
          message: null,
        });
      }, 5 * 1000);
    } else {
      dispatch({
        type: 'SET_MESSAGE',
        message: [result.data.new_password2[0], 'error'],
      });
      setTimeout(() => {
        dispatch({
          type: 'SET_MESSAGE',
          message: null,
        });
      }, 5 * 1000);
    }
    return result;
  };
};

export const userIsIn = () => {
  return async (dispatch) => {
    const token = `Token ${cookies.get('rsid')}`;
    const user = await userApi.getUserInfo(token);
    dispatch({
      type: 'USERISIN',
      payload: {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        user_type: user.user_type,
      },
    });
  };
};

export const userLogout = () => {
  const cookies = new Cookies();
  cookies.remove('rsid');
  return { type: 'LOGOUT' };
};

export const createUser = (user) => {
  return async (dispatch) => {
    await userApi.createUser({
      user,
    });
    dispatch({
      type: 'CREATE',
      payload: {},
    });
  };
};

export const getUsers = () => {
  return async (dispatch) => {
    const token = `Token ${cookies.get('rsid')}`;
    const response = await userApi.getUsers(token);
    const users = [];
    if (response) {
      response.map((user) => {
        return users.push({
          ...user,
          fullName: `${user.first_name} ${user.last_name}`,
          firstLetter: user.first_name.charAt(0),
        });
      });
    }

    dispatch({
      type: 'GETUSERS',
      payload: users,
    });
  };
};
