import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDaySummaryApi, getProductTypeSummaryApi } from '../services/api';

// Helper function to prepare API params
const prepareParams = (searchData) => {
  const params = {
    startDate: searchData.startDate,
    endDate: searchData.endDate,
    employeeIds: searchData.employees !== 'All' ? searchData.employees : undefined,
    warehouseIds: searchData.warehouse !== 'All' ? searchData.warehouse : undefined,
    principalId: searchData.principal !== 'All' ? searchData.principal : undefined,
    workTypeId: searchData.task !== 'All' ? searchData.task : undefined,
  };

  // Remove undefined values for cleaner query params
  Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

  return params;
};

// Async thunk for fetching day summary report
export const fetchDaySummaryReport = createAsyncThunk(
  'vasReport/fetchDaySummary',
  async (searchData, { rejectWithValue }) => {
    try {
      const params = prepareParams(searchData);
      const reportData = await getDaySummaryApi(params);
      return { data: reportData, reportType: 'product' };
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch day summary report');
    }
  },
);

// Async thunk for fetching product type summary report
export const fetchProductTypeSummaryReport = createAsyncThunk(
  'vasReport/fetchProductTypeSummary',
  async (searchData, { rejectWithValue }) => {
    try {
      const params = prepareParams(searchData);
      const reportData = await getProductTypeSummaryApi(params);
      return { data: reportData, reportType: 'product-type' };
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch product type summary report');
    }
  },
);

// Action to determine which report type to fetch
export const getVasReport = (searchData) => (dispatch) => {
  if (searchData.reportType === 'product') {
    return dispatch(fetchDaySummaryReport(searchData));
  }
  return dispatch(fetchProductTypeSummaryReport(searchData));
};

// Initial state
const initialState = {
  data: {
    daySummary: [],
    productTypeSummary: [],
  },
  loading: false,
  error: null,
  searchPerformed: false,
  reportType: null,
};

// Create the slice
const vasReportSlice = createSlice({
  name: 'vasReport',
  initialState,
  reducers: {
    resetReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Handle day summary report
      .addCase(fetchDaySummaryReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDaySummaryReport.fulfilled, (state, action) => {
        state.loading = false;
        state.data.daySummary = action.payload.data;
        state.searchPerformed = true;
        state.reportType = action.payload.reportType;
      })
      .addCase(fetchDaySummaryReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.searchPerformed = true;
      })
      // Handle product type summary report
      .addCase(fetchProductTypeSummaryReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductTypeSummaryReport.fulfilled, (state, action) => {
        state.loading = false;
        state.data.productTypeSummary = action.payload.data;
        state.searchPerformed = true;
        state.reportType = action.payload.reportType;
      })
      .addCase(fetchProductTypeSummaryReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.searchPerformed = true;
      });
  },
});

// Export actions and reducer
export const { resetReport } = vasReportSlice.actions;
export default vasReportSlice.reducer;
