import { getDoc, doc, addDoc, updateDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { statisticsCollectionRef, sickleaveReasonsCollectionRef } from '../db/firestore';

import { formatDoc, formatDocs } from '../utils/formatFirestore';

export const getSickleaveCurrentID = async () => {
  try {
    const docRef = doc(statisticsCollectionRef, 'sickleaveId');
    const response = await getDoc(docRef);
    return formatDoc(response).currentNumber;
  } catch (error) {
    console.error('Error getting sickleave ID:', error);
    // Return a fallback value to prevent complete failure
    return 0;
  }
};

export const updateSickleaveCurrentID = async (currentNumber) => {
  try {
    const docRef = doc(statisticsCollectionRef, 'sickleaveId');
    await updateDoc(docRef, { currentNumber });
  } catch (error) {
    console.error('Error updating sickleave ID:', error);
    throw new Error('Failed to update sickleave ID counter');
  }
};

export const getSickLeaveReasons = async () => {
  try {
    const response = await getDocs(sickleaveReasonsCollectionRef);
    return formatDocs(response);
  } catch (error) {
    console.error('Error fetching sickleave reasons:', error);
    return [];
  }
};

export const createSickLeaveReason = async (data) => {
  try {
    const response = await addDoc(sickleaveReasonsCollectionRef, data);
    return response.id;
  } catch (error) {
    console.error('Error creating sickleave reason:', error);
    throw new Error('Failed to create sickleave reason');
  }
};

export const deleteSickLeaveReason = async (id) => {
  try {
    const reasonRef = doc(sickleaveReasonsCollectionRef, id);
    await deleteDoc(reasonRef);
  } catch (error) {
    console.error('Error deleting sickleave reason:', error);
    throw new Error('Failed to delete sickleave reason');
  }
};
