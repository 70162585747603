import { axiosMain } from './axiosConfig';

const searchShifts = async (data) => {
  const params = {};

  if (data.is_working !== undefined) params.is_working = data.is_working;
  if (data.startDate) params.check_in = data.startDate;
  if (data.endDate) params.check_out = data.endDate;
  if (data.warehouse) params.warehouse = data.warehouse;
  if (data.worker) params.worker = data.worker;

  const response = await axiosMain.get('/report/shift/', { params });
  return response.data;
};

const warehouseShares = async () => {
  const response = await axiosMain.get('/report/shift/');
  return response.data;
};

const getWorkingShift = async () => {
  const response = await axiosMain.get('/report/shift/?is_working=true');
  return response.data;
};

const reportApi = {
  searchShifts,
  warehouseShares,
  getWorkingShift
};

export default reportApi;
