import 'react-confirm-alert/src/react-confirm-alert.css';

import {
    useEffect,
    useRef,
    useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';

import {
    addSpeedRate,
    postShiftTask,
    stopWork,
    switchWork,
} from '../../../actions/shiftActions';
import {
    endActivity,
    endShift,
    startTaskActivity,
} from '../../../actions/vasActivityActions';
import { getPrincipalsTasks } from '../../../actions/warehouseActions';
import { getVasProductsByWorkTypeIdApi } from '../../../api/vasproduct';
import CountTime from '../../HelperFunctions/CountTime';
import ReactSwipeButton from '../../Others/ReactSwipeButton';
import EndShiftModal from './EndShiftModal';
import VasView from './VasView';

const InJob = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Selectors for shifts, tasks, principal tasks, and vasProducts
    const shifts = useSelector((state) => state.shift);
    const tasks = useSelector((state) => state.tasks);
    const principalTasks = useSelector((state) => state.principalTasks);

    // Local state
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [usingModal, setUsingModal] = useState(false);
    const [unit, setUnit] = useState('');

    // Create a ref to track if the component is mounted
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    // Get the latest (ongoing) task and all completed tasks
    const latestTask = tasks.find((task) => task.end === null);
    const competedTasks = tasks.filter((task) => task.end !== null);

    const [latestShift, setLatestShift] = useState({});

    // When shifts or completed tasks change, update the latestShift
    useEffect(() => {
        const activeShift = shifts.find(
            (shift) => shift.check_out === null && shift.is_working === true
        );
        if (isMountedRef.current) {
            setLatestShift(activeShift);
        }
    }, [competedTasks, shifts]);

    // Get principal tasks when latestShift and latestTask are available
    useEffect(() => {
        if (latestShift && latestTask) {
            // If your getPrincipalsTasks returns a promise and you need to do something
            // with the result, you could chain a then() and check the isMountedRef.
            dispatch(
                getPrincipalsTasks(
                    latestShift.warehouse,
                    latestTask.principal_id
                )
            );
        }
    }, [dispatch, latestShift, latestTask, shifts]);

    const breakOrLunchTask = principalTasks?.find(
        (task) =>
            task.description === 'BREAK-BUTTON' ||
            task.description === 'LUNCH-BUTTON'
    );
    const isLunchTask = breakOrLunchTask?.description === 'LUNCH-BUTTON';

    // Determine unit and whether to use modal from principalTasks and tasks data
    useEffect(() => {
        let uni = '';
        const filtered = [];

        tasks.forEach((task) => {
            const ta = principalTasks?.find((t) => t.id === task.work_type);
            if (ta) {
                filtered.push(ta);
            }
        });

        const x = filtered?.find((tasks) => {
            const descriptions = tasks.description.split(',');
            uni = descriptions.find((e) => e.includes('UNIT'));
            return descriptions?.find(
                (description) => description === 'PERFORMANCE-END'
            );
        });
        if (isMountedRef.current) {
            if (uni) {
                setUnit(uni.split(':')[1].trim());
            }
            if (x) {
                setUsingModal(true);
            }
        }
    }, [principalTasks, tasks]);

    // Function to switch work (for switching work type)
    const switchWorking = async (event) => {
        if (isMountedRef.current) setLoading(true);
        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(endActivity(latestShift.id));
        if (isMountedRef.current) setLoading(false);
    };

    // Function to stop working completely (ends shift and task activity)
    const stopWorking = async (event) => {
        if (isMountedRef.current) setLoading(true);
        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(stopWork(latestShift.id));
        await dispatch(endShift(latestShift.id));
        if (isMountedRef.current) {
            setLoading(false);
        }
        navigate('/');
    };

    // Function to start a break (or lunch break)
    const startBreak = async () => {
        if (isMountedRef.current) setLoading(true);
        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        // Use breakOrLunchTask id regardless if it's break or lunch.
        await dispatch(postShiftTask(latestShift.id, breakOrLunchTask.id));
        await dispatch(endActivity(latestShift.id));
        if (isMountedRef.current) setLoading(false);
    };

    // Function to continue working after break/lunch
    const contineuWorking = async (event) => {
        if (isMountedRef.current) setLoading(true);

        // Filter out break/lunch tasks if necessary.
        const competedTasksNoBreak = competedTasks.filter(
            (task) => task.work_type !== breakOrLunchTask?.id
        );
        competedTasksNoBreak.sort(
            (a, b) => Date.parse(a.end) - Date.parse(b.end)
        );
        const previousTask =
            competedTasksNoBreak[competedTasksNoBreak.length - 1];

        // Switch the work type and post a new shift task.
        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(postShiftTask(latestShift.id, previousTask.work_type));

        // Check if the previous task's work type has any associated VAS products.
        const previousVasProducts = await getVasProductsByWorkTypeIdApi(
            previousTask.work_type
        );

        const associatedVasProducts = previousVasProducts?.filter(
            (product) => product.workTypeId === previousTask.work_type
        );

        // If there is at least one associated VAS product, start the task activity.
        if (associatedVasProducts && associatedVasProducts.length > 0) {
            await dispatch(
                startTaskActivity(
                    {
                        employeeId: parseInt(latestShift.worker),
                        warehouseId: parseInt(latestShift.warehouse),
                        shiftId: latestShift.id,
                        principalId: previousTask.principal_id,
                        taskId: previousTask.work_type,
                    },
                    false
                )
            );
        }

        if (isMountedRef.current) setLoading(false);
    };

    // Utility function to format time from a timestamp
    const getTime = (timestamp) => {
        const dateObject = new Date(timestamp);
        const hours = ('0' + dateObject.getHours()).substr(-2);
        const minutes = ('0' + dateObject.getMinutes()).substr(-2);
        return `${hours}:${minutes}`;
    };

    // Calculate worked time for completed tasks
    const getWorkedTime = () => {
        let sumTimeStamp = 0;
        competedTasks.forEach((task) => {
            const diff = Date.parse(task.end) - Date.parse(task.start);
            sumTimeStamp += diff;
        });
        return sumTimeStamp;
    };

    // Modal handlers for ending shift with performance-end modal.
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        if (isMountedRef.current) setOpen(false);
    };

    const handleSubmitModal = async (amouth) => {
        if (isMountedRef.current) setOpen(false);
        if (isMountedRef.current) setLoading(true);

        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(stopWork(latestShift.id));
        await dispatch(addSpeedRate(latestShift.id, amouth, unit));
        if (isMountedRef.current) {
            setLoading(false);
            navigate('/');
        }
    };

    const handleOpenModal = () => {
        if (isMountedRef.current) setOpen(true);
    };

    // Determine the start time for CountTime component (assuming latestTask.start is available)
    const start = Date.parse(latestTask?.start);

    return (
        <div className="text-center mt-2">
            <VasView latestShift={latestShift} latestTask={latestTask} />
            <div className="pt-5">
                <h4 className="border rounded p-3 text-green">
                    <div className="row">
                        <div className="col-lg-3">
                            {latestTask?.principal_name},{' '}
                            {t(latestTask?.work_type_name)}
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <CountTime startTime={start} />
                            </div>
                            {/* Render button for starting break/lunch */}
                            {breakOrLunchTask &&
                                breakOrLunchTask.id !==
                                    latestTask?.work_type && (
                                    <div className="my-3">
                                        <LoadingButton
                                            onClick={startBreak}
                                            loading={loading}
                                            loadingIndicator="Loading..."
                                            variant="contained"
                                        >
                                            {isLunchTask
                                                ? t('timer.startLunch')
                                                : t('timer.startBreak')}
                                        </LoadingButton>
                                    </div>
                                )}
                            {/* Render button for continuing work after break/lunch */}
                            {breakOrLunchTask &&
                                breakOrLunchTask.id ===
                                    latestTask?.work_type && (
                                    <div className="my-3">
                                        <LoadingButton
                                            onClick={contineuWorking}
                                            loading={loading}
                                            loadingIndicator="Loading..."
                                            variant="contained"
                                        >
                                            {t('timer.continueWorking')}
                                        </LoadingButton>
                                    </div>
                                )}
                        </div>
                        <div className="col-lg-3 mt-2">
                            <ReactSwipeButton
                                text={t('timer.switchWorkType')}
                                color="#358dc9"
                                onSuccess={switchWorking}
                            />
                        </div>
                    </div>
                </h4>
                <h4 className="border rounded p-3 text-red">
                    <div className="row">
                        <div className="col-lg-3">
                            {t('timer.totalWorkingTime')}
                        </div>
                        <div className="col-lg-6">
                            <CountTime startTime={start - getWorkedTime()} />
                        </div>
                        <div className="col-lg-3 mt-2">
                            <ReactSwipeButton
                                text={t('timer.stopWorking')}
                                color="#de0E19"
                                onSuccess={
                                    usingModal ? handleOpenModal : stopWorking
                                }
                            />
                        </div>
                    </div>
                </h4>
                <hr />

                {competedTasks &&
                    competedTasks
                        .slice() // make a copy before reversing
                        .reverse()
                        .map((task) => (
                            <h4 key={task.id} className="border rounded p-3">
                                <div className="row">
                                    <div className="col-lg-3">
                                        {task.principal_name},{' '}
                                        {task.work_type_name}
                                    </div>
                                    <div className="col-lg-6">
                                        {`${getTime(
                                            Date.parse(task.start)
                                        )} - ${getTime(Date.parse(task.end))}`}
                                    </div>
                                </div>
                            </h4>
                        ))}
            </div>
            <EndShiftModal
                open={open}
                handleClose={handleClose}
                handleSubmitModal={handleSubmitModal}
                unit={unit}
            />
        </div>
    );
};

export default InJob;
