import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import UploadIcon from '@mui/icons-material/Upload';
import {
  Alert,
  Autocomplete,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  addVasProduct,
  getProductTypesAndUoms,
  getVasProductById,
  updateVasProduct,
} from '../../../actions/vasProductActions';
import {
  getPrincipalsTasks,
  getWarehouse,
  getWarehousePrincipals,
} from '../../../actions/warehouseActions';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';

const initialFormData = {
  eanCode: '',
  pcs: '',
  productCode: '',
  targetPerformance: '',
  productName: '',
  productType: '',
  uom: '',
  warehouseId: '',
  principalId: '',
  principalName: '',
  workTypeId: '',
  image: '',
};

const VasProductForm = () => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); // if id exists, we are in edit mode

  const [formData, setFormData] = useState(initialFormData);
  const [uploading, setUploading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  // Assume these pieces of state are available in your store
  const { warehouses } = useSelector(state => state);
  const { principal: principals } = useSelector(state => state);
  const { principalTasks: tasks } = useSelector(state => state);
  const { productTypes, UOMs, vasProductDetails } = useSelector(
    state => state.vasProducts
  );

  // On mount, load warehouses and product types/UOMs.
  useEffect(() => {
    dispatch(getWarehouse());
    dispatch(getProductTypesAndUoms());
  }, [dispatch]);

  // If editing, load the product details
  useEffect(() => {
    if (id) {
      dispatch(getVasProductById(id));
    }
  }, [dispatch, id]);

  // When product details are loaded, populate the form
  useEffect(() => {
    if (id && vasProductDetails) {
      setFormData({
        eanCode: vasProductDetails.eanCode || '',
        pcs: vasProductDetails.pcs || '',
        productCode: vasProductDetails.productCode || '',
        targetPerformance: vasProductDetails.targetPerformance || '',
        productName: vasProductDetails.productName || '',
        productType: vasProductDetails.productType?.name || '',
        uom: vasProductDetails.uom?.name || '',
        warehouseId: vasProductDetails.warehouseId || '',
        principalId: vasProductDetails.principalId || '',
        principalName: vasProductDetails.principalName || '',
        workTypeId: vasProductDetails.workTypeId || '',
        image: vasProductDetails.image || '',
      });
    }
  }, [id, vasProductDetails]);

  useEffect(() => {
    if (id && formData.warehouseId) {
      dispatch(getWarehousePrincipals(formData.warehouseId));
    }
  }, [id, formData.warehouseId, dispatch]);

  useEffect(() => {
    if (id && formData.warehouseId && formData.principalId) {
      dispatch(getPrincipalsTasks(formData.warehouseId, formData.principalId));
    }
  }, [id, formData.warehouseId, formData.principalId, dispatch]);

  // Prevent scroll wheel from changing number input values
  const handleWheel = e => {
    // Prevent the default scroll behavior when input is focused
    e.target.blur();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Clear error for the field when user starts typing
    setFieldErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleChangeWarehouse = e => {
    const warehouseId = e.target.value;
    setFormData(prev => ({
      ...prev,
      warehouseId,
      principalId: '',
      workTypeId: '',
    }));
    if (warehouseId) {
      dispatch(getWarehousePrincipals(warehouseId));
    }
    setFieldErrors(prev => ({ ...prev, warehouseId: null }));
  };

  const handleChangePrincipal = e => {
    const principalId = e.target.value;
    setFormData(prev => ({
      ...prev,
      principalId,
      workTypeId: '',
    }));
    if (principalId) {
      dispatch(getPrincipalsTasks(formData.warehouseId, principalId));
    }
    setFieldErrors(prev => ({ ...prev, principalId: null }));
  };

  const handleChangeTask = e => {
    const workTypeId = e.target.value;
    setFormData(prev => ({
      ...prev,
      workTypeId,
    }));
    setFieldErrors(prev => ({ ...prev, workTypeId: null }));
  };

  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', 'dth-vas');
    try {
      const response = await fetch(
        'https://api.cloudinary.com/v1_1/dgfkvkcpm/image/upload',
        {
          method: 'POST',
          body: data,
        }
      );
      const result = await response.json();
      setFormData(prev => ({ ...prev, image: result.secure_url }));
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFieldErrors({});

    let submissionData = {};

    if (id) {
      // For edit mode, post only the fields that have changed.
      // Note: productType and uom will remain as strings.
      Object.keys(formData).forEach(key => {
        let originalValue;
        // For productType and uom, the original value is stored as an object,
        // so we extract the name for comparison.
        if (key === 'productType') {
          originalValue = vasProductDetails.productType?.name || '';
        } else if (key === 'uom') {
          originalValue = vasProductDetails.uom?.name || '';
        } else {
          originalValue = vasProductDetails[key] || '';
        }
        if (formData[key] !== originalValue) {
          submissionData[key] = formData[key];
        }
      });
    } else {
      // For add mode, send all data. Always send productType and uom as strings.
      submissionData = {
        ...formData,
        productType: formData.productType,
        uom: formData.uom,
      };
    }

    // If no changes in edit mode, navigate to the list page
    if (id && Object.keys(submissionData).length === 0) {
      navigate('/tools/vas-products-list');
      return;
    }

    try {
      if (id) {
        // Edit mode: update product with id
        await dispatch(updateVasProduct(id, submissionData));
      } else {
        // Add mode: create new product
        await dispatch(addVasProduct(submissionData));
      }
      navigate('/tools/vas-products-list');
    } catch (errors) {
      setFieldErrors(errors);
    }
  };

  return (
    <Page title={id ? 'Edit VAS Product' : 'Add VAS Product'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Link color="inherit" href="/tools">
            Tools
          </Link>
          <Link color="inherit" href="/tools/vas-products-list">
            VAS Product
          </Link>
          <Typography color="textPrimary">
            {id ? 'Edit VAS Product' : 'Add VAS Product'}
          </Typography>
        </Breadcrumbs>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit VAS Product' : 'Add VAS Product'}
        </Typography>

        {fieldErrors.general && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {fieldErrors.general}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <FormControl fullWidth variant="outlined" margin="normal" required>
            <InputLabel required>Warehouse</InputLabel>
            <Select
              label="Warehouse"
              name="warehouseId"
              value={formData.warehouseId}
              onChange={handleChangeWarehouse}
              error={Boolean(fieldErrors.warehouseId)}
            >
              <MenuItem value="">Select Warehouse</MenuItem>
              {warehouses?.map(warehouse => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
            {fieldErrors.warehouseId && (
              <Typography variant="caption" color="error">
                {fieldErrors.warehouseId}
              </Typography>
            )}
          </FormControl>

          {formData.warehouseId && (
            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel required>Principal</InputLabel>
              <Select
                label="Principal"
                name="principalId"
                value={formData.principalId}
                onChange={handleChangePrincipal}
                error={Boolean(fieldErrors.principalId)}
              >
                <MenuItem value="">Select Principal</MenuItem>
                {principals?.map(principal => (
                  <MenuItem key={principal.id} value={principal.id}>
                    {principal.name}
                  </MenuItem>
                ))}
              </Select>
              {fieldErrors.principalId && (
                <Typography variant="caption" color="error">
                  {fieldErrors.principalId}
                </Typography>
              )}
            </FormControl>
          )}

          {formData.principalId && (
            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel required>Task</InputLabel>
              <Select
                label="Task"
                name="workTypeId"
                value={formData.workTypeId}
                onChange={handleChangeTask}
                error={Boolean(fieldErrors.workTypeId)}
              >
                <MenuItem value="">Select Task</MenuItem>
                {tasks?.map(task => (
                  <MenuItem key={task.id} value={task.id}>
                    {task.title}
                  </MenuItem>
                ))}
              </Select>
              {fieldErrors.workTypeId && (
                <Typography variant="caption" color="error">
                  {fieldErrors.workTypeId}
                </Typography>
              )}
            </FormControl>
          )}

          <TextField
            label="Product Code"
            name="productCode"
            value={formData.productCode}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(fieldErrors.productCode)}
            helperText={fieldErrors.productCode}
          />

          <TextField
            label="Product Name"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(fieldErrors.productName)}
            helperText={fieldErrors.productName}
          />

          <Autocomplete
            freeSolo
            options={productTypes?.map(pt => pt.name) || []}
            value={formData.productType}
            onChange={(event, newValue) =>
              setFormData(prev => ({
                ...prev,
                productType: newValue || '',
              }))
            }
            onInputChange={(event, newInputValue) =>
              setFormData(prev => ({
                ...prev,
                productType: newInputValue,
              }))
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Product Type"
                margin="normal"
                variant="outlined"
                fullWidth
                error={Boolean(fieldErrors.productType)}
                helperText={fieldErrors.productType}
              />
            )}
          />

          <Autocomplete
            freeSolo
            options={UOMs?.map(uom => uom.name) || []}
            value={formData.uom}
            onChange={(event, newValue) =>
              setFormData(prev => ({
                ...prev,
                uom: newValue || '',
              }))
            }
            onInputChange={(event, newInputValue) =>
              setFormData(prev => ({
                ...prev,
                uom: newInputValue,
              }))
            }
            renderInput={params => (
              <TextField
                {...params}
                label="UOM"
                margin="normal"
                variant="outlined"
                fullWidth
                error={Boolean(fieldErrors.uom)}
                helperText={fieldErrors.uom}
              />
            )}
          />

          <TextField
            label="Principal Name"
            name="principalName"
            value={formData.principalName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(fieldErrors.principalName)}
            helperText={fieldErrors.principalName}
          />

          <TextField
            label="Pcs"
            name="pcs"
            type="number"
            value={formData.pcs}
            onChange={handleChange}
            onWheel={handleWheel}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(fieldErrors.pcs)}
            helperText={fieldErrors.pcs}
          />

          <TextField
            label="Target Performance"
            name="targetPerformance"
            type="number"
            inputProps={{ step: '0.01' }}
            value={formData.targetPerformance}
            onChange={handleChange}
            onWheel={handleWheel}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(fieldErrors.targetPerformance)}
            helperText={fieldErrors.targetPerformance}
          />

          <TextField
            label="BOX EAN-Code"
            name="eanCode"
            value={formData.eanCode}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={Boolean(fieldErrors.eanCode)}
            helperText={fieldErrors.eanCode}
          />

          <FormControl
            fullWidth
            sx={{
              mt: 2,
              border: '1px dashed #ccc',
              borderRadius: '8px',
              p: 2,
              textAlign: 'center',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Typography variant="body1" gutterBottom>
              Upload Product Image
            </Typography>

            {!formData.image ? (
              <>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ mb: 2 }}
                  startIcon={<UploadIcon />}
                >
                  Choose File
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </Button>
                {uploading && (
                  <CircularProgress
                    sx={{
                      display: 'block',
                      mx: 'auto',
                      mb: 2,
                    }}
                  />
                )}
              </>
            ) : (
              <div>
                <img
                  src={formData.image}
                  alt="Uploaded"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '200px',
                    marginTop: '10px',
                    borderRadius: '8px',
                  }}
                />
                <Typography sx={{ mt: 1 }} color="green">
                  Image uploaded successfully!
                </Typography>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ mt: 2 }}
                  onClick={() =>
                    setFormData(prev => ({
                      ...prev,
                      image: '',
                    }))
                  }
                >
                  Remove Image
                </Button>
              </div>
            )}
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {id ? 'Update' : 'Submit'}
          </Button>
        </form>
      </Container>
    </Page>
  );
};

export default VasProductForm;
