import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const NoDataCard = ({ message }) => (
  <Card sx={{ mt: 2 }}>
    <CardContent>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={3}>
        <InfoIcon sx={{ fontSize: 40, mb: 2, color: 'text.secondary' }} />
        <Typography variant="body1" color="text.secondary">
          {message}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

NoDataCard.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NoDataCard;
