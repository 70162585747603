const setting = {
  en: {
    changePassword: 'Change Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    save: 'Save',
    about: 'About',
  },
  fi: {
    changePassword: 'Vaihda salasana',
    newPassword: 'Uusi salasana',
    confirmPassword: 'Vahvista salasana',
    save: 'Tallenna',
    about: 'Tietoja sovelluksesta',
  },
  vi: {
    changePassword: 'Đổi mật khẩu',
    newPassword: 'Mật khẩu mới',
    confirmPassword: 'Xác nhận mật khẩu',
    save: 'Lưu',
    about: 'Thông tin',
  }
};

export default setting;