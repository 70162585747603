import { axiosMain } from './axiosConfig';

const getWarehouse = async () => {
  const response = await axiosMain.get('/warehouse/');
  const resData = response.data;
  const filteredData = resData.filter((data) => data.is_active === true);
  return filteredData;
};

const getWarehousePrincipals = async (id) => {
  const response = await axiosMain.get(`/warehouse/${id}/principal/`);
  const resData = response.data;
  const filteredData = resData.filter((data) => data.is_active === true);
  return filteredData;
};

const getPrincipalsTasks = async (warehouseID, principalID) => {
  const response = await axiosMain.get(`/warehouse/${warehouseID}/principal/${principalID}/task/`);
  return response.data;
};

const warehouseApi = {
  getWarehouse,
  getWarehousePrincipals,
  getPrincipalsTasks
};

export default warehouseApi;
