/**
 * Aggregates work type totals by warehouse from billing reports
 *
 * @param {Object|null|undefined} reports - The billing reports object, can be null/undefined
 * @returns {Object} Aggregated work type totals by warehouse
 */
const aggregateWorkTypeTotalsByWarehouse = (reports) => {
  // Return empty object if reports is falsy or not an object
  if (!reports || typeof reports !== 'object') {
    return {};
  }

  let warehouseWorkTypeTotals = {};

  try {
    // Loop through each report
    Object.values(reports).forEach((report) => {
      // Skip if report or warehouses is undefined
      if (!report || !report.warehouses) return;

      // Loop through each warehouse in the report
      Object.entries(report.warehouses).forEach(([warehouseId, warehouseData]) => {
        // Skip if warehouseData or workTypeTotals is undefined
        if (!warehouseId || !warehouseData || !warehouseData.workTypeTotals) return;

        if (!warehouseWorkTypeTotals[warehouseId]) {
          warehouseWorkTypeTotals[warehouseId] = {};
        }

        // Loop through each work type total in the warehouse
        Object.entries(warehouseData.workTypeTotals).forEach(([workTypeId, workTypeData]) => {
          // Skip if workTypeData is undefined or missing key properties
          if (
            !workTypeId ||
            !workTypeData ||
            !('work_type_name' in workTypeData) ||
            !('totalSeconds' in workTypeData)
          )
            return;

          const { work_type_name, totalSeconds } = workTypeData;

          if (!warehouseWorkTypeTotals[warehouseId][workTypeId]) {
            warehouseWorkTypeTotals[warehouseId][workTypeId] = {
              work_type_name,
              totalSeconds: 0,
            };
          }
          warehouseWorkTypeTotals[warehouseId][workTypeId].totalSeconds += totalSeconds || 0;
        });
      });
    });
  } catch (error) {
    console.error('Error in aggregateWorkTypeTotalsByWarehouse:', error);
    return {};
  }

  return warehouseWorkTypeTotals;
};

export default aggregateWorkTypeTotalsByWarehouse;
