export function roundToNearestHour(time) {
  // parse the time string and get the date object
  const date = new Date(time);
  // get the number of minutes from the date object
  const minutes = date.getMinutes();
  // check if the number of minutes is less than 30
  if (minutes < 30) {
    // set the number of minutes to 0
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
  } else {
    // set the number of minutes to 0 and add 1 hour
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(date.getHours() + 1);
  }
  // return the rounded time as a string
  return date.toISOString();
}

export function roundToNearestQuarter(time) {
  // Parse the time string and get the date object
  const date = new Date(time);
  // Get the number of minutes from the date object
  const minutes = date.getMinutes();
  // Round to the nearest quarter (0, 15, 30, 45)
  const remainder = minutes % 15;

  // Reset seconds and milliseconds
  date.setMilliseconds(0);
  date.setSeconds(0);

  if (remainder < 8) {
    // Round down to the nearest quarter
    date.setMinutes(Math.floor(minutes / 15) * 15);
  } else {
    // Round up to the nearest quarter
    date.setMinutes(Math.ceil(minutes / 15) * 15);

    // If we rounded up to 60, adjust the hour
    if (date.getMinutes() === 60) {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }
  }

  // Return the rounded time as a string
  return date.toISOString();
}
