// This file has been simplified while preserving core authentication functionality
import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';

// Initial state
const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
};

// Handlers for state updates
const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// Auth provider component
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Simplified auth methods
  const login = async (email, password) => {
    console.log('JWT login method has been simplified', { email, password });
    return Promise.resolve();
  };

  const register = async (email, password, firstName, lastName) => {
    console.log('JWT register method has been simplified', {
      email,
      password,
      firstName,
      lastName,
    });
    return Promise.resolve();
  };

  const logout = async () => {
    console.log('JWT logout method has been simplified');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
