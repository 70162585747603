import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TodayIcon from '@mui/icons-material/Today';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Fade,
  FormControlLabel,
  Switch,
} from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * Reusable report search form component that works with all reports in the system
 */
const ReportSearchForm = ({
  onSearch,
  data,
  filterOptions,
  isLoading = false,
  additionalFields = [],
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    warehouse: 'All',
    principal: 'All',
    task: 'All',
    employees: 'All',
    worker: 'All',
    showFilterPopper: false,
    filterOptions: filterOptions || {},
    anchorEl: null,
  });

  const handleSelectChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleMultipleChange = (key, selected, fetchAction) => {
    const ids = selected.map((item) => item.id);

    // If key is "employee", set both worker and employees for backward compatibility
    if (key === 'employee') {
      setFilters((prev) => ({
        ...prev,
        worker: ids,
        employees: ids,
      }));
    } else {
      handleSelectChange(key, ids.length === 1 ? ids[0] : ids);
    }

    if (key === 'warehouse' && ids.length === 1 && fetchAction) {
      fetchAction(ids[0]);
    }
  };

  const setToday = () => {
    const today = new Date().toISOString().split('T')[0];
    setFilters((prev) => ({
      ...prev,
      startDate: today,
      endDate: today,
    }));
  };

  const handleFilterToggle = (name, value) => {
    setFilters((prev) => ({
      ...prev,
      filterOptions: { ...prev.filterOptions, [name]: value },
    }));
  };

  const toggleAllFilters = (value) => {
    const updatedOptions = {};
    Object.keys(filters.filterOptions).forEach((key) => {
      updatedOptions[key] = value;
    });

    setFilters((prev) => ({
      ...prev,
      filterOptions: updatedOptions,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (onSearch) {
      const searchData = {
        worker: filters.worker === 'All' ? [] : filters.worker,
        employees: filters.employees === 'All' ? [] : filters.employees,
        warehouse: filters.warehouse === 'All' ? [] : filters.warehouse,
        principal: filters.principal,
        task: filters.task,
        startDate: filters.startDate,
        endDate: filters.endDate,
        filter: filters.filterOptions,
      };

      await onSearch(searchData);
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {/* Date Range Selection */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* From date field */}
                <Grid item xs={12} sm={5.5}>
                  <TextField
                    id="startDate"
                    label={t('report.from')}
                    type="date"
                    value={filters.startDate}
                    onChange={({ target }) => handleSelectChange('startDate', target.value)}
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                {/* To date field */}
                <Grid item xs={11} sm={5.5}>
                  <TextField
                    id="endDate"
                    label={t('report.to')}
                    type="date"
                    value={filters.endDate}
                    onChange={({ target }) => handleSelectChange('endDate', target.value)}
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                {/* Today button */}
                <Grid item xs={1} sm={1} display="flex" alignItems="center" justifyContent="center">
                  <Tooltip title={t('common.today')}>
                    <IconButton onClick={setToday} color="primary" size="small">
                      <TodayIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            {/* Multi-select fields */}
            {[
              { key: 'employee', dataKey: 'users' },
              { key: 'warehouse', dataKey: 'warehouses' },
            ].map(
              ({ key, dataKey }) =>
                data[dataKey] && (
                  <Grid item xs={12} key={key}>
                    <Autocomplete
                      multiple
                      onChange={(event, newValue) =>
                        handleMultipleChange(key, newValue, data.fetchPrincipals)
                      }
                      limitTags={5}
                      id={`checkboxes-${key}`}
                      options={data[dataKey]}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.first_name || option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} value={option.id} key={option.id}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.first_name || option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t(
                            `common.select${
                              key === 'employee'
                                ? 'Employee'
                                : key.charAt(0).toUpperCase() + key.slice(1)
                            }`,
                          )}
                        />
                      )}
                    />
                  </Grid>
                ),
            )}

            {/* Principal and Task selection (only if warehouse is selected) */}
            {Number.isInteger(filters.warehouse) && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="principal-label">{t('common.selectPrincipal')}</InputLabel>
                      <Select
                        labelId="principal-label"
                        id="principal"
                        value={filters.principal}
                        label={t('common.selectPrincipal')}
                        onChange={({ target }) => {
                          handleSelectChange('principal', target.value);
                          if (target.value !== 'All' && data.fetchTasks) {
                            data.fetchTasks(filters.warehouse, target.value);
                          }
                        }}
                      >
                        <MenuItem value="All">{t('report.all')}</MenuItem>
                        {data.principals?.map((principal) => (
                          <MenuItem key={principal.id} value={principal.id}>
                            {principal.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Task selection (only if principal is selected) */}
                  {filters.principal !== 'All' && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="task-label">{t('common.selectWorktype')}</InputLabel>
                        <Select
                          labelId="task-label"
                          id="task"
                          value={filters.task}
                          label={t('common.selectWorktype')}
                          onChange={({ target }) => handleSelectChange('task', target.value)}
                        >
                          <MenuItem value="All">{t('report.all')}</MenuItem>
                          {data.tasks?.map((task) => (
                            <MenuItem key={task.id} value={task.id}>
                              {t(task.title)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {/* Additional custom fields */}
            {additionalFields.map((field, index) => (
              <Grid item xs={12} key={`additional-${index}`}>
                {field}
              </Grid>
            ))}

            {/* Search Button and Filter options */}
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                  {isLoading ? t('common.searching') : t('report.search')}
                </Button>

                {Object.keys(filters.filterOptions).length > 0 && (
                  <Button
                    variant="outlined"
                    onClick={(event) =>
                      setFilters((prev) => ({
                        ...prev,
                        showFilterPopper: !prev.showFilterPopper,
                        anchorEl: event.currentTarget,
                      }))
                    }
                  >
                    {t('common.filters')}
                  </Button>
                )}
              </Stack>

              {/* Filter options popup */}
              <Popper
                open={filters.showFilterPopper}
                anchorEl={filters.anchorEl}
                placement="bottom-end"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper elevation={3} sx={{ mt: 1, p: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        {t('common.displayOptions')}
                      </Typography>
                      <Box display="flex" flexDirection="column" gap={1}>
                        {Object.keys(filters.filterOptions).map((filterName) => (
                          <FormControlLabel
                            key={filterName}
                            control={
                              <Switch
                                checked={filters.filterOptions[filterName]}
                                onChange={({ target }) =>
                                  handleFilterToggle(filterName, target.checked)
                                }
                                name={filterName}
                              />
                            }
                            label={t(`navigation.${filterName}`)}
                          />
                        ))}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={1}
                          pt={1}
                          borderTop={1}
                          borderColor="divider"
                        >
                          <Button size="small" onClick={() => toggleAllFilters(false)}>
                            {t('common.hideAll')}
                          </Button>
                          <Button size="small" onClick={() => toggleAllFilters(true)}>
                            {t('common.showAll')}
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

ReportSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    warehouses: PropTypes.array,
    principals: PropTypes.array,
    tasks: PropTypes.array,
    users: PropTypes.array,
    fetchPrincipals: PropTypes.func,
    fetchTasks: PropTypes.func,
  }).isRequired,
  filterOptions: PropTypes.object,
  isLoading: PropTypes.bool,
  additionalFields: PropTypes.array,
};

export default ReportSearchForm;
