import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from '@mui/material';

import { addShift, startAssignedShift } from '../../../actions/shiftActions';
import { getWarehouse } from '../../../actions/warehouseActions';
import { getWarehouseNameByID } from '../../HelperFunctions/Others';

const formatTime = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getHours()).padStart(2, '0')}:${String(
        date.getMinutes()
    ).padStart(2, '0')}`;
};

const LoginToWorkPlace = ({ assigned }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [warehouse, setWarehouse] = useState('');
    const [isWarehousesLoading, setIsWarehousesLoading] = useState(false);
    const [isStartingShift, setIsStartingShift] = useState(false);
    const [isStartingAssignedShift, setIsStartingAssignedShift] =
        useState(false);
    const isMounted = useRef(true);

    const user = useSelector((state) => state.user);
    const warehouses = useSelector((state) => state.warehouses);
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    useEffect(() => {
        const fetchWarehouses = async () => {
            if (!isMounted.current) return;
            setIsWarehousesLoading(true);
            try {
                await dispatch(getWarehouse());
            } catch (error) {
                console.error('Error fetching warehouses:', error);
            } finally {
                // Only update state if still mounted
                if (isMounted.current) {
                    setIsWarehousesLoading(false);
                }
            }
        };
        fetchWarehouses();
    }, [dispatch]);

    const handleStartShift = async (event) => {
        event.preventDefault();

        if (!user?.id) {
            console.error('User not available');
            return;
        }

        setIsStartingShift(true);
        try {
            await dispatch(addShift(warehouse, user.id));
        } catch (error) {
            console.error('Error starting shift:', error);
        } finally {
            if (isMounted.current) {
                setIsStartingShift(false);
            }
        }
    };

    const handleStartAssignedShift = async () => {
        setIsStartingAssignedShift(true);
        try {
            await dispatch(startAssignedShift(assigned));
        } catch (error) {
            console.error('Error starting assigned shift:', error);
        } finally {
            if (isMounted.current) {
                setIsStartingAssignedShift(false);
            }
        }
    };

    return (
        <div className="mt-5">
            <h1 className="page-heading">{t('timer.loginToWork')}</h1>

            {assigned && warehouses && (
                <>
                    <div className="mb-5">
                        <p>
                            {t('timer.assignedShift')}{' '}
                            {getWarehouseNameByID(
                                assigned.warehouse,
                                warehouses
                            )}
                        </p>
                        <p>
                            {t('timer.shiftStart')}:{' '}
                            {`${formatTime(
                                assigned.assigned_start
                            )} - ${formatTime(assigned.assigned_end)}`}
                        </p>
                        <button
                            className="btn btn-primary"
                            onClick={handleStartAssignedShift}
                            disabled={isStartingAssignedShift}
                        >
                            {isStartingAssignedShift ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="ms-2">
                                        {t('timer.loggingIn')}
                                    </span>
                                </>
                            ) : (
                                t('timer.loginToWork')
                            )}
                        </button>
                    </div>
                    <Divider className="mb-5">{t('common.or')}</Divider>
                </>
            )}

            <form onSubmit={handleStartShift}>
                <div className="form-group">
                    <label htmlFor="warehouse">
                        {t('timer.selectWarehouse')}
                    </label>
                    <select
                        onChange={({ target }) => setWarehouse(target.value)}
                        className="form-control"
                        id="warehouse"
                        value={warehouse}
                        required
                        disabled={isWarehousesLoading}
                    >
                        <option value="">{t('timer.selectWarehouse')}</option>
                        {isWarehousesLoading ? (
                            <option disabled>{t('common.loading')}...</option>
                        ) : (
                            warehouses?.map((wh) => (
                                <option key={wh.id} value={wh.id}>
                                    {wh.name}
                                </option>
                            ))
                        )}
                    </select>
                </div>

                <button
                    className="btn btn-primary mt-3"
                    type="submit"
                    disabled={isStartingShift || isWarehousesLoading}
                >
                    {isStartingShift ? (
                        <>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="ms-2">{t('timer.loggingIn')}</span>
                        </>
                    ) : (
                        t('timer.loginToWork')
                    )}
                </button>
            </form>
        </div>
    );
};

export default LoginToWorkPlace;
