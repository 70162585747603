import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getUsers,
  getWarehouse,
  managerReportSearch,
  getWarehousePrincipals,
  getPrincipalsTasks,
} from '../../../../actions';
import { sortObject } from '../../../HelperFunctions/Others';
import ReportSearchForm from '../../../report/ReportSearchForm';
import RenderReportSection from './RenderReportSection';

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Use fallback to empty arrays if Redux state is null
  const warehousesData = useSelector((state) => state.warehouses) || [];
  const principalsData = useSelector((state) => state.principal) || [];
  const tasksData = useSelector((state) => state.principalTasks) || [];
  const usersData = useSelector((state) => state.users) || [];

  // Load initial data only once when component mounts
  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getWarehouse());
      dispatch(getUsers());
      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded]);

  // Prepare data for the ReportSearchForm
  const formData = {
    warehouses: sortObject(warehousesData, 'name'),
    principals: sortObject(principalsData, 'name'),
    tasks: sortObject(tasksData, 'title'),
    users: sortObject(usersData, 'first_name'),
    fetchPrincipals: (warehouseId) => dispatch(getWarehousePrincipals(warehouseId)),
    fetchTasks: (warehouseId, principalId) =>
      dispatch(getPrincipalsTasks(warehouseId, principalId)),
  };

  const handleSearch = async (searchData) => {
    setLoading(true);
    try {
      await dispatch(managerReportSearch(searchData));
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-3">
        <h4 className="page-heading">{t('report.report')}</h4>
      </div>
      <ReportSearchForm onSearch={handleSearch} data={formData} isLoading={loading} />
      <RenderReportSection loading={loading} setLoading={setLoading} />
    </>
  );
};

export default Search;
