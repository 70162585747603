import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

/**
 * StatisticsDisplay component shows the boxes completed and performance metrics
 */
const StatisticsDisplay = ({ 
  totalBoxesCompleted, 
  avgPerformance, 
  performanceBackgroundColor, 
  performanceStatusText,
  product 
}) => {
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 2,
        mb: 2,
      }}
    >
      {/* Boxes Completed */}
      <Box
        sx={{
          p: 2,
          bgcolor: 'grey.100',
          borderRadius: 1,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="text.secondary">
          {t('selectedProduct.totalBoxesCompleted')}
        </Typography>
        <Typography variant="h3" color="text.primary">
          {totalBoxesCompleted}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('selectedProduct.totalBoxesCompletedDetail', {
            count: totalBoxesCompleted * product.pcs,
          })}
        </Typography>
      </Box>

      {/* Performance */}
      <Box
        sx={{
          p: 2,
          bgcolor: performanceBackgroundColor,
          borderRadius: 1,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="text.secondary">
          {t('selectedProduct.avgPerformance')}
        </Typography>
        <Typography variant="h3" color="text.primary">
          {avgPerformance}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('selectedProduct.performanceUnit')}
        </Typography>

        {product.targetPerformance && (
          <Box sx={{ mt: 1 }}>
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontWeight: 'bold',
                color:
                  parseFloat(avgPerformance) >= product.targetPerformance
                    ? 'success.dark'
                    : 'error.dark',
                marginRight: 1, // Add gap between status text and target
                display: 'inline-block', // Ensure margin works correctly
              }}
            >
              {performanceStatusText}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontWeight: 'bold', display: 'inline' }}
            >
              {t('selectedProduct.target')}: {product.targetPerformance}{' '}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

StatisticsDisplay.propTypes = {
  totalBoxesCompleted: PropTypes.number.isRequired,
  avgPerformance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  performanceBackgroundColor: PropTypes.string.isRequired,
  performanceStatusText: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
};

export default StatisticsDisplay;