import numeral from 'numeral';

// ----------------------------------------------------------------------

// Configure numeral locale for Finnish standards
numeral.register('locale', 'fi', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
});

// Set default locale to Finnish
numeral.locale('fi');

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number, format = '0,0') {
  return numeral(number).format(format);
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
