import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { getWorkOrders } from '@/api/vas-activity';
import { format } from 'date-fns';

export default function PalletList() {
  const [pallets, setPallets] = useState([]);

  useEffect(() => {
    const fetchPallets = async () => {
      try {
        const response = await getWorkOrders();
        // Flatten the data structure to get all pallets
        const allPallets = response.data.flatMap((workOrder) =>
          workOrder.pallets.map((pallet) => ({
            ...pallet,
            productCode: workOrder.productInfo.productCode,
            workOrderNumber: workOrder.workOrderNumber,
          })),
        );
        setPallets(allPallets);
      } catch (error) {
        console.error('Error fetching pallets:', error);
      }
    };

    fetchPallets();
  }, []);

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Pallet List
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="pallet table">
          <TableHead>
            <TableRow>
              <TableCell>Completed At</TableCell>
              <TableCell>Product Code</TableCell>
              <TableCell>Work Order Number</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell>Pallet Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pallets.map((pallet) => (
              <TableRow key={pallet.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {pallet.completedAt
                    ? format(new Date(pallet.completedAt), 'dd.MM.yyyy HH:mm:ss')
                    : '-'}
                </TableCell>
                <TableCell>{pallet.productCode}</TableCell>
                <TableCell>{pallet.workOrderNumber}</TableCell>
                <TableCell align="right">{pallet.quantity}</TableCell>
                <TableCell>{pallet.palletNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
