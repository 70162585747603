export const getWarehouseNameByID = (warehouseID, warehouses) => {
  if (
    !warehouseID ||
    warehouseID === 'All' ||
    (Array.isArray(warehouseID) && warehouseID.length === 0)
  )
    return 'All';
  if (warehouseID === 'SICK') return 'Sairasloma';
  const id = parseInt(warehouseID);
  const warehouse = warehouses.find((e) => e.id === id);
  return warehouse?.name || 'All'; // Add fallback in case warehouse is not found
};

export const getWorkerNameByID = (workerID, workers) => {
  // Check if workers is null, undefined, or not an array
  if (!workers || !Array.isArray(workers) || workers.length === 0) {
    return `Worker ${workerID || 'Unknown'}`;
  }

  if (typeof workerID === 'string') {
    workerID = parseInt(workerID);
  }

  // Handle if workerID is not a valid number
  if (isNaN(workerID)) {
    return 'Unknown Worker';
  }

  // Find the worker by ID
  const worker = workers.find((e) => e.id === workerID);

  // Check if worker exists before accessing properties
  if (!worker) {
    return `Worker #${workerID}`;
  }

  // Safely access properties with fallbacks
  const firstName = worker.first_name || '';
  const lastName = worker.last_name || '';
  const fullName = `${firstName} ${lastName}`.trim();

  // Return the full name or a fallback
  return fullName || `Worker #${workerID}`;
};
