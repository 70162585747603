import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShiftByWorkerID, getShiftTask } from '../../../actions/shiftActions';
import InJob from './InJob';
import LoginToWorkPlace from './LoginToWorkPlace';
import TaskForm from './TaskForm';

/**
 * Determines if a worker is currently in an assigned shift time window
 * @param {Object} shift - The shift object to check
 * @param {number} bufferMinutes - Minutes before/after start time to consider valid
 * @returns {boolean} Whether the current time is within the shift window
 */
const isWithinShiftTimeWindow = (shift, bufferMinutes = 60) => {
  if (!shift || !shift.assigned_start || !shift.assigned_end) return false;

  const assignedStart = new Date(shift.assigned_start).getTime();
  const assignedEnd = new Date(shift.assigned_end).getTime();
  const now = new Date().getTime();

  // Check if now is between assigned start and end times
  if (now > assignedStart && now < assignedEnd) return true;

  // Check if within buffer period before shift ends
  const diffMinutes = Math.round(Math.abs((now - assignedStart) / 60000));
  return diffMinutes < bufferMinutes && now < assignedEnd;
};

const Timer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const shifts = useSelector((state) => state.shift || []);
  const tasks = useSelector((state) => state.tasks || []);

  // Load shifts data when component mounts or user changes
  useEffect(() => {
    if (user?.id) {
      dispatch(getShiftByWorkerID(user.id));
    }
  }, [dispatch, user]);

  // Find active and assigned shifts
  const activeShift = useMemo(() => shifts.find((shift) => shift.is_working === true), [shifts]);

  const assignedShift = useMemo(() => {
    if (!shifts.length) return null;

    return shifts.find((shift) => shift.assigned_start !== null && isWithinShiftTimeWindow(shift));
  }, [shifts]);

  // Determine if user is at workplace
  const isAtWorkplace = !!activeShift;

  // Load tasks when active shift changes
  useEffect(() => {
    if (isAtWorkplace && activeShift?.id) {
      dispatch(getShiftTask(activeShift.id));
    }
  }, [dispatch, activeShift, isAtWorkplace]);

  // Find active task
  const activeTask = useMemo(() => tasks.find((task) => task.end === null), [tasks]);

  // Determine if user is on a task
  const isOnTask = !!activeTask;

  // Render appropriate component based on user state
  if (isAtWorkplace && isOnTask) {
    return <InJob />;
  }

  if (isAtWorkplace && !isOnTask) {
    return <TaskForm />;
  }

  return <LoginToWorkPlace assigned={assignedShift} />;
};

export default Timer;
