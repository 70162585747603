import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

const ProductGrid = ({ products, onProductSelect }) => (
    <Grid container spacing={2}>
        {products.map((product) => (
            <Grid item xs={4} sm={3} md={2} key={product.id}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => onProductSelect(product)}
                >
                    {product.image ? (
                        <img
                            src={product.image}
                            alt={product.productCode}
                            style={{
                                width: '100px',
                                height: '75px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                            }}
                        />
                    ) : (
                        <Box
                            sx={{
                                width: '100px',
                                height: '75px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f5f5f5',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                textAlign: 'center',
                                overflow: 'hidden',
                                px: 1,
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {product.productCode}
                            </Typography>
                        </Box>
                    )}
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 1,
                            textAlign: 'center',
                            maxWidth: '100px',
                        }}
                    >
                        {product.productCode}
                    </Typography>
                </Box>
            </Grid>
        ))}
    </Grid>
);

export default ProductGrid;
