// @mui
import { Box, Card, Container, Typography, Chip } from '@mui/material';

import useSettings from '../../../hooks/useSettings';

import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useEffect, useState } from 'react';
import { fDate } from '../../../utils/formatTime';

const AboutApp = () => {
  const { themeStretch } = useSettings();

  const [releases, setReleases] = useState([]);

  useEffect(() => {
    // Since we removed Firebase dependency, we'll provide static release info
    setReleases([
      {
        id: 'current',
        version: process.env.REACT_APP_VERSION || '0.7.13',
        type: 'current',
        time_stamp: new Date(),
        changes: [
          'Firebase components removed except for notification and sickleave functionality',
        ],
      },
    ]);
  }, []);

  return (
    <Page title="Setting: About App">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="About App"
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'Setting', href: '/setting' },
            { name: 'About' },
          ]}
        />

        {releases &&
          releases.map((release) => (
            <Card key={release.id} sx={{ marginY: 2 }}>
              <Box sx={{ padding: 2 }}>
                <div className="d-flex g-3">
                  <Typography variant="h5">{release.version}</Typography>
                  <Chip label={release.type} />
                </div>
                <Typography variant="body2" color="textSecondary">
                  {fDate(release.time_stamp)}
                </Typography>
                <Typography variant="h6" className="mt-3">
                  Core Changes{' '}
                </Typography>

                <ul>
                  {release.changes?.map((chance) => (
                    <li key={chance}>{chance}</li>
                  ))}
                </ul>
              </Box>
            </Card>
          ))}
      </Container>
    </Page>
  );
};

export default AboutApp;
