import { differenceInSeconds } from 'date-fns';

const getNewReport = (reports, Performance = []) => {
  let newReport = {};
  reports.sort(function (a, b) {
    var keyA = a.worker,
      keyB = b.worker;
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  reports.forEach((e) => {
    let worker = e.worker;
    let warehouse = e.warehouse;

    if (!(worker in newReport)) {
      newReport[worker] = { shifts: [], total: 0, warehouses: {} };
    }

    if (!(warehouse in newReport[worker].warehouses)) {
      newReport[worker].warehouses[warehouse] = { workTypeTotals: {} };
    }

    newReport[worker].shifts.push(e);

    const shiftDifference = differenceInSeconds(new Date(e.check_out), new Date(e.check_in));
    newReport[worker].total += shiftDifference >= 27000 ? shiftDifference - 1800 : shiftDifference;

    e.workTypeTotal.forEach((workType) => {
      workType.total.forEach((timeSlot) => {
        const workTypeDifference = differenceInSeconds(
          new Date(timeSlot.end),
          new Date(timeSlot.start),
        );

        if (!(workType.work_type in newReport[worker].warehouses[warehouse].workTypeTotals)) {
          newReport[worker].warehouses[warehouse].workTypeTotals[workType.work_type] = {
            work_type_name: workType.work_type_name,
            totalSeconds: 0,
          };
        }

        newReport[worker].warehouses[warehouse].workTypeTotals[workType.work_type].totalSeconds +=
          workTypeDifference;
      });
    });
  });
  return newReport;
};

export default getNewReport;
