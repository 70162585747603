const timer = {
  en: {
    loginToWork: 'Start to Work',
    selectWarehouse: 'Select Warehouse',
    startWork: 'Start Work',
    selectPrincipal: 'Select Principal',
    selectWorktype: 'Select Worktype',
    note: 'NOTE!',
    noteText: 'You are currently logged in',
    klo: 'from',
    startWorking: 'Start Working',
    stopWorking: 'Stop Working',
    switchWorkType: 'Switch Work Type',
    totalWorkingTime: 'Total Working Time',
    warning: 'NOTE!',
    warningText: 'You are choosing to stop the work. By choosing this, the time stamp will stop',
    cancel: 'Cancel',
    assignedShift: 'You have assigned shift at',
    shiftStart: 'Start at',
    startBreak: 'Start Break',
    startLunch: 'Start Lunch',
    continueWorking: 'Continue Working',
    performanceCalendar: 'How many calendars did you do?',
    performanceCalendarBox: 'How many boxs did you do?',
    performanceCalendarDescription: 'example: 100 pcs',
    loggingIn: 'Logging in',
  },
  fi: {
    loginToWork: 'Kirjaudu sisään',
    selectWarehouse: 'Valitse työpaikka, kustannuspaikka',
    startWork: 'Aloita työt',
    selectPrincipal: 'Valitse päämies',
    selectWorktype: 'Valitse työlaji',
    note: 'HUOM!',
    noteText: 'Olet tällä hetkellä kirjautunut',
    klo: 'Klo',
    startWorking: 'Aloita Työnteko',
    stopWorking: 'Lopeta Työnteko',
    switchWorkType: 'Vaihda työlaji',
    totalWorkingTime: 'Työaika yhteensä',
    warning: 'HUOM!',
    warningText: 'Valitsit Lopeta työn. Kun valitset tämän, aikaleima pysähtyy',
    cancel: 'Peruuta',
    assignedShift: 'Sinulle on nimetty vuoro',
    shiftStart: 'Vuoro alkaa',
    startBreak: 'Aloita tauko',
    startLunch: 'Aloita lounas',
    continueWorking: 'Jatka työt',
    performanceCalendar: 'Kuinka monta kalenteria teit?',
    performanceCalendarBox: 'Kuinka monta kalenteri laatikkoa teit?',
    performanceCalendarDescription: 'esimerkki: 100 kpl',
    loggingIn: 'Kirjaudutaan',
  },
  vi: {
    loginToWork: 'Bắt đầu làm việc',
    selectWarehouse: 'Chọn kho',
    startWork: 'Bắt đầu làm việc',
    selectPrincipal: 'Chọn khách hàng',
    selectWorktype: 'Chọn loại công việc',
    note: 'Chú ý!',
    noteText: 'Bạn đã đăng nhập',
    klo: 'từ',
    startWorking: 'Bắt đầu làm việc',
    stopWorking: 'Kết thúc làm việc',
    switchWorkType: 'Chuyển loại công việc',
    totalWorkingTime: 'Tổng thời gian làm việc',
    warning: 'Cảnh báo!',
    warningText: 'Bạn đang chọn dừng công việc. Bằng cách chọn này, dấu thời gian sẽ dừng lại',
    cancel: 'Hủy',
    assignedShift: 'bạn đã được phân công làm việc tại ',
    shiftStart: 'Bắt đầu vào lúc',
    startBreak: 'Bắt đầu giải lao',
    startLunch: 'Bắt đầu giờ ăn',
    continueWorking: 'Tiếp tục làm việc',
    performanceCalendar: 'Bạn đã làm bao nhiêu cái lịch?',
    performanceCalendarBox: 'Bạn đã làm bao nhiêu cái hộp?',
    performanceCalendarDescription: 'thí dụ: 100 cái',
    loggingIn: 'Đang đăng nhập',
  }
};

export default timer;