const initialState = {
    vasProducts: [],
    productTypes: [],
    UOMs: [],
    vasProductDetails: null, // New field to store details of a single VAS product
    loading: false,
    error: null,
};

export default function vasProductsReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_VAS_PRODUCTS_REQUEST':
        case 'GET_VAS_PRODUCTS_BY_WORKTYPE_REQUEST':
        case 'GET_PRODUCT_TYPES_AND_UOMS_REQUEST':
        case 'GET_VAS_PRODUCT_BY_ID_REQUEST': // Request to fetch one product
        case 'GET_PRODUCT_TYPES_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
                // Optionally reset details when starting a new fetch:
                ...(action.type === 'GET_VAS_PRODUCT_BY_ID_REQUEST' && {
                    vasProductDetails: null,
                }),
            };

        case 'GET_VAS_PRODUCTS_SUCCESS':
        case 'GET_VAS_PRODUCTS_BY_WORKTYPE_SUCCESS':
            return {
                ...state,
                vasProducts: action.payload.map((product) => {
                    if (product.image) {
                        return {
                            ...product,
                            image: product.image.replace(
                                '/upload/',
                                '/upload/c_fill,w_100,h_100/'
                            ),
                        };
                    }
                    return product;
                }),
                loading: false,
            };

        case 'GET_PRODUCT_TYPES_AND_UOMS_SUCCESS':
            return {
                ...state,
                productTypes: action.payload.productTypes,
                UOMs: action.payload.uoms,
                loading: false,
            };

        case 'GET_PRODUCT_TYPES_SUCCESS':
            return {
                ...state,
                productTypes: action.payload,
                loading: false,
            };

        case 'GET_VAS_PRODUCT_BY_ID_SUCCESS': // Successfully fetched one product
            return {
                ...state,
                loading: false,
                vasProductDetails: action.payload,
            };

        case 'GET_VAS_PRODUCTS_FAILURE':
        case 'GET_PRODUCT_TYPES_FAILURE':
        case 'GET_VAS_PRODUCTS_BY_WORKTYPE_FAILURE':
        case 'GET_PRODUCT_TYPES_AND_UOMS_FAILURE':
        case 'GET_VAS_PRODUCT_BY_ID_FAILURE': // Failed fetching one product
            return {
                ...state,
                loading: false,
                error: action.payload,
                ...(action.type === 'GET_VAS_PRODUCT_BY_ID_FAILURE' && {
                    vasProductDetails: null,
                }),
            };

        case 'ADD_VAS_PRODUCT_SUCCESS':
            return {
                ...state,
                vasProducts: [...state.vasProducts, action.payload],
            };

        case 'ADD_VAS_PRODUCT_FAILURE':
            return {
                ...state,
                error: action.payload,
            };

        case 'UPDATE_VAS_PRODUCT_SUCCESS':
            return {
                ...state,
                vasProducts: state.vasProducts.map((product) =>
                    product.id === action.payload.id ? action.payload : product
                ),
            };

        case 'UPDATE_VAS_PRODUCT_FAILURE':
            return {
                ...state,
                error: action.payload,
            };

        case 'DELETE_VAS_PRODUCT_SUCCESS':
            return {
                ...state,
                vasProducts: state.vasProducts.filter(
                    (product) => product.id !== action.payload
                ),
            };

        default:
            return state;
    }
}
